.industries{
	float: left;
	width: 100%;
	padding: 0px 0;
	padding-bottom: 90px;
	.industries_items{
		display: flex;
		align-items: flex-end;
		.industries_item{
			flex-grow: 1;
			text-align: center;
			height: 100%;

			.industries_item__icon{
				svg{
					max-width: 92px;
					max-height: 90px;
				}
			}
			.industries_item__title{
				font-size: 20px;
				font-weight: $lightFont;
				margin-top: 20px;
			}
			&:hover{
				.industries_item__icon{
				}
			}
		}
	}
}

@media (max-width: 1024px){
	.industries{
		padding: 35px 30px;
		padding-bottom: 75px;
	}
	.industries .industries_items .industries_item .industries_item__title{
		font-size: 16px;
	}
}
@media (max-width: 767px){
	.industries .industries_items{
		flex-wrap: wrap;
	}
	.industries .industries_items .industries_item{
		width: 100%;
		margin-bottom: 70px;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
