.industries_with_positions{
	float: left;
	width: 100%;
	padding: 0px 0;
	padding-bottom: 120px;
	.industries_items{
		display: flex;
		flex-wrap: wrap;
		.industries_item{
			width: 20%;
			text-align: center;
			border-left: 1px solid #EEEEEE;
			border-right: 1px solid #EEEEEE;
			margin: 0 -0.5px;
			padding: 0 30px;

			.industries_item__icon{
				height: 90px;
				// display: flex;
				// align-content: flex-end;
				svg{
					max-width: 92px;
					max-height: 90px;
				}
			}
			.industries_item__title{
				font-size: 20px;
				font-weight: 500;
				margin-top: 20px;
			}
			.postion{

				margin-top: 40px;
				p{

				}
			}
			&:hover{
				.industries_item__icon{
				}
			}
		}
	}
}

@media (max-width: 1024px){
	.industries_with_positions{
		padding: 35px 30px;
		padding-bottom: 75px;
	}
	.industries_with_positions .industries_items .industries_item .industries_item__title{
		font-size: 21px;
	}
	.industries_with_positions .industries_items .industries_item{
		width: 33.33%;
		margin-bottom: 60px;
	}
	.industries_with_positions{
		padding-bottom: 15px;
	}

}
@media (max-width: 767px){
	.industries_with_positions .industries_items{
		flex-wrap: wrap;
	}
	.industries_with_positions .industries_items .industries_item{
		width: 100%;
		margin-bottom: 70px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.industries_with_positions .industries_items .industries_item{
		border-left: none;
		border-right: none;
		border-top: 1px solid #EEEEEE;
		border-bottom: 1px solid #EEEEEE;
		margin: -0.5px 0;
		padding: 40px 0px;
	}
	.industries_with_positions .industries_items .industries_item .industries_item__title{
		font-size: 18px;
	}
	.industries_with_positions .industries_items .industries_item .postion{
		margin-top: 30px;
	}
}
