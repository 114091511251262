.header{
	float: left;
	width: 100%;
.headerSticky{
	padding: 0 25px;
	display: flex;
	z-index: 100;
	align-items: center;

	&:hover{
		background-color: #fff;
		opacity: 1;
		pointer-events: inherit;
		// box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);


		.header_menu.mainPageMenu{
			opacity: 1;
			pointer-events: inherit;
		}

	}
	&.is-stuck{
		background-color: #fff;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

		.header_menu.mainPageMenu{
			opacity: 1;
			pointer-events: inherit;
		}
	}



	&.headerSticky_openOffer{
		background-color: transparent;
		transition-duration: 0s;
		box-shadow: none;

		.header_title .header_link .header_logo{
			path{
				fill: #fff;
			}
		}
		.header_menu .header_menu__list li a{
			color: #fff;
		}

		.header_menu .header_menu__list li{
			opacity: .3;
			&.offerShow{
				opacity: 1;
			}
		}

		.header_menu.mainPageMenu{
			opacity: 1;
			pointer-events: inherit;
		}

	}

	&.headerSticky_openOffer:hover{
		background-color: transparent;
		transition-duration: 0s;
		.header_title .header_link .header_logo{
			path{
				fill: #fff;
			}
		}
		.header_menu .header_menu__list li a{
			color: #fff;
		}

		.header_menu.mainPageMenu{
			opacity: 1;
			pointer-events: inherit;
		}
	}

	.header_title{
		margin: 0; padding: 0;
		line-height: 0em;
		float: left;
		font-size: 1px;
		.header_link{
			line-height: 1em;
			.header_logo{
				width: 156px;
			}
		}
	}

	.header_menu{
		float: left;
		flex-grow: 10;
		transition-duration: .3s;
		&.mainPageMenu{
			opacity: 0;
			pointer-events: none;
		}
		.header_menu__list{
			float: left;
			text-align: center;
			width: 100%;
			@extend %noPadding;
			li{
				display: inline-block;
				margin: 0 40px;
				a{
					padding: 22px 10px;
					font-size: 16px;
					display: block;
					color: $darkBlue;
					font-weight: $mediumFont;
					&:hover{
						opacity: .6;
					}
				}

				ul{
					display: none;
				}
			}
		}
	}

	.loginHeader{
		position: relative;
		border-radius: 3px;
		cursor: pointer;
		display: inline-block;
		border: 1px solid $blue;
		color: $blue;
		padding: 5px 12px;
		float: left;
		margin-right: 10px;
		transition-duration: .3s;
		font-size: 12px;
		font-weight: 600;
		&:hover{
			opacity: .6;
		}
		img{
			height: 10px;
			margin-left: 5px;
			position: relative;
			top: -1px;
		}
	}
	.header_changeLanguage{
		margin-left: auto;
		font-weight: $boldFont;
		position: relative;


		.showMobile{
			display: none;
		}
		.header_changeLanguage__wrapMobile{
			display: none;
		}
		.header_changeLanguage__wrap, .header_changeLanguage__wrapMobile{
			position: relative;
			float: none;
			border-radius: 3px;
			background-color: $blue;
			cursor: pointer;
			padding: 10px 12px;
			// @extend %buttonAnimation;
			@include buttonAnimation($darkBlue);
			&.active{
				border-radius: 3px 3px 0 0;
			}

			.header_changeLanguage__title{
				position: relative;
				z-index: 1;
				font-size: 12px;
				color: #fff;
				float: left;
			}

			.header_changeLanguage__currentLanguage{
				position: relative;
				z-index: 1;
				font-size: 13px;
				color: #fff;
				text-transform: uppercase;
				margin-left: 15px;
				float: left;
				line-height: 1em;
				top: 2px;

				img{
					position: relative;
					top: -1px;
				}
			}

			&::after{

			}
		}
		.header_changeLanguage__wrapMore{
			float: left;
			display: none;
			border-radius: 0 0 3px 3px;
			background-color: $blue;
			cursor: pointer;
			width: 100%;
			position: absolute;
			.item{
				float: left;
				padding: 10px 12px;
				border-top: 1px solid rgba(255,255,255,.3);
				width: 100%;
				overflow: hidden;
				position: relative;
				@include buttonAnimation($red);

				.lang{
					position: relative;
					z-index: 1;
					font-size: 12px;
					color: #fff;
					float: left;
				}
				.langS{
					position: relative;
					z-index: 1;
					font-size: 13px;
					color: #fff;
					float: right;
					text-transform: uppercase;
				}

			}
		}

		.header_changeLanguage__list{
			// display: none;
			@extend %noPadding;
			float: left;
			position: absolute;
			top: 0;
			.header_changeLanguage__item{
				line-height: initial;
				a{
					font-size: 13px;
					color: #fff;
				}
			}
		}



		&:hover{

		}
	}
}
}

.header_menuHamburger{
	margin-left: 20px;
	display: none;
	span{
		width: 20px;
		height: 3px;
		background-color: $blue;
		display: block;
		margin-bottom: 3px;
		&:last-child{
			margin-bottom: 0;
		}
	}
}



//offers
.offerMenu_show{
	float: left;
	width: 100%;
	display: block;
	position: fixed;
	top: 0;
	z-index: 99;
	overflow: hidden;
	display: none;
	transition-duration: .2s;


	&.active{
		display: block;
	}

	.offerMenu_show_tabs{
		float: left;
		padding: 100px;
		background-color: $darkBlue;

		padding-top: 120px;
		width: 100%;
		z-index: 2;
		ul{
			border: 1px solid rgba(255, 255, 255, 0.25);
			border-radius: 3px;
			overflow: hidden;

			@extend %noPadding;
			li{
				&.box-1{
					width: 100%;
				}
				&.box-2{
					width: 50%;
				}
				a{
					border-right: 1px solid rgba(255, 255, 255, 0.25);
					display: block;
					font-weight: $mediumFont;
					line-height: 26px;
					font-size: 24px;
					align-items: center;justify-content: center;
					color: #fff;
					display: flex;
					.tabs_icon{
						width: 120px;
						height: 120px;
						display: flex;
						align-items: center;
						justify-content: center;
						.tabs_icon__svg{
							max-width: 60px;
							max-height: 50px;
							width: 100%;
							path{
								fill: #fff;
							}
						}

					}

					.tabs_arrow{
						.tabs_arrow__svg{
							path{
								fill: #fff;
							}
						}
					}

					.tabs_content{
						float: left;
						.tabs_title{
							font-weight: 500;
							line-height: 26px;
							font-size: 24px;
						}
						.tabs_info{
							text-transform: uppercase;
							font-weight: $mediumFont;
							font-size: 14px;
							letter-spacing: 1.4px;
						}
					}
					.tabs_arrow{
						width: 60px;
						float: left;
						display: flex;
						align-items: center;
						justify-content: center;
						.tabs_arrow__svg{
							width: 9px;
						}
					}
					&:hover{
						background-color: $red;
						transition-duration: .3s;
						.tabs_icon, .tabs_arrow{
							.tabs_icon__svg, .tabs_arrow__svg{
								path{
									fill: #fff;
									transition-duration: .3s;
								}
							}
						}
						.tabs_content{
							color: #fff;
									transition-duration: .3s;

						}
					}
				}
				&:last-child{
					a{
						border: none;
					}
				}
			}
		}
	}
}

.header_menu .offerShow{
	ul{
		display: none;
	}
}

.mobileMenu .offerShow{
	ul{
		display: block;
	}
}

.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item .langS{
	overflow: hidden;
	margin-right: 17px;
}
.header .headerSticky .header_changeLanguage .header_changeLanguage__wrap .header_changeLanguage__currentLanguage img, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__currentLanguage img{
	margin-left: 1px;
}

.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item,
.header .headerSticky .header_changeLanguage .header_changeLanguage__wrap, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile{
	padding: 5px 12px;
	padding-right: 7px;
}
.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item .lang,
.header .headerSticky .header_changeLanguage .header_changeLanguage__wrap .header_changeLanguage__title, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__title{
	position: relative;
	top: 1px;
}
.mobileNav .mobileMenu ul li a, .mobileNav .mobileMenu_lang ul li a{
	img{
		margin-right: 25px;
	}
}
@media (max-width: 1100px){
	.header .headerSticky .header_menu .header_menu__list li{
		margin: 0 28px;
	}
}
@media (max-width: 1024px){
	.header_menu__list{
		display: none;
	}
	.header_menuHamburger{
		display: block;
	}
	.header .headerSticky{
		background-color: #fff;
		padding: 15px 20px;
	}
	.header_changeLanguage__title{
		display: none;
	}
	.header .headerSticky .header_menu.mainPageMenu{
		display: none;
	}
	.header .headerSticky .header_title{

		float: left;
	}
	.header .headerSticky .header_changeLanguage{
		float: right;
	}
	.header .headerSticky .header_changeLanguage .header_changeLanguage__wrap{
		display: none;
	}
	.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile{
		background-color: transparent;
		background-image: url(../img/icons/union.svg);
		background-size: 100%;
		width: 20px;
		display: block;
		text-align: center;
		padding: 0;
		margin-bottom: -3px;

		height: 24px;
		background-repeat: no-repeat;
	}
	.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__currentLanguage{
		margin: 0;
	}
	.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__currentLanguage{
		font-size: 9px;
		position: relative;
		top: 5.5px;
		text-align: center;
		width: 100%;
	}
	.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__currentLanguage img{
		display: none;
	}
	.header .headerSticky .header_changeLanguage .showMobile{
		display: block;
	}

	.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile:hover{
		&::before, &::after{
			display: none;
		}
	}
	.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile{
		// position: relative;
		// top: 4px;
	}
	.header .headerSticky .loginHeader{
		margin-right: 30px;
		margin-left: auto;
	}
	.header .headerSticky .header_changeLanguage{
		margin-left: 0px;
	}
}

@media (max-width: 400px){

	.header .headerSticky .loginHeader img{
		display: none;
	}
	.header .headerSticky .loginHeader{
		margin-right: 10px;
	}
	.header .headerSticky .header_title {
    		min-width: 120px;
	}
	.header .headerSticky .header_title .header_link .header_logo {
	    width: 120px;
	}
}
