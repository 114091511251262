.blog_newsletter{
      float: left;
      width: 100%;
      background-color: $blue;
      padding-bottom: 140px;
      padding-top: 140px;
      text-align: center;
      color: #fff;

      .blog_newsletter__wrap{
            max-width: 730px;
            margin: 0 auto;
            .header{
                  font-weight: bold;
                  line-height: 38px;
                  font-size: 28px;
                  text-align: center;
                  margin-bottom: 20px;
                  letter-spacing: -0.2px;
            }
            .text{
                  float: left;
                  width: 100%;
                  font-weight: 300;
                  line-height: 39px;
                  font-size: 28px;
                  text-align: center;
                  letter-spacing: -0.2px;

            }

            .form{
                  div{

                        margin-top: 30px;
                        width: 100%;
                        #fm_form_1{
                              background-color: transparent!important;
                        }
                        .field{
                              width: 100%!important;
                        }
                        .label{
                              display: none!important;
                        }
                  }
                  .message_error{
                        width: 100%!important;
                        display: block;
                        float: left;
                        margin-bottom: 10px;
                  }
                  form{
                        float: left;
                        width: 100%;
                        margin: 0px 0;
                        padding: 0 60px;

                        div{
                              float: left;
                              width: calc(50% - 20px);
                              margin: 0 10px!important;

                              input{
                                    width: calc(50% - 10px);
                                    margin-right: 10px;
                                    float: left;
                                    background: #FAFAFA;
                                    border: 1px solid #E4E4E4;
                                    text-indent: 10px;
                                    box-sizing: border-box;
                                    border-radius: 4px;
                                    height: 50px!important;
                              }
                        }

                        p{
                              display: flex;
                              align-items: center;
                              float: left;
                              width: 100%;
                              color: #fff!important;
                              position: relative;
                              line-height: 24px!important;
                              font-size: 16px!important;
                              margin: 0!important;
                              input{
                              margin: 0;
                              padding: 0;
                              margin-right: 20px;
                              position: relative;
                              border: none!important;
                              margin-left: 10px;
                                  // opacity: 0;
                                  cursor: pointer;
                              }

                              // span:before {
                              //       content:'';
                              //     position: relative;
                              //     height: 25px;
                              //     width: 25px;
                              //     display: block;
                              //       border: 2px solid #000;
                              //       border-radius: 4px;
                              //       opacity: .5;
                              //       float: left;
                              // }

                              &:hover input ~ .checkmark {
                                  background-color: #ccc;
                              }

                              & input:checked ~ .checkmark {
                                  background-color: #000;
                              }

                              .checkmark:after {
                                  content: "";
                                  position: absolute;
                                  display: none;
                              }


                              .textCheck{
                                    text-align: left;
                                    font-style: normal;
                                    font-weight: normal;
                                    line-height: 24px;
                                    font-size: 16px;
                                    color: #fff;
                                    width: calc(100% - 40px);
                                    margin-left: auto;
                              }
                        }
                        button{
                              min-width: 180px;
                              height: 50px!important;
                              display: inline-block;
                              margin: 0 auto;
                              text-align: center;
                              background-color: $darkBlue!important;
                              color: #fff;
                              border-radius: 4px;
                              margin-top: 30px;
                              position: relative;
                              cursor: pointer;
                              transition-duration: .3s;

                              &:hover{
                                    opacity: .6;
                              }

                        }
                  }
            }


            .more{
                  width: 100%;
                  float: left;
                  font-weight: normal;
                  line-height: 24px;
                  font-size: 16px;
                  text-align: center;

            }
      }
}


@media (max-width: 767px){
      .blog_newsletter{
            padding: 80px 0;
      }
      .blog_newsletter .blog_newsletter__wrap .header{
            line-height: 27px;
            font-size: 19px;
            text-align: center;
            letter-spacing: -0.2px;
      }
      .blog_newsletter .blog_newsletter__wrap .text{
            line-height: 27px;
            font-size: 19px;
            text-align: center;
            letter-spacing: -0.2px;
      }
      .blog_newsletter .blog_newsletter__wrap form{
            padding: 0;
      }
      .blog_newsletter .blog_newsletter__wrap form .input input{
            width: 100%;

            margin: 0;
            margin-bottom: 10px;
      }
      .blog_newsletter .blog_newsletter__wrap form .check{
            flex-wrap: wrap;
            .textCheck{

            }
      }
      .blog_newsletter .blog_newsletter__wrap form button{
            width: 100%;
      }
      .blog_newsletter .blog_newsletter__wrap .form form{
            padding: 0!important;
      }
      .blog_newsletter .blog_newsletter__wrap .form form div{
            width: 100%;
      }
}
