.troopsModule{
	float: left;
	width: 100%;
	background-color: #FAFAFA;
	padding-top: 120px;
	margin-top: 0px;

	.troopsModule_header{
		h2{
			font-weight: bold;
			font-size: 46px;
			text-align: center;
		}
	}

	.troopsModule_wrap{
		position: relative;
		.swiper-container{
			margin: 40px 115px 50px 115px;
			padding: 10px;
			overflow: hidden;

			.troopsModule_slider__item{
				padding: 30px;
				background-color: transparent;
				box-sizing: border-box;
				transition-duration: .3s;
				.troopsModule_slider__header{

					font-weight: 500;
					line-height: 26px;
					font-size: 24px;
					margin-bottom: 20px;

					span{
						&.city{
							display: block;
						}
					}
				}
				.troopsModule_slider__content{
					p{
						font-weight: normal;
						line-height: 25px;
						font-size: 16px;
						margin: 0; padding: 0;
						float: left;
						width: 100%;
						&::after{
							content: '';
							width: 100px;
							height: 1px;
							color: $blue;
						}
					}
					a{
						font-weight: normal;
						line-height: normal;
						font-size: 16px;
						float: left;
						position: relative;
						color: $blue;
						margin-top: 10px;
						display: block;
						width: 100%;
						&::after{
							content: '';
							width: 60%;
							margin-top: 5px;
							height: 1px;
							display: block!important;
							transition-duration: .3s;
							background-color: $blue;
							margin: 0 auto;
							margin-top: 5px;
						}
						&:hover{
							color: $red;
							&::after{
								width: 100%;
								background-color: $red;
							}
						}
					}
				}

				&.activeInMap{
					background-color: #FFFFFF;
					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
					border-radius: 4px;
					&:hover{
						box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

					}
				}
				&:hover{
					background-color: #FFFFFF;
					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
					border-radius: 4px;
				}
			}
		}

		.troopsModule_arrows{
			position: absolute;
			bottom: 0; top: 0;
			left: 0; right: 0;
			display: flex;
			align-items: center;
			.troopsModule_arrows__left, .troopsModule_arrows__right{
				line-height: 1em;
				svg{
					line-height: 1em;
					transition-duration: .2s;
					path{
						transition-duration: .2s;
					}
					&:hover path{
						fill: $darkBlue;

					}
				}
			}
			.troopsModule_arrows__right{
				margin-left: auto;
			}
		}
	}

	.swiper-pagination{
		margin-top: 50px;
		position: relative;
		.swiper-pagination-bullet{
			width: 12px;
			height: 12px;
		}
	}
}
.swiper-pagination-bullet-active{
	background: $blue;
}


#mapSite{
	height: 450px;
	width: 100%;
}


@media (max-width: 1440px){
	.troopsModule{
		padding-top: 90px;
	}
}
@media (max-width: 1024px){
	.troopsModule .troopsModule_wrap .troopsModule_arrows{
		display: none;
	}
	.troopsModule{
		overflow: hidden;
	}
	.troopsModule .troopsModule_wrap .swiper-container{
		margin: 40px 0;
		width: calc(100% + 300px);
		margin-left: -150px;
		margin-right: -150px;
	}
	.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item{
		background-color: #FFFFFF;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
	}
	.troopsModule .troopsModule_header h2{
		font-size: 37px;
	}
}

@media (max-width: 767px){
	.troopsModule .troopsModule_wrap .troopsModule_arrows{
		display: none;
	}
	.troopsModule{
		overflow: hidden;
		padding-top: 60px;
	}
	.troopsModule .troopsModule_wrap .swiper-container{
		margin: 40px 0;
		width: calc(100% + 0px);
		margin-right: 0px;
	}
	.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item{
		background-color: #FFFFFF;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		// width: 0%!important;
	}
	.troopsModule .troopsModule_header h2{
		font-size: 28px;
	}
	.troopsModule .swiper-pagination{
		margin-top: 30px;
	}
	.troopsModule .troopsModule_wrap .swiper-container{
		margin-bottom: 10px;
	}
	.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item{
		left: -20px;
	}
	.swiper-wrapper, .troopsModule .troopsModule_wrap .swiper-container{
		overflow: visible;
	}

}
