.textWithTitle{
	padding: 60px 0;
	float: left;
	width: 100%;
	.textWithTitle_content{
		max-width: 1000px;
		margin: 0 auto;
		h3{
			font-size: 14px;
			letter-spacing: 1.4px;
			font-weight: 500;
			text-transform: uppercase;
			float: left;
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
		}
		.content{
			font-style: normal;
			font-weight: $lightFont;
			line-height: 1.2em;
			font-size: 46px;
			text-align: center;
			letter-spacing: -0.2px;

			p{
				margin: 0; padding: 0;
			}
		}
	}
}

@media (max-width: 1024px){
	.textWithTitle{
		padding: 35px 0;
		padding-bottom: 60px;
		float: left;
		width: 100%;

		.textWithTitle_content{
			max-width: 540px;
			margin: 0 auto;
			h3{
				font-size: 14px;
				letter-spacing: 1.4px;
				font-weight: 500;
				text-transform: uppercase;
				float: left;
				width: 100%;
				text-align: center;
			}
			.content{
				font-style: normal;
				font-weight: bold;
				line-height: 1.2em;
				font-size: 37px;
				text-align: center;
				letter-spacing: -0.2px;

			}
		}
	}

}

@media (max-width: 767px){
	.textWithTitle{
		padding: 35px 0;
		padding-bottom: 35px;
		float: left;
		width: 100%;

		.textWithTitle_content{
			max-width: 540px;
			margin: 0 auto;
			h3{
				font-size: 14px;
				letter-spacing: 1.4px;
				font-weight: 500;
				text-transform: uppercase;
				float: left;
				width: 100%;
				text-align: center;
			}
			.content{
				font-style: normal;
				font-weight: bold;
				line-height: 1.2em;
				font-size: 28px;
				text-align: center;
				letter-spacing: -0.2px;

			}
		}
	}

}
