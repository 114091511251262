.textSection_bigTitle{
	padding: 60px 0;
	float: left;
	width: 100%;
	.textSection_bigTitle_content{
		max-width: 1000px;
		margin: 0 auto;
		h3{
			font-size: 46px;
			text-align: center;
			font-weight: $boldFont;
			// text-transform: uppercase;
			float: left;
			width: 100%;
			text-align: center;
			margin-bottom: 30px;
		}
		.content{
			font-style: normal;
			font-weight: $lightFont;
			line-height: 26px;
			font-size: 20px;
			text-align: center;
			letter-spacing: -0.2px;

			p{
				margin: 0; padding: 0;
			}
		}
		.content_more{
			text-align: center;
			max-width: 834px;
			margin: 0 auto;
			margin-top: 50px;
			
			line-height: 38px;
			font-size: 28px;
			font-weight: $lightFont
		}
	}
}

@media (max-width: 1024px){
	.textSection_bigTitle{
		padding: 35px 0;
		padding-bottom: 60px;
		float: left;
		width: 100%;

		.textSection_bigTitle_content{
			max-width: 540px;
			margin: 0 auto;
			h3{	
				line-height: normal;
				font-size: 37px;
				text-align: center;
				
			}
			.content{
				font-weight: normal;
				line-height: 28px;
				font-size: 20px;

			}
		}
	}

	.textSection_bigTitle .textSection_bigTitle_content .content_more{
		margin-top: 30px;
		font-size: 23px;
		line-height: 1.2em;
	}

}

@media (max-width: 767px){
	.textSection_bigTitle{
		padding: 35px 0;
		padding-bottom: 35px;
		float: left;
		width: 100%;

		.textSection_bigTitle_content{
			max-width: 100%;
			margin: 0 auto;
			float: left;
			width: 100%;
			h3{
				font-weight: bold;
				line-height: normal;
				font-size: 26px;
				text-align: left;
			}
			.content{
				font-weight: normal;
				line-height: 28px;
				text-align: left;
				font-size: 20px;	
				
			}
		}
	}

	.textWithTitle_subtitle .textWithTitle_subtitle_content .content_more{
		font-size: 19px;
	}
	.textSection_bigTitle .textSection_bigTitle_content h3{
		display: none;
	}
	.textSection_bigTitle{
		padding: 0;
		padding-bottom: 45px;
	}
}