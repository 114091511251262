.offersWrap_section{
	float: left;
	width: 100%;

	.offersSection_tmpl{
		padding: 60px 0;
		float: left;
		width: 100%;
		.offersSection_tmpl__header{
			margin-bottom: 60px;
			h2{
				font-style: normal;
				font-weight: bold;
				line-height: normal;
				font-size: 46px;
				text-align: center;
				line-height: 1em;
				color: $darkBlue;
			}
		}
		.offersSection_tmpl__items{

		}

		.readMore{
			float: left;
	            width: 100%;
	            text-align: center;

	            a{
	                  min-width: 220px;
	                  display: inline-block;
	                  background-color: $blue;
	                  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
	                  border-radius: 3px;
	                  text-align: center;
	                  position: relative;
	                  padding: 15px 10px;
	                  color: #fff;
	                  font-size: 16px;
	                  margin-top: 60px;
	                  span{
	                        position: relative;
	                        z-index: 1;
	                  }

	                        @include buttonAnimation($darkBlue);

	                  }
		}
	}

}

@media (max-width: 1024px){
	.offersWrap_section .offersSection_tmpl .offersSection_tmpl__header h2{
		font-size: 37px;
	}
}
@media (max-width: 767px){
	.offersWrap_section .offersSection_tmpl{
		padding: 30px 0;
	}
	.offersWrap_section .offersSection_tmpl .offersSection_tmpl__header h2{
		font-size: 28px;
	}
	.offersWrap_section .offersSection_tmpl .offersSection_tmpl__header{
		margin-bottom: 30px;
	}
}
