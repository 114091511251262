.offersNav{
	float: left;
	width: 100%;
	.offersMenu{
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		li{
			width: 20%;
			text-align: center;
			float: left;
			a{
				display: inline-block;
				color: $blue;
				font-size: 20px;
				font-weight: 300;
				.menu-image-hover-wrapper{
					margin-right: 25px;
					.menu-image{

					}
					.hovered-image{
						display: none;
					}
				}

				&:hover{
						color: $green;
						.menu-image-hover-wrapper{
						.menu-image{
							display: none;

						}
						.hovered-image{
							display: inline-block;
							margin-left: 0px!important;
						}
					}
				}
			}

			&.current-menu-item{
				a{
					color: $green;
					.menu-image-hover-wrapper{
						.menu-image{
							display: none;

						}
						.hovered-image{
							display: inline-block;
							opacity: 1;
							margin-left: 0px!important;
						}
					}

				}
			}

		}
	}
}
.menu-item a.menu-image-title-after.menu-image-not-hovered img, .menu-item a.menu-image-hovered.menu-image-title-after .menu-image-hover-wrapper{
	padding-right: 0!important;
}
@media (max-width: 1024px){

	.offersNav .offersMenu li a .menu-image-hover-wrapper{
		margin-right: 0;
	}
	.menu-item a.menu-image-title-after.menu-image-not-hovered img, .menu-item a.menu-image-hovered.menu-image-title-after .menu-image-hover-wrapper{
		display: block;
		width: 100%;
		margin-bottom: 10px;
	}
	.offersNav .offersMenu{
		align-items: flex-end;
	}
}

@media (max-width: 767px){

	.offersNav .offersMenu li a .menu-image-hover-wrapper{
		margin-right: 0;
	}
	.menu-item a.menu-image-title-after.menu-image-not-hovered img, .menu-item a.menu-image-hovered.menu-image-title-after .menu-image-hover-wrapper{
		display: block;
		width: 100%;
		margin-bottom: 10px;
	}
	.offersNav .offersMenu{
		align-items: flex-end;
	}

	.offersNav .offersMenu li a{
		font-size: 10px;
	}
	.menu-item a img{
		max-width: 35px;
		max-height: 30px;
	}
}
