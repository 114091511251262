.news_box{
      float: left;
      background-color: #FAFAFA;
      width: 100%;
      padding-top: 120px;
      padding-bottom: 90px;

      .header{
            font-style: normal;
            font-weight: bold;
            line-height: normal;
            margin-bottom: 60px;
            font-size: 46px;
            text-align: center;
            letter-spacing: -0.2px;
            color: #002E5D;
      }

      .read_more{
            float: left;
            width: 100%;
            text-align: center;

            a{
                  min-width: 220px;
                  display: inline-block;
                  background-color: $blue;
                  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
                  border-radius: 3px;
                  text-align: center;
                  position: relative;
                  padding: 15px 10px;
                  color: #fff;
                  font-size: 16px;
                  margin-top: 60px;
                  span{
                        position: relative;
                        z-index: 1;
                  }

                        @include buttonAnimation($darkBlue);

                  }
            }

      .blog_section__content{
            float: left;
            width: 100%;
            .blog_section__article{
                  padding: 30px;
                  background-color: #fff;
                  margin-bottom: 30px;
                  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
                  border-radius: 4px;


                  .article__top{
                        float: left;
                        width: 100%;

                        .data{
                              float: left;
                              font-style: normal;
                              font-weight: 500;
                              line-height: 12px;
                              font-size: 12px;
                              color: #002E5D;
                              letter-spacing: 0.7px;
                              text-transform: uppercase;


                        }
                        .category{
                              float: left;
                              font-style: normal;
                              font-weight: 500;
                              line-height: 11px;
                              font-size: 12px;
                              text-transform: uppercase;
                              display: flex;align-items: center;

                                          a{
                                                float: left;
                                                margin-right: 10px;
                                                color: $darkBlue;
                                                display: inline-block;
                                          }


                              &::before{
                                    content: '';
                                    width: 2px; height: 2px;
                                    background-color: #002E5D;
                                    float: left;
                                    margin: 0 12px;
                                    border-radius: 10px;
                              }
                        }
                  }
                  .article_title{
                        padding: 30px 0;
                        float: left;
                        width: 100%;
                        h2{
                              line-height: 1em;

                              a{
                              font-style: normal;
                              font-weight: 500;
                              line-height: 1em;
                              font-size: 28px;
                              letter-spacing: 0.2px;
                              color: $darkBlue;
                              }
                        }
                  }

                  .article_image{
                        height: 200px;
                        float: left;
                        margin-bottom: 30px;
                        width: 100%;
                        background-position: center;
                        background-size: cover;
                  }

                  .article_content{
                        margin-bottom: 35px;
                        float: left;
                        width: 100%;
                        p{
                              margin: 0; padding: 0;
                        }
                  }

                  .article_more{
                        font-weight: 500;
                        line-height: 25px;
                        font-size: 16px;
                        letter-spacing: 0.2px;
                        color: $blue;

                        img{
                              height: 7px;
                              margin-left: 5px;
                        }
                  }
            }

            .blog_section__pagination{
                  float: left;
      		width: 100%;
      		text-align: center;
      		display: flex;
                  margin-top: 30px;
                  margin-bottom: 60px;
      		justify-content: center;
      		a, span{
      			border: 1px solid #D0D0D0;
      			box-sizing: border-box;
      			border-radius: 100px;
      			width: 50px;
      			height: 50px;
      			display: flex;
      			align-items: center;
      			color: $blue;
      			font-size: 22px;
      			font-weight: 500;
      			justify-content: center;
      			line-height: 1em;
      			margin: 0 5px;
      			transition-duration: .3s;

      			li{
      				list-style: none;
      				display: inline-block;
      			}

      			&.prev{
      				margin-right: 60px;
      				img{
      					height: 15px;
      				}
      			}
      			&.next{
      				margin-left: 60px;
      				img{
      					height: 15px;
      				}
      			}

      			&:hover{
      				background-color: #fff;
      			}
      		}
      		span{
      			border: 1px solid $blue;

      		}
            }
      }
}


@media (max-width: 767px){
      .news_box .blog_section__content .blog_section__article .article_title h2 a{
            font-family: Work Sans;
            font-style: normal;
            font-weight: 500;
            line-height: 27px;
            font-size: 19px;
            letter-spacing: 0.2px;
      }

      .news_box .header{
            font-size: 26px;
      }
      .news_box{
            padding-top: 60px;
            padding-bottom: 60px;
      }
      .news_box .read_more a{
            margin-top: 30px;
      }
}
