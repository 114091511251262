.whatIsThreeZones{
	float: left;
	width: 100%;
	padding: 90px 0;
	.whatIsThreeZones_content{
		max-width: 860px;
		margin: 0 auto;
		.whatIsThreeZones_title{
			font-weight: $boldFont;
			line-height: 54px;
			font-size: 46px;
			text-align: center;
			letter-spacing: -0.2px;
			color: $darkBlue;
			margin-bottom: 40px;
		}
		p{
			font-weight: $lightFont;
			line-height: 38px;
			text-align: center;
			font-size: 28px;
			color: $darkBlue;
		}
	}
}

@media (max-width: 1440px){
	.whatIsThreeZones{
		padding: 60px 0;
	}
}
@media (max-width: 1024px){
	.whatIsThreeZones{
		padding: 90px 0;
		padding-bottom: 30px;
	}
	.whatIsThreeZones .whatIsThreeZones_content .whatIsThreeZones_title{
		font-size: 37px;
		margin-bottom: 30px;
	}
	.whatIsThreeZones .whatIsThreeZones_content p{
		line-height: 31px;
		font-size: 23px;
	}
	.whatIsThreeZones .grid-container{
		max-width: 610px;
	}
}
@media (max-width: 767px){
	.whatIsThreeZones{
		padding-top: 60px;
	}
	.whatIsThreeZones .whatIsThreeZones_content .whatIsThreeZones_title{
		font-size: 26px;
	}
	.whatIsThreeZones .whatIsThreeZones_content p{
		line-height: 26px;
		font-size: 19px;
	}
	.whatIsThreeZones .grid-container{
	}
}