.formSection{
	float: left;
	width: 100%;
	text-align: center;
	padding: 140px 0;
	color: #fff;
	margin-top: 60px;

	h2{
		font-weight: $boldFont;
		line-height: normal;
		font-size: 46px;
		margin: 0; padding: 0;
		letter-spacing: 0px;
		font-weight: $lightFont;
		padding: 0 20px;

	}
	.content{
		max-width: 695px;
		margin: 0 auto;
		margin-top: 40px;

		p{
			font-size: 28px;
			line-height: 39px;
			margin: 0;
			letter-spacing: -0.2px;
			padding: 0;
		}
		a{
			margin-top: 50px;
			display: inline-block;
			font-size: 16px;

			width: 220px;
			background-color: $darkBlue;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
			border-radius: 3px;
			text-align: center;
			position: relative;
			padding: 15px 10px;
			color: #fff;
			@include buttonAnimation(#fff); 
			span{
				z-index: 1;
				position: relative;
			}
			&:hover span{
				color: $darkBlue;
			}
		}
	}
}


@media (max-width: 1024px){
	.formSection{
		padding: 110px 0;
	}
	.formSection h2{
		font-size: 37px;
		max-width: 540px;
		margin: 0 auto;
		padding: 0;
	}
	.formSection .content{
		max-width: 492px;

		p{
			line-height: 1.4em;
			font-size: 28px;
			max-width: 500px;
			margin: 0 auto;
		}
	}
}
@media (max-width: 767px){
	.formSection{
		margin-top: 0px;
		padding: 80px 0;
	}
	.formSection h2{
		font-size: 28px;
	}
	.formSection .content{
		padding: 0 20px;
		p{		
			font-size: 19px;
			line-height: 1.4em;
		}
	}
}