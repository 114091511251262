.text_full_width{
	float: left;
	padding: 60px 0;
	padding-bottom: 90px;
	width: 100%;
	.content{
		line-height: 38px;
		max-width: 800px;
		margin: 0 auto;
		font-size: 28px;
		text-align: center;
		letter-spacing: -0.2px;
		p{
			margin-bottom: 80px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}


@media (max-width: 1024px){
	.text_full_width .content{
		font-size: 23px;
		line-height: 1.2em;
		max-width: 540px;
		margin: 0 auto;
	}
}
@media (max-width: 767px){
	.text_full_width .content{
		font-size: 19px;
		line-height: 1.2em;
		padding: 0 20px;
	}
}
