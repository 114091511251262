.intro_page{
	float: left;
	width: 100%;
	padding: 60px 0px;
	.intro_page__leftSection{

		display: flex;
		align-items: center;
		height: 100%;
		position: relative;

		h2{
			text-transform : uppercase;
			font-size: 20px;
			letter-spacing: .4em;
			font-weight: $boldFont;
			margin-top: -60px;
			position: absolute;
			left: 0;
		}
		.leftIcon{
			margin-left: auto;
			svg{
				height: 300px;

			}
		}
	}
	.intro_page__rightSection{

		display: flex;
		align-items: center;
		height: 100%;

		.content{
			font-weight: $normalFont;
			line-height: normal;
			font-size: 39.35px;
			
			a{

				min-width: 220px;
				float: left;
				display: block;
				background-color: $blue;
				box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
				border-radius: 3px;
				text-align: center;
				position: relative;
				padding: 15px 10px;
				color: #fff;
				font-size: 16px;
				margin-top: 35px;
				span{
					position: relative;
					z-index: 1;
				}

				@include buttonAnimation($darkBlue); 

			}
		}
	}
}

@media (max-width: 1230px){
	.intro_page{
		padding: 60px 45px;
	}
}

@media (max-width: 1024px){
	.intro_page{
		padding: 35px 45px;
	}
	.intro_page .intro_page__leftSection h2{
		font-size: 13px;
	}
	.intro_page .intro_page__rightSection .content{
		font-size: 28px;
	}
	.intro_page .intro_page__leftSection .leftIcon svg{
		height: 200px;
	}
}
@media (max-width: 767px){
	.intro_page{
		padding: 15px 0;
		padding-bottom: 30px;
	}
	.intro_page .intro_page__leftSection h2{
		font-size: 10px;
		left: auto;
		right: 80px;
		margin-top: 0px;
		line-height: 1em;
		margin-bottom: 0px;
		bottom: 90px;
	}
	.intro_page .intro_page__rightSection .content{
		font-size: 26px;
		margin-top: 20px;
	}
	.intro_page .intro_page__leftSection .leftIcon svg{
		height: 140px;
	}
	.intro_page .intro_page__rightSection .content a{
		margin-top: 50px;
	}
}