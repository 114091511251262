//colors

$blue : #39A9DC;
$darkBlue : #002E5D;
$red : #EF4253;
$yellow : #FCCB00;
$green : #02B7A4;

html, body{
	max-width: 100%;
	min-width: 320px;
	color: $darkBlue;
}

// %message-shared {
//   border: 1px solid #ccc;
//   padding: 10px;
//   color: #333;
// }
// @extend %message-shared;

//fonts weight
$lightFont : 300;
$normalFont : 400;
$mediumFont : 500;
$boldFont : 700;


h2, p{
	line-height: 1.2em;
}


@import 'components/helpers';

//commons
@import 'components/commons/top/top';
@import 'components/commons/top/topMobile';
@import 'components/commons/footer/footer';


//sections
@import 'components/sections/mainPage/intro';
@import 'components/sections/mainPage/whatIs_mainPage';
@import 'components/sections/mainPage/workBox_mainPage';
@import 'components/sections/mainPage/workBox_1col_mainPage';
@import 'components/sections/mainPage/textBox_mainPage';
@import 'components/sections/mainPage/franchise_mainPage';
@import 'components/sections/mainPage/troops_mainPage';
@import 'components/sections/mainPage/footer_mainPage';
@import 'components/sections/mainPage/news_box';



@import 'components/sections/pages/intro';
@import 'components/sections/pages/text_module';
@import 'components/sections/pages/text_module_subtitle';
@import 'components/sections/pages/image_text_box';
@import 'components/sections/pages/image_text_box_color';
@import 'components/sections/pages/form_section';
@import 'components/sections/pages/industries';
@import 'components/sections/pages/industries_with_positions';
@import 'components/sections/pages/row_3_box_icon';
@import 'components/sections/pages/row_5_circle_box';
@import 'components/sections/pages/row_2_box';
@import 'components/sections/pages/text_full_width';
@import 'components/sections/pages/margin_change';
@import 'components/sections/pages/row_3_box_icon_withTitle';
@import 'components/sections/pages/person';
@import 'components/sections/pages/textSection_bigTitle';
@import 'components/sections/pages/row_3_box_offer';
@import 'components/sections/pages/row_3_only_text';
@import 'components/sections/pages/contact_full_width';
@import 'components/sections/pages/text_full_width__content__more_text__button';
@import 'components/sections/pages/box_image_background';
@import 'components/sections/pages/new_offers';
@import 'components/sections/pages/media';
@import 'components/sections/pages/download';


@import 'components/sections/offers/offersTop';
@import 'components/sections/offers/offers';
@import 'components/sections/offers/offersSections';
@import 'components/sections/offers/offersSection_tmpl';

@import 'components/sections/offers/offersPage/offersPage';
@import 'components/sections/offers/offersPage/offersPage_section';

@import 'components/sections/form/form';
	@import 'components/sections/form/forms/employee';
	@import 'components/sections/form/forms/forms_modal';


@import 'components/sections/blog/blog_section';
@import 'components/sections/blog/blog_post';
@import 'components/sections/blog/recent_post';
@import 'components/sections/blog/blog_newsletter';


.imageFullWidth{
	padding: 60px 0;
	float: left;
	width: 100%;
	padding-top: 30px;
}
.image_mobile{
	display: none;
}

.troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__right{
	display: none;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic{
	display: none;
}
.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item{
	text-align: center;
}
.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content a::after{
	text-align: center;
	display: inline-block;
}

.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content a{
	float: none;
}

.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content a {
    float: none;
    display: block;
    line-height: 1em;
}

@media (min-width: 1320px){
	.grid-container.grid-container--max{
			max-width: 1680px;
			padding-right: 60px; padding-left: 60px;
	}
}

@media (max-width: 1024px){

.imageFullWidth{
		padding: 60px 0;
		padding-bottom: 35px;
		float: left;
		width: 100%;
		padding-top: 0px;
	}

}
@media (max-width: 767px){
	.grid-container{
		padding: 0 20px;
	}
	.image_mobile{
		display: block;
	}
	.image_desktop{
		display: none;
	}



}

.hideCookie{
	display: none!important;
}
