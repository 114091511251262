.siteFooter{
	padding: 35px 0;
	
	float: left;
	width: 100%;


	.siteFooter_grid{
		display: flex;
		align-items: center;
		float: left; width: 100%;
		.siteFooter_left{
			font-size: 16px;
		}
		.siteFooter_right{
			margin-left: auto;
			ul{
				@extend %noPadding;
				li{

					float: left;
					margin-left: 30px;

					a{
						svg{
							height: 19px;
							path{
								fill: $darkBlue;
								transition-duration: .2s;
							}
							&:hover{
								path{
									fill: $blue;
								}
							}
						}
					}
				}
			}
		}
	}
}


@media (max-width: 768px){
	.siteFooter .siteFooter_grid{
		display: block;
	}
	.siteFooter .siteFooter_grid .siteFooter_left,
	.siteFooter .siteFooter_grid .siteFooter_right{
		float: left;
		width: 100%;
		text-align: center;
	}
	.siteFooter .siteFooter_grid .siteFooter_right ul{
		margin-top: 30px;
	}
	.siteFooter .siteFooter_grid .siteFooter_right ul li{
		float: none;
		margin: 0 15px;
		display: inline-block;
	}
}