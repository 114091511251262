.showClass{
	position: absolute;
	left: 0; right: 0; top: 0; bottom: 0;
	display: none;
	background-color: $green;
	z-index: 1000;
	&.red{
		display: block;
	}

}
.employee_form{
	min-height: calc(100vh - 91px);
	display: flex;
	align-items: center;
	justify-content: center;


	form{
		min-height: calc(100vh - 91px);
		width: 100%;

		.grid-container{
		}
		.fieldGroup{
			float: left;
			width: 100%;
			min-height: 78vh;
			display: flex;
			align-items: center;
			justify-content: center;
			.fieldGroup_wrapper{
				width: 100%;
				float: left;
			}
			.fieldGroup_title{
				font-style: normal;
				font-weight: bold;
				line-height: normal;
				font-size: 28px;
				text-align: center;
				letter-spacing: -0.2px;
				margin-bottom: 50px;
			}
			.fieldGroup_info{
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				font-size: 16px;
				text-align: center;
				color: #02B7A4;
				min-height: 19px;
				margin-bottom: 60px;
				&.fieldGroup_info__hide{
					opacity: 0;
					pointer-events: none;
				}
			}


			.fieldGroup_info__item{
				width: 100%;
				position: relative;
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				justify-content: center;
				.fieldGroup_info__itemLabel{
					position: absolute;
					left: 0;
					width: calc(50% - 200px);
					text-align: right;
					padding-right: 20px;
					font-style: normal;
					font-weight: 500;
					top: 15px;
					line-height: 1.2em;
					font-size: 16px;
					text-align: right;

					color: #002E5D;
				}

				.inputGroup{
					width: 400px;

					input, textarea{
						width: 100%;
						margin: 0;
						border: 1px solid #E4E4E4;
						box-sizing: border-box;
						border-radius: 4px;
						padding: auto 15px;
						box-shadow: none;
						height: 50px;


						&.validInput{
							border: 2px solid $green;
						}
						&.smallInput{
							margin: 0;
							max-width: 100%;
							margin-right: 20px;
							float: left;
							width: 120px;
							&:last-child{
								margin-right: 0;
							}
						}

					}
					textarea{
						height: 100px;
						resize: none;
					}

					label{
						width: 100%;
						float: left;
						margin-bottom: 20px;
						input[type="radio"]{
							float: left;
							width: auto;
							height: auto;
						}
						span{
							// float: right;
							float: left;
							line-height: 1em;
							margin-left: 10px;
							font-size: 16px;
						}
					}



					&.radioGroup{
						padding-top: 16px;
						label{
							position: relative;
							padding-left: 30px;
							color: #002E5D;
							font-size: 16px;
							line-height: 20px;
							margin-bottom: 30px;
							.smallLabel{
								width: 80%;
								float: right;
								margin-top: -13px;
							}
						}
						.radioButton{
							float: left;
							width: auto;
							height: auto;
							display: block;
							width: 1px;
							height: 1px;
							overflow: hidden;opacity: 0;



						}
						.radioEnable{
							float: right;width: 80%;
							margin-top: -13px;
						}
						.inputCircle{
							position: absolute;
							width: 20px; height: 20px;
							display: block;
							background-color: #fff;
							border: 2px solid #868686;
							border-radius: 20px;
							left: 0;
							margin: 0;
							cursor: pointer;

							&::after{
								display: block;
								width: 10px; height: 10px;
								background-color: transparent;
								content: '';
								position: relative;
								left: 3px;
								top: 3px;
								border-radius: 10px;
							}

						}

						.radioButton:hover ~ .inputCircle, {
							&::after{
								background-color: #868686
							}
						}
						.radioButton:checked ~ .inputCircle{
							border-color: $blue;
							&::after{
								background-color: $blue;
							}
						}
					}


					&.radioTable{
						padding-top: 16px;
						label{
							position: relative;
							padding-left: 30px;
							color: #002E5D;
							font-size: 16px;
							line-height: 20px;
							margin-bottom: 30px;
						}
						.radioButton{
							float: left;
							width: auto;
							height: auto;
							display: block;
							width: 1px;
							height: 1px;
							overflow: hidden;opacity: 0;



						}
						.radioEnable{
							float: right;width: 80%;
							margin-top: -13px;
						}
						.inputCircle{
							position: absolute;
							width: 20px; height: 20px;
							display: block;
							background-color: #fff;
							border: 2px solid #868686;
							border-radius: 20px;
							left: 0;
							margin: 0;
							cursor: pointer;

							&::after{
								display: block;
								width: 10px; height: 10px;
								background-color: transparent;
								content: '';
								position: relative;
								left: 3px;
								top: 3px;
								border-radius: 10px;
							}

						}

						.radioButton:hover ~ .inputCircle, {
							&::after{
								background-color: #868686
							}
						}
						.radioButton:checked ~ .inputCircle{
							border-color: $blue;
							&::after{
								background-color: $blue;
							}
						}
					}

					&.radioTable{
						width: 100%;

						.radioTable_row{
							display: flex;
							flex-wrap: wrap;

							.top{
								width: 100%;
								float: left;
								display: flex;
								flex-wrap: wrap;
								.item{
									display: block;
									width: 16.666%;
									float: left;
									border-right: 1px solid #C4C4C4;
									text-align: center;

								}
							}

							.languageInfo{
								float: left;
								border-right: 1px solid #C4C4C4;
								width: 16.666%;
								padding:30px 0;
								text-align: right;
								padding-right: 20px;


							}

							label{
								float: left;
								width: 16.666%;
								text-align: center;
								margin: 0;
								padding:30px 0;
								border-right: 1px solid #C4C4C4;
								.inputCircle{
									display: inline-block;
									float: none;
									position: relative;
								}
								.text{
									display: none;
								}
							}
						}
					}



				}


				.form-error{
					display: none;
					position: absolute;
					right: 0;
					width: calc(50% - 200px);

					font-weight: 500;
					line-height: 1.2em;
					font-size: 16px;
					padding: 0; margin: 0;
					color: #EF4253;
					padding-left: 20px;
				}

				&.itemMax{
					width: 100%;
					float: left;
					display: block;
					margin-top: 40px;
					label{
						width: 16.666%;
						float: left;
						position: relative;
						top: 3px;
						span{
							display: block;
							width: 100%;
							font-size: 14px;
						}
					}
					.inputGroup{
						float: left;
						max-width: 300px;
						input{
							background: #FAFAFA;
border: 1px solid #E4E4E4;
						}
					}
				}
				&.fieldGroup_info__itemError{
					.errorInput{
						border: 2px solid $red;
						background-color: #fff;
					}
					.validInput{
						border: 2px solid $green;
						background-color: #fff;

					}

					.form-error{
						display: block;
					}
				}



			}

		}


		.input_fileGroup{
			position: relative;
			float: left;
			width: 100%;
			text-align: center;
			margin-bottom: 100px;
			.input_file{
				width: 0px; height: 0px;
				overflow: hidden;
				left: -10000000px;
				top: -1000000px;
			}
			.is-invalid-input {
				border: none!important;
			}
			.is-invalid-input + .input_file__body{
				border: 1px solid $red;
				color: $red;
			}

			.validInput +  .input_file__body{
				background-color: $blue;
				color: #fff;
			}
			.input_file__body{
					min-width: 220px;
					background-color: #fff;
					border: 1px solid $blue;
					color: $blue;
					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
					border-radius: 3px;
					text-align: center;
					position: relative;
					padding: 15px 10px;
					cursor: pointer;
					font-size: 16px;
					display: inline-block;
					margin: 0;
					span{
						position: relative;
						z-index: 1;
					}

					@include buttonAnimation($blue);

					&:hover{
						span{
							color: #fff;
						}
					}
			}
		}


		.inputGroup_checkbox{
			position: relative;
			float: left;
			width: 100%;
			text-align: center;
			margin-bottom: 100px;
			margin-top: -20px;
			.input_checkbox{
				width: 0; height: 0;
				position: absolute;
				left: -1000px; height: -10000px;
				overflow: hidden;
				opacity: 0;
				&:hover ~ .inputStyle, {
					}
				&:checked ~ .inputStyle{
						background-color: $blue;
						position: relative;
						&::after{
							position: absolute;
							left: 9px;
							top: 1px;
							content: '';
							width: 10px;
							height: 20px;
							border: solid white;
							border-width: 0 2px 2px 0;
							-webkit-transform: rotate(45deg);
							-ms-transform: rotate(45deg);
							transform: rotate(45deg);
						}
					}
			}
			.is-invalid-input + .inputStyle{
				border: 1px solid $red;
				color: $red;
			}

			.inputStyle{
				width: 29px;
				height: 29px;
				background: #FAFAFA;
				border: 1px solid #E4E4E4;
				display: inline-block;
				margin-bottom: 20px;

			}

			p{
				font-weight: 500;
				line-height: 1.5em;
				font-size: 16px;
				text-align: center;
				color: #002E5D;
			}

			a{
				font-weight: 500;
				line-height: normal;
				font-size: 16px;
				text-align: center;
				color: #39A9DC;
			}
		}



		.submit, .goToMainPage{
			text-align: center;
			float: left;
			width: 100%;
			position: relative;
			bottom:0px;
			margin-top: 20px;
			z-index: 1;
			left: 0;
			.button{
				min-width: 220px;
				background-color: $blue;
				box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
				border-radius: 3px;
				text-align: center;
				position: relative;
				padding: 15px 10px;
				color: #fff;
				font-size: 16px;
				display: inline-block;
				span{
					position: relative;
					z-index: 1;
				}

				@include buttonAnimation($darkBlue);
			}
			.buttonHide{
				display: none;
			}
		}
	}
}

.slider{
	background-color: #84D0F0;
	border-radius: 10px;
	height: 10px;
	margin-left: 10%; margin-right: 10%;
	margin-top: 6px;

	.sliderLines{
		width: 100%;
		position: absolute;
		top: 0;
		line-height: 0px;
		span{
			display: block;
			float: left;
			border-right: 1px solid #39A9DC;
			width: 25%;
			height: 10px;

		}
	}
	.slider-fill{
		background-color: #39A9DC;
		border-radius: 10px 0 0 10px;
		height: 10px;


	}
	.slider-handle{
		background-color: red;
		background-image: url('../img/icons/sliderRange.svg');
		width: 23px; height: 37px;
		background-color: transparent;
		margin-top: 5px;
	}
}

.sliderInfo{
	width: 100%;
	.item{
	width: 20%;
	float: left;
	text-align: center;

	}
}

.formWrap_done{
	&.formWrap_done__employee{
		background-image: url(../img/bg_form/1.svg);
		background-size: auto 360px;
		background-repeat: no-repeat;
		position: relative;
		background-position: left -100px center;
		&::before{
			position: absolute;
			top: 0; bottom: 0; right: 0; left: 0;
			background-image: url(../img/bg_form/2.svg);
			content: '';
			background-size: auto 360px;
			background-repeat: no-repeat;
			background-position: right -100px center;
		}

	}
	.endGroup{
		position: relative;
		z-index: 1;
		max-width: 686px;
		margin: 0 auto;
		.fieldGroup_title{
			color: #fff;
			text-align: center;
			width: 100%;
			float: left;
			font-weight: bold;
			line-height: normal;
			font-size: 28px;
			text-align: center;
			margin-bottom: 190px;
		}
		.moreInfo{
			font-style: normal;
			font-weight: normal;
			line-height: 38px;
			font-size: 28px;
			text-align: center;
			color: #fff;
			max-width: 686px;
			margin: 0 auto;
			margin-top: 100px;

		}
	}
}




.is-invalid-input:not(:focus){
	background-color: #fff;
	border: 2px solid #EF4253!important;
}
.opt{
	display: block;
	width: 100%;
	font-size: 14px;
	opacity: .4;
}

.hideItems{
	display: none;
}


// .checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
// }


// .checkboxGroup{

// 	display: block;
//     position: relative;
//     padding-left: 35px;
//     margin-bottom: 12px;
//     cursor: pointer;
//     font-size: 22px;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;

// 	input{
// 		position: absolute;
// 	    opacity: 0;
// 	    cursor: pointer;
// 	}
// 	.checkmark{
// 		display: block;
// 		width: 18px; height: 18px;
// 		border-radius: 2px;
// 		float: left;
// 		border: 2px solid #010101;
// 	}

// 	&:hover input ~ .checkmark {
// 	    background-color: #ccc;
// 	}
// 	input:checked ~ .checkmark {
//     background-color: #2196F3;
// 	}

// 	input:checked ~ .checkmark:after {
// 	    display: block;
// 	}

// 	.checkmark:after {
// 	    left: 9px;
// 	    top: 5px;
// 	    width: 5px;
// 	    height: 10px;
// 	    border: solid white;
// 	    border-width: 0 3px 3px 0;
// 	    -webkit-transform: rotate(45deg);
// 	    -ms-transform: rotate(45deg);
// 	    transform: rotate(45deg);
// 	}




// }

.fieldGroup_info__itemCheckbox{
	width: 400px; margin: 0 auto;
	.checkboxGroup {
	    display: block;
	    position: relative;
	    margin-bottom: 30px;
	    cursor: pointer;
	    font-size: 22px;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    width: 100%;
		float: left;
	    span{
	    	font-size: 16px;
			color: #0C2B83;
			float: left;
			line-height: 1em;
	    }
	}

	/* Hide the browser's default checkbox */
	.checkboxGroup input {
	    position: absolute;
	    opacity: 0;
	    cursor: pointer;
	    width: 0px; height: 0px;
	    left: -1000px; top: -1000px;
	    overflow: hidden;
	}

	/* Create a custom checkbox */
	.checkmark {
	    display: block;
		width: 18px; height: 18px;
		border-radius: 2px;
		float: left;
		border: 2px solid #DADADA;
		position: relative;
		margin-right: 10px;
		float: left;
	}

	/* On mouse-over, add a grey background color */
	.checkboxGroup:hover input ~ .checkmark {
	    background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	.checkboxGroup input:checked ~ .checkmark {
	    background-color: $blue;
	    border-color: $blue;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
	    content: "";
	    position: absolute;
	    display: none;
	}

	/* Show the checkmark when checked */
	.checkboxGroup input:checked ~ .checkmark:after {
	    display: block;
	}

	/* Style the checkmark/indicator */
	.checkboxGroup .checkmark:after {
		left: 4px;
		top: 0px;
		width: 6px;
		height: 13px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	.checkboxGroupSmall{
		float: left;
		width: auto;
	}
	.smallLabel{
		float: right;
		width: 80%;
						margin: 0;
		margin-top: -15px;

		border: 1px solid #E4E4E4;
		box-sizing: border-box;
		border-radius: 4px;
		padding: auto 15px;
		box-shadow: none;
		height: 50px;
	}
}

@media (max-width: 1200px){


.formWrap_done.formWrap_done__employee{
	background-image: none;
}


.formWrap_done.formWrap_done__employee::before{
	display: none;
}

.formWrap_done{

}

}

@media (max-width: 767px){
	.headerForm{
		padding: 0;
	}
	.headerForm .header_title{
		display: none;
	}
	.headerForm .progressG{
		width: 100%;
		padding: 0;
	}
	.headerForm .progress{
		width: 100%;
		margin-top: 0;
		margin-left: 0;
	}
	.employee_form form .submit, .employee_form form .goToMainPage{
		position: relative;
		bottom: 0;
		margin-top: 30px;
		.button{
			width: 100%;
		}
	}
	.headerForm .progress .progress-meter-text{
		padding: 0 20px;
	}




	.employee_form form .fieldGroup .fieldGroup_info__item .fieldGroup_info__itemLabel{
		position: relative;
		width: 100%;
		float: left;
		top: 0;
		text-align: left;
		padding-right: 0;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup{
		width: 100%;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item{
		display: block;
	}
	.employee_form form .fieldGroup .fieldGroup_title{
		margin-bottom: 20px;
		margin-top: 50px;
	}
	.employee_form form .fieldGroup .fieldGroup_info{
		margin-bottom: 30px;
	}
	.employee_form form .fieldGroup .fieldGroup_info.fieldGroup_info__hide{
		height: 0px;
		min-height: 0;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item{
		float: left;
		width: 100%;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item .fieldGroup_info__itemLabel{
		margin-bottom: 10px;
	}

	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup input.smallInput {

	    margin: 0;
	        margin-right: 0px;
	    max-width: 100%;
	    margin-right: 20px;
	    float: left;
	    width: calc(33% - 13px);

	}

	.employee_form form .fieldGroup .fieldGroup_info__item .form-error{
		position: relative;
		width: 100%;
		left: 0;
		margin: 0;
		float: left;
		padding: 0;
		font-size: 12px;
		margin-top: 5px;
	}

	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup label{
		padding: 20px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 0;
	}
	.employee_form form .fieldGroup .fieldGroup_info__itemRadio .fieldGroup_info__itemLabel{
		padding: 20px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 0;
	}
	.fieldGroup_info__itemRadio{
		background: #FFFFFF;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
		border-radius: 2px;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup .inputCircle{
		position: relative;
		margin-right: 10px;
	}


	 .employee_form form .fieldGroup_info__itemMobileStyle .fieldGroup_info__itemLabel{
		padding: 20px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 0;
	}

	.employee_form form .fieldGroup_info__itemMobileStyle{
		background: #FFFFFF;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
		border-radius: 2px;
		.inputGroup{
			padding: 0 20px;
		}
	}

	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup{
		float: left;
	}

.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup{
	padding-top: 0;
}
	.sliderInfo .item{
		font-size: 14px;
	}
	.sliderInfo {
		float: left;
    	width: 100%;

		margin-bottom: 40px;
	}
	.slider {

	    background-color: #84D0F0;
	    border-radius: 10px;
	    height: 10px;
	    margin-left: 7%;
	    margin-right: 7%;
	    margin-top: 6px;
		margin-bottom: 22px;
		margin-top: 40px;
	}


	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row {

    display: block;

    width: 100%;
    float: left;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row .languageInfo{
		width: 100%;
		border: none;
		padding: 0;
		text-align: left;
		padding: 20px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 0;
		margin: 0;
	}

	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row .top{
		display: none;
	}

	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row label{
		border: none;
		padding: 0;
		text-align: left;
		border-bottom: 1px solid #C4C4C4;
		width: 100%;
		padding: 20px;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row label .inputCircle{
		float: left;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row label .text{
		display: inline-block;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row{
		background: #FFFFFF;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
		border-radius: 2px;
		margin-bottom: 30px;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item.itemMax{
		margin-top: 0;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item.itemMax label{
		width: 100%;
		margin-top: 0;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item.itemMax .inputGroup{
		width: 100%;
	}
	.employee_form form .input_fileGroup .input_file__body{
		margin-top: 20px;
	}
	.employee_form form .inputGroup_checkbox .inputStyle{
		margin-top: 20px;
	}
	.employee_form form .inputGroup_checkbox p, .employee_form form .inputGroup_checkbox a{
		font-size: 14px;
	}
	.employee_form form .inputGroup_checkbox{
		margin-bottom: 20px;
	}
	.employee_form form .input_fileGroup{
		margin-bottom: 50px;
	}
	.progress-meter-text{
		white-space: normal;
	}
	.employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup label .smallLabel{
		width: 100%;
		margin-top: 20px;
		float: left;
	}
	.employee_form{
		display: block;
	}
	.headerForm .progressG{
		height: 80px;
	}
	.fieldGroup_info__itemCheckbox{
		width: 100%;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
	}
	.fieldGroup_info__itemCheckbox .checkboxGroup{
		padding: 20px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 0;
	}
.fieldGroup_info__itemCheckbox .smallLabel {

    float: left;
    width: calc(100% - 40px);
    margin: 20px;
        margin-top: 20px;
    margin-top: 0;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: auto 15px;
    box-shadow: none;
    height: 50px;
}
.fieldGroup_info__itemCheckbox .checkboxGroupSmall{
	width: 100%;
	border: none;
}
.formWrap_done.formWrap_done__employee{
	background-image: none;
}
.formWrap_done .endGroup .fieldGroup_title{
	margin-bottom: 20px;
}
.formWrap_done .endGroup .moreInfo{
	padding: 0 20px;
	font-size: 18px;
	opacity: 1;
}
.formWrap_done.formWrap_done__employee::before{
	display: none;
}


}


.mainPageButton{
	text-align: center;
	float: left;
	width: 100%;
	position: absolute;
	bottom: 50px;
	z-index: 1;
	left: 0;
	.button{
		min-width: 220px;
		background-color: $blue;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
		border-radius: 3px;
		text-align: center;
		position: relative;
		padding: 15px 10px;
		color: #fff;
		font-size: 16px;
		display: inline-block;
		span{
			position: relative;
			z-index: 1;
		}

		@include buttonAnimation($darkBlue);
	}
	.buttonHide{
		display: none;
	}
}

// 
// @media (max-height: 640px){
// 	.employee_form form .submit, .employee_form form .goToMainPage{
// 		position: relative;
// 		bottom: 0;
// 	}
// 	.employee_form form .fieldGroup .fieldGroup_title{
// 		margin-bottom: 30px;
// 	}
// 	.employee_form form .fieldGroup .fieldGroup_info{
// 		margin-bottom: 40px;
// 	}
// }
