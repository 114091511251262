.row_3_box_icon_withTitle{
	float: left;
	width: 100%;
	padding-top: 0px;
	padding-bottom: 60px;
	.row_3_box_icon_withTitle__items{
		padding: 0 60px;
		.row_3_box_icon_withTitle__item{
			margin-bottom: 0px;
			padding: 0 40px;
			.icon{
				float: left;
				width: 100%;
				margin-bottom: 30px;
				height: 90px;
				display: flex;
				align-items: flex-end;
				svg{
					max-width: 80px;
					max-height: 90px;
				}
			}
			h4{
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				font-size: 20px;
				letter-spacing: -0.2px;
				width: 100px;
			}
			.content{
				p{
					line-height: 25px;
					margin-top: 30px;
					font-size: 16px;
				}
			}
		}
	}
}

@media (max-width: 1024px){
	.row_3_box_icon_withTitle .row_3_box_icon_withTitle__items .row_3_box_icon_withTitle__item h4{
		width: 100%;
	}
	.row_3_box_icon_withTitle{
		padding-bottom: 60px;
	}
	.row_3_box_icon_withTitle{
		float: left;
		width: 100%;
		margin-bottom: 0px;
		.row_3_box_icon_withTitle__item{
			padding: 0 52px;
			.icon{
				float: left;
				width: 100%;
				margin-bottom: 30px;
				height: 90px;
				display: flex;
				align-items: flex-end;
				svg{
					max-width: 80px;
					max-height: 90px;
				}
			}
			h4{
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				font-size: 20px;
				letter-spacing: -0.2px;
			}
		}
	}

	.row_3_box_icon_withTitle .row_3_box_icon_withTitle__items .row_3_box_icon_withTitle__item{
		margin-bottom: 60px;
	}


}
@media (max-width: 767px){

	.row_2_box .cell .item, .row_2_box .cell:nth-child(2n+2) .item{
		padding: 0 20px;
	}
	.row_3_box_icon_withTitle .row_3_box_icon_withTitle__items{
		padding: 0;
	}
	.row_3_box_icon_withTitle{
		float: left;
		width: 100%;
		.grid-margin-x > .small-12 {

		width: 100%;

		}
		.grid-margin-x > .cell {

			width: calc(100% - 1.25rem);
			margin-left: 0;
			margin-right: 0;
			width: 100%;

		}
		.row_3_box_icon_withTitle__item{
			padding: 0 45px;
			.icon{
				float: left;
				width: 100%;
				margin-bottom: 30px;
				height: 90px;
				display: flex;
				align-items: flex-end;
				svg{
					max-width: 80px;
					max-height: 90px;
				}
			}
			h4{
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				font-size: 17px;
				letter-spacing: -0.2px;
			}
		}
	}
}
