

.offersPageWrap_section{
	float: left;
	width: 100%;

	.offersPage_tmpl{
		padding: 60px 0;
		float: left;
		width: 100%;
		.offersPage_tmpl__header{
			margin-bottom: 60px;
			h2{
				font-style: normal;
				font-weight: bold;
				line-height: normal;
				font-size: 46px;
				text-align: center;
				line-height: 1em;
				color: $darkBlue;
			}
		}
		.offersPage_tmpl__items{

			.offersPage_tmpl__item{
				margin-bottom: 30px;
				box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
				border-radius: 4px;
				padding: 30px;
				background-color: #fff;
				transition-duration: .3s;

				&:hover{
					box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
				}

				&.offersPage_tmpl__itemOpen{
						.offersPage_tmpl__itemContent{
							.content{
								.contentShort{
									display: none;
								}
								.contentFull{
									display: block;
								}
							}
						}

						.goTo{
							.apply{
								.hideText{
									display: inline-block;
								}
								.applyText{
									display: none;
								}

								img,svg{
									transform: rotate(180deg);
								}
							}
							.goToForm{
								display: inline-block;
							}
						}
				}

				.offersPage_tmpl__itemContent{
					display: block;
					float: left;
					width: calc(100% - 200px);
					padding-right: 30px;
					.category{
						width: 100%;
						float: left;
						margin-bottom: 20px;
						.category_item{
							background-color: rgba(2, 183, 164, 0.05);
							color: $green;
							padding: 8px 15px;
							border-radius: 20px;
							font-size: 10px;
							font-weight: 700;
							float: left;
							letter-spacing: 1px;
							text-transform: uppercase;
						}
					}
					.title{
						font-style: normal;
						font-weight: normal;
						line-height: normal;
						font-size: 28px;
						width: 100%;
						margin-bottom: 15px;
						float: left;
					}
					.forWho{
						width: 100%;
						float: left;
						font-weight: bold;
						line-height: 12px;
						font-size: 12px;
						text-transform: uppercase;
						margin-bottom: 40px;
					}
					.date{
						width: 50%;
						float: left;
						font-weight: 500;
						line-height: 12px;
						font-size: 12px;
						display: flex;
						align-items: center;
						text-transform: uppercase;
						img, svg{
							margin-right: 10px;
						}
						&.mobileShow{display: none;}
					}
					.place{
						width: 50%;
						float: left;
						font-weight: 500;
						line-height: 12px;
						font-size: 12px;
						display: flex;
						align-items: center;
						text-transform: uppercase;
						img, svg{
							margin-right: 10px;
						}
						&.mobileShow{display: none;}
					}
					.content{
						margin-top: 0;
						font-style: normal;
						font-weight: normal;
						float: left;
						width: 100%;
						.contentShort{
							display: block;
						}
						.contentFull{
							display: none;
						}

						p{
							line-height: 1.5em;
							font-size: 16px;
							opacity: .8;
							&:last-child{
								margin-bottom: 0;
							}
						}
					}


				}

				.itemRight{
					width: 200px;
					float: left;
					.category{
						width: 100%;
						float: left;
						margin-bottom: 20px;
						.category_item{
							background-color: rgba(2, 183, 164, 0.05);
							color: $green;
							padding: 8px 15px;
							border-radius: 20px;
							font-size: 10px;
							font-weight: 700;
							float: left;
							letter-spacing: 1px;
							text-transform: uppercase;
						}
					}

					.date{

						width: 100%;
						float: left;
						font-weight: 500;
						line-height: 12px;
						font-size: 12px;
						display: flex;
						align-items: center;
						text-transform: uppercase;
						span{
							width: 20px;
							text-align: center;
							margin-right: 10px;
						}
						img, svg{

						}
						&.mobileShow{display: none;}
					}
					.place{

						width: 100%;
						float: left;
						font-weight: 500;
						line-height: 12px;
						font-size: 12px;
						display: flex;
						align-items: center;
						margin-top: 25px;
						text-transform: uppercase;
						span{
							width: 20px;
							text-align: center;
							margin-right: 10px;
						}
						img, svg{

						}
						&.mobileShow{display: none;}
					}
				}

				.goTo{
					margin-top: 25px;
					float: left;
					width: 100%;
					display: flex;
					align-items: center;
					.apply{
						font-size: 16px;
						color: $blue;
						width: 100px;
						display: inline-block;
						.applyText{
							display: inline-block;
						}
						.hideText{
							display: none;
						}
						img,svg{
							margin-left: 5px;
							position: relative;
							&.iconRotate{
								transform: rotate(180deg);
							}
						}
						&:hover{
							img,svg{
								animation: mymove .8s forwards;
							}
						}
					}

					.goToForm{
						float: left;
						flex-grow: 100;
						text-align: center;
						display: none;
						button{
							min-width: 220px;
							display: inline-block;
							margin-left: -100px;
							background-color: $darkBlue;
							box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
							border-radius: 3px;
							text-align: center;
							position: relative;
							padding: 15px 10px;
							color: #fff;
							font-size: 16px;
							color: #fff;
							span{
								position: relative;
								z-index: 1;
								color: #fff;

							}

							@include buttonAnimation($blue);
						}
					}
				}



			}
		}
	}

	.offersPage_pagination{
		float: left;
		width: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		a, span{
			border: 1px solid #D0D0D0;
			box-sizing: border-box;
			border-radius: 100px;
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			color: $blue;
			font-size: 22px;
			font-weight: 500;
			justify-content: center;
			line-height: 1em;
			margin: 0 5px;
			transition-duration: .3s;

			li{
				list-style: none;
				display: inline-block;
			}

			&.prev{
				margin-right: 60px;
				img{
					height: 15px;
				}
			}
			&.next{
				margin-left: 60px;
				img{
					height: 15px;
				}
			}

			&:hover{
				background-color: #fff;
			}
		}
		span{
			border: 1px solid $blue;

		}
	}
	.mobileShow{
		display: none;
	}
}


@media (max-width: 767px){
	.mobileHide{
		display: none;
	}
	.offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent{
		padding-right: 0;
		width: 100%;
	}
	.offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .date.mobileShow,
	.offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .place.mobileShow{
		display: block;
		
	}
	.offersPageWrap_section{
		.mobileShow{
			display: block;
		}
	}
	.offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .content{
		margin-top: 25px;
	}
}
