.imageTextBoxColor{
	float: left;
	width: 100%;
	padding-top: 75px;
	padding-bottom: 120px;
	.imageTextBoxColor_content{
		padding: 75px 60px;
		.imageTextBoxColor_content__image{
			padding-right: 60px;
			img{

			}
		}
		.imageTextBoxColor_content__text{
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.wrap{
				width: 360px;
				margin-left: -30px;
				font-size: 16px;
				font-weight: $lightFont;
				line-height: 39px;
				line-height: 24px;
				
				p{
					line-height: 1.5em;
					font-weight: $normalFont;
				}
				.title{
					line-height: 39px;
					font-size: 28px;
					font-weight: $lightFont;
					position: relative;
					padding-bottom: 30px;
					margin-bottom: 30px;
					&::after{
						display: block;
						bottom: -3px;
						height: 3px;
						width: 50%;
						right: 0;
						background-color: $darkBlue;
						position: absolute;

						content: '';
					}
				}
			}
		}
		&:nth-child(2n + 2){
			.grid-x{
				flex-direction: row-reverse;
				.imageTextBoxColor_content__image{
					padding-right: 0;
					padding-left: 60px;
				}
				.imageTextBoxColor_content__text{
					.wrap{
						margin-left: 0;
						margin-right: -30px;
					}
				}
			}
		}
		&:first-child{
			padding-top: 0;
		}
		&:last-child{
			padding-bottom: 0;
		}
	}
}


@media (max-width: 1024px){
	
	.imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap .title{
		font-size: 23px;
		line-height: 1.2em;
	}
	.imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap p{
		font-size: 16px;
		line-height: 1.3em;
	}
	.imageTextBoxColor .imageTextBoxColor_content{
		margin: 0 auto;
	}
	.imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__image,
	.imageTextBoxColor .imageTextBoxColor_content:nth-child(2n+2) .grid-x .imageTextBoxColor_content__image{
		padding-right: 0px;
		padding-left: 0;
	}
	.imageTextBoxColor .imageTextBoxColor_content .grid-x, .imageTextBoxColor .imageTextBoxColor_content:nth-child(2n+2) .grid-x{
		flex-flow: column-reverse wrap;
	}
	.imageTextBoxColor .imageTextBoxColor_content{
		max-width: 492px;
		padding: 75px 0;
		padding-top: 0px;
	}
	.imageTextBoxColor .imageTextBoxColor_content:nth-child(2n+2) .grid-x .imageTextBoxColor_content__text .wrap{
		margin-right: 0;
	}
	.imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap{
		width: 100%;
		margin-left: 0;
		margin-bottom: 60px;
		// p{
		// 	width: 100%;
		// 	margin: 0;
		// 	font-size: 23px;
		// }
	}
	.imageTextBoxColor{
		padding-bottom: 75px;
	}


}
@media (max-width: 767px){

	.imageTextBoxColor{
		padding: 0 20px;
		padding-top: 40px;
	}
	.imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap{
		text-align: center;
		line-height: 27px;
		font-size: 19px;
		margin-bottom: 40px;
		p{
			line-height: 27px;
			font-size: 19px;
			line-height: 1.3em;
		}
	}
	.imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap .title{
		margin-bottom: 17px;
		font-weight: $normalFont;
		line-height: 1.2em;
		padding-bottom: 17px;
	}


}