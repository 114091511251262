.text_full_width--media{
	float: left;
	padding: 90px 0;
	padding-bottom: 120px;
	width: 100%;
	.content{
		line-height: 38px;
		max-width: 800px;
		margin: 0 auto;
		font-size: 28px;
		text-align: center;
          letter-spacing: -0.2px;
          margin-bottom: 60px;
		p{
			margin-bottom: 80px;
			&:last-child{
				margin-bottom: 0;
			}
          }
     }
          
     .person{
          // display: flex;
          // justify-content: center;
          padding: 0;
          .person_wrap{
               // padding-left: 200px;
          }
          .person_image{
               float: left;
               width: 50%;
               // border: 1px solid red;
               padding-right: 50px;
               .person_image_wrap{
                    width: 140px;
                    height: 140px;
                    border-radius: 100%;
                    overflow: hidden;
                    float: right;

                    img{
                         width: 100%;
                    }
               }
               
          }
          .person_info{
               padding-left: 50px;
               float: left;
               width: 50%;
               // border: 1px solid green;
               .name{
                    line-height: 26px;
                    font-size: 24px;
                    font-weight: 500;
               }
               .name_more{
                    margin-top: 20px;
                    p{
                         font-size: 16px;
                         margin-bottom: 5px;
                    }
               }
               .name_contact{
                    margin-top: 20px;
                    p{
                         margin: 0; padding: 0;
                         margin-bottom: 15px;
                         width: 100%;
                         float: left;
                         &:last-child{
                              margin-bottom: 0;
                         }
                         a{
                              font-size: 16px;
                              text-decoration: none;
                              color: $blue;
                              float: left;
                              transition-duration: .3s;

                              &::after{
                                   transition-duration: .3s;
                                   content: '';
                                   width: 0%;
                                   background-color: $red;
                                   height: 1px;
                                   float: none;
                                   margin-top: 5px;
                                   display: block;
                              }
                              &:hover{
                                   color: $red;
                                   &::after{
                                        width: 50%;
                                   }
                              }
                         }
                    } 
               }
          }
     }

}


@media (max-width: 1024px){
     .text_full_width--media{
          padding: 35px 0;
          padding-bottom: 70px;
     }
	.text_full_width--media .content{
		font-size: 23px;
		line-height: 1.2em;
		max-width: 540px;
          margin: 0 auto;
          margin-bottom: 50px;
     }
     .text_full_width--media .person .person_image{
          padding-right: 20px;
     }
     .text_full_width--media .person .person_info{
          padding-left: 20px;
     }
}
@media (max-width: 767px){
	.text_full_width--media .content{
		font-size: 19px;
		line-height: 1.2em;
		padding: 0 20px;
     }
     .text_full_width--media .person .person_image{
          width: 100%;
          text-align: center;
          padding-right: 0;
          margin-bottom: 30px;
     }
     .text_full_width--media .person .person_image .person_image_wrap{
          float: none;
          display: inline-block;
     }
     
     .text_full_width--media .person .person_info{
          width: 100%;
     }
     .text_full_width--media .person .person_info{
          text-align: center;
          padding-left: 0;
     }
     .text_full_width--media .person .person_info .name_contact p{
          text-align: center;
     }
     .text_full_width--media .person .person_info .name_contact p a{
          float: none;
          display: inline-block;
     }
}
