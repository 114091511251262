.textWithTitle_subtitle{
	padding: 60px 0;
	padding-bottom: 30px;
	float: left;
	width: 100%;
	.textWithTitle_subtitle_content{
		max-width: 1000px;
		margin: 0 auto;
		h3{
			font-size: 14px;
			letter-spacing: 1.4px;
			font-weight: 500;
			text-transform: uppercase;
			float: left;
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
		}
		.content{
			font-style: normal;
			font-weight: $lightFont;
			line-height: 1.2em;
			font-size: 46px;
			text-align: center;
			letter-spacing: -0.2px;

			p{
				margin: 0; padding: 0;
			}
		}
		.content_more{
			text-align: center;
			max-width: 834px;
			margin: 0 auto;
			margin-top: 20px;

			line-height: 38px;
			font-size: 28px;
			font-weight: $lightFont
		}
	}
}

@media (max-width: 1024px){
	.textWithTitle_subtitle{
		padding: 35px 0;
		padding-bottom: 60px;
		float: left;
		width: 100%;

		.textWithTitle_subtitle_content{
			max-width: 540px;
			margin: 0 auto;
			h3{
				font-size: 14px;
				letter-spacing: 1.4px;
				font-weight: 500;
				text-transform: uppercase;
				float: left;
				width: 100%;
				text-align: center;
			}
			.content{
				font-style: normal;
				font-weight: bold;
				line-height: 1.2em;
				font-size: 37px;
				text-align: center;
				letter-spacing: -0.2px;

			}
		}
	}

	.textWithTitle_subtitle .textWithTitle_subtitle_content .content_more{
		margin-top: 30px;
		font-size: 23px;
		line-height: 1.2em;
	}

}

@media (max-width: 767px){
	.textWithTitle_subtitle{
		padding: 35px 0;
		padding-bottom: 35px;
		float: left;
		width: 100%;

		.textWithTitle_subtitle_content{
			max-width: 540px;
			margin: 0 auto;
			h3{
				font-size: 14px;
				letter-spacing: 1.4px;
				font-weight: 500;
				text-transform: uppercase;
				float: left;
				width: 100%;
				text-align: center;
			}
			.content{
				font-style: normal;
				font-weight: bold;
				line-height: 1.2em;
				font-size: 28px;
				text-align: center;
				letter-spacing: -0.2px;

			}
		}
	}

	.textWithTitle_subtitle .textWithTitle_subtitle_content .content_more{
		font-size: 19px;
	}

}
