.workBox{
	float: left;
	width: 100%;
	padding-bottom: 75px;
	.number-2{
		width: 50%;
	}
	.workBox_box{
		padding: 60px 30px 90px 30px;
		float: left;
		width: 100%;
		transition-duration: .2s;
		height: 100%;
		position: relative;
		header{
			position: relative;
			margin-bottom: 40px;
			h3{
				font-size: 24px;
				color: $darkBlue;
				margin-bottom: 15px;
				font-weight: $mediumFont;
			}

			&::before{
				width: 50%;
				content: '';
				height: 3px;
				background-color: $darkBlue;
				position: absolute;
				bottom: -3px;
				right: 0;
			}
		}
		.workBox_content{
			font-weight: normal;
			line-height: 25px;
			font-size: 16px;
			margin-bottom: 30px;
			opacity: 1;
			color: $darkBlue;
			float: left;
			width: 100%;
			p{
				margin-bottom: 0;
			}
			strong{
				font-size: 28px;
				line-height: 38px;
				display: block;
				opacity: 1;
				font-weight: 300;
				margin-bottom: 25px;
			}
		}

		.workBox_more{
			background: #39A9DC;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
			border-radius: 3px;
			font-size: 16px;
			text-align: center;
			min-width: 220px;
			color: #fff;
			font-weight: $normalFont;
			padding: 18px;
			display: block;
			position: absolute;
			bottom: 30px;
			@include buttonAnimation($red);
			span{
				position: relative;
				z-index: 1;
			}
		}
		&:hover{
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
			border-radius: 3px;
			// .workBox_more{
			// 	&::after{
			// 		top: -100%;
			// 	}
			// 	&::before{
			// 		top: -120%;
			// 	}
			// }
		}
	}
}


@media (max-width: 1440px){
	.workBox{
		padding-bottom: 60px;
	}
}
@media (max-width: 1024px){
	.workBox{
		padding-bottom: 45px;
	}
	.workBox .workBox_box{
		text-align: center;
	}
	.workBox .workBox_box header h3{
		line-height: 26px;
		font-size: 21px;
		text-align: left;
	}

	.workBox .workBox_box .workBox_content{
		font-size: 16px;
		margin-bottom: 45px;
		text-align: left;
	}
	.workBox .workBox_box .workBox_content strong{
		line-height: 31px;
		font-size: 23px;
		text-align: left;
	}
	.workBox .workBox_box .workBox_more{
		display: inline-block;
		left: 30px;
	}
	.workBox .workBox_box header{
		margin-bottom: 45px;
	}
}


@media (max-width: 767px){
	.workBox{
		padding-bottom: 35px;
	}
	.workBox .workBox_box{
		text-align: center;
	}
	.workBox .workBox_box header h3{
		line-height: 25px;
		font-size: 18px;
		text-align: left;
	}

	.workBox .workBox_box .workBox_content{
		font-size: 16px;
		text-align: left;
	}
	.workBox .workBox_box .workBox_content strong{
		line-height: 26px;
		font-size: 19px;
		text-align: left;
	}
	.workBox .workBox_box .workBox_more{
		display: inline-block;
		width: calc(100% - 60px);
		left: 30px;
	}
	.workBox .workBox_box header{
		margin-bottom: 40px;
	}
	.workBox .workBox_box{
		padding-top: 40px;
	}
	.workBox .workBox_box .workBox_content{
		margin-bottom: 30px;
	}
}
