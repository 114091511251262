.person{
	padding: 0;
	padding-bottom: 120px;
	float: left;
	width: 100%;      
	.title{
		text-align: center;
		font-weight: 300;
		line-height: 1.2em;
		font-size: 28px;
		text-align: center;
		letter-spacing: -0.2px;
		margin-bottom: 50px;
	}
	.items{
		margin: 0 auto;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		.item{
			padding: 0 60px;
			text-align: center;
			transition-duration: .3s;
			.image{
				width: 140px;
				display: inline-block;
				height: 140px;
				border-radius: 140px;
				overflow: hidden;
				img{
					width: 100%;
				}
			}
			.name{
				font-size: 20px;
				text-align: center;
				font-weight: 300;
				margin-top: 30px;
			}
			&:hover{
				transform: scale(1.02);
			}
		}
		
	}
}

@media (max-width: 1024px){
	.person{
		padding-top: 60px;
		padding-bottom: 60px;
	}
}
@media (max-width: 767px){
	.person .items .item{
		width: 100%;
		margin-bottom: 60px;
	}
}