.mainPageIntro{
	position: relative;
	float: left;
	height: auto;
	margin-top: -76px;
	width: 100%;
	.mainPageIntro_content{
		width: 55%;
		position: absolute;
		max-width: 1920px;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 0; right: 0;
		top: 0; bottom: 0;
		&::before{
			background-image: url(../img/circle/blue.svg);
		background-size: auto 120%;
		background-position: right bottom;
		content: '';
		width: 100%;
		right: 0;
		height: 100%;
		float: right;
		position: absolute;
		background-repeat: no-repeat;
		}
		h2{
			width: 30vw;
			font-size: 2.5vw;
			position: relative;
			z-index: 1;
			font-size: 2.5vw;
			margin-left: -100px;
			margin-top: -100px;
			color: $darkBlue;
			strong{
				width: 100%;
				display: block;
			}
		}
	}

	.mainPageIntro_image{
		position: relative;
		right: 0;
		top: 0;
		left: 0;
		margin-left: auto;
		img{
			width: 77vw;
			margin-left: auto;
			float: right;
		}
	}


	.mainPageIntro_tabs{
		position: absolute;
		float: left;
		width: 100%;
		z-index: 2;
		bottom: -10px;
		ul{
			background: #FAFAFA;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
			border-radius: 3px;
			overflow: hidden;

			@extend %noPadding;
			li{
				border-right: 1px solid #ccc;
				a{
					display: block;
					font-weight: $mediumFont;
					line-height: 26px;
					font-size: 24px;
					align-items: center;justify-content: center;
					color: #002E5D;
					display: flex;
					.tabs_icon{
						width: 120px;
						height: 120px;
						display: flex;
						align-items: center;
						justify-content: center;
						.tabs_icon__svg{
							max-width: 60px;
							max-height: 50px;
							width: 100%;
							path{
								fill: $darkBlue;
							}
						}

					}

					.tabs_content{
						float: left;
						flex-grow: 100;
						.tabs_title{
							font-weight: 500;
							line-height: 26px;
							font-size: 24px;
						}
						.tabs_info{
							text-transform: uppercase;
							font-weight: $mediumFont;
							font-size: 14px;
							letter-spacing: 1.4px;
						}
					}
					.tabs_arrow{
						width: 60px;
						float: left;
						display: flex;
						align-items: center;
						justify-content: center;
						.tabs_arrow__svg{
							width: 9px;
						}
					}
					&:hover{
						background-color: $red;
						transition-duration: .3s;
						.tabs_icon, .tabs_arrow{
							.tabs_icon__svg, .tabs_arrow__svg{
								path{
									fill: #fff;
									transition-duration: .3s;
								}
							}
						}
						.tabs_content{
							color: #fff;
									transition-duration: .3s;

						}
					}
				}
				&:last-child{
					border-right: none;
				}
			}
		}
	}
}

@media (max-width: 1024px){
	.header .headerSticky .header_title{
		min-width: 140px;
	}
	.mainPageIntro .mainPageIntro_tabs ul li a .tabs_icon{
		min-width: 74px;
	}
	.mainPageIntro .mainPageIntro_content:before{
		width: 65%;
		background-size: auto 101%;
		background-position: right bottom 0px;
	}
	.mainPageIntro .mainPageIntro_tabs ul li a .tabs_content .tabs_title{
		font-size: 20px;
		line-height: 22px;
		margin-bottom: 5px;
	}
	.mainPageIntro .mainPageIntro_tabs{
		margin-top: -30px;
		position: relative;
	}
	.mainPageIntro .mainPageIntro_image img{
		float: right;
	}
	.mainPageIntro{
		margin-top: 0;
	}
	.mainPageIntro .mainPageIntro_content h2{
		width: 34vw;
		font-size: 3.0vw;
		margin-top: -80px;

	}
	.mainPageIntro .mainPageIntro_image img{
		width: 70vw;
	}
}

@media (max-width: 767px){
	.mainPageIntro .mainPageIntro_image img{
		display: none;
	}
	.mainPageIntro .mainPageIntro_tabs{
		margin-top: 0px;
		bottom: 0;
		.grid-container{
			padding: 0; margin: 0;
		}
	}
	.mainPageIntro .mainPageIntro_content h2{
		margin-top: 0;
		float: left;
		font-size: 8vw;
		width: 100%;
		margin-left: 0px;
		padding: 0px;
		padding-top: 40px;
	}
	.mainPageIntro .mainPageIntro_content{
		width: 100%;
		position: relative;
		padding: 0 20px;
		padding-bottom: 40vw;
	}
	.mainPageIntro .mainPageIntro_content:before{
		width: 100%;
		background-size: 100%;
		bottom: 0;
		background-position: center bottom;

			background-image: url(../img/circle/circleMobile.svg);

	}
	.mainPageIntro .mainPageIntro_tabs ul li{
		border-bottom: 1px solid #C4C4C4;

	}
	.mainPageIntro .mainPageIntro_tabs ul li a .tabs_content{
		flex-grow: 10;
	}
	.mainPageIntro .mainPageIntro_tabs ul{
		box-shadow: none;
	}
}
