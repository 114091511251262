@keyframes mymove2 {
     0%   {top: 0px;}
     30%  {top: 3px;}
     60%  {top: -3px}
     100% {top: 0px;}
}

.download_section{
     float: left;
     width: 100%;
     background-color: #FAFAFA;
     padding: 120px 0;
     .download_section_header{
          float: left;
          width: 100%;
          text-align: center;
          margin-bottom: 60px;
          .download_section_subtitle{
               font-style: normal;
               font-weight: 500;
               line-height: normal;
               font-size: 14px;
               text-align: center;
               letter-spacing: 1.4px;
               text-transform: uppercase;
               margin-bottom: 30px;
          }
          .download_section_title{
               font-style: normal;
               font-weight: bold;
               line-height: normal;
               font-size: 46px;
               text-align: center;
          }
     }
     .download_section_items{
          float: left;
          width: 100%;
          
          .item{
               background-color: #fff;
               box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
               border-radius: 4px;
               padding: 30px;
               padding-bottom: 35px;
               display: flex;
               transition-duration: .3s;
               margin-bottom: 30px;
               flex-wrap: wrap;
               &:last-child{
                    margin-bottom: 0;
               }

               &:hover{
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
               }
                       
               .item_left{
                    width: calc(100% - 80px);
                    .item_left_fileName{
                         font-style: normal;
                         font-weight: normal;
                         line-height: 38px;
                         font-size: 28px;
                    }
                    .item_left_fileType{
                         font-style: normal;
                         font-weight: bold;
                         line-height: 12px;
                         font-size: 12px;
                         letter-spacing: 0.7px;
                         margin-top: 15px;
                         text-transform: uppercase;
                    }
               }
               .item_right{
                    margin-left: auto;

                    a{
                         font-style: normal;
                         font-weight: normal;
                         line-height: 25px;
                         font-size: 16px;
                         display: block;
                         position: relative;
                         margin-top: 5px;
                         color: $blue;
                         img,svg{
                              margin-left: 5px;
                              position: relative;
                  		}
                         &:hover{
                              img, svg{
                                   animation: mymove2 .8s forwards;
                              }
                         }
                    }
               }
          }
     }
}

@media (max-width: 767px){
      .download_section .download_section_header .download_section_title{
            font-size: 28px;
      }
      .download_section .download_section_header .download_section_subtitle{
            margin-bottom: 15px;
      }
      .download_section .download_section_items .item .item_left .item_left_fileName{
            font-size: 22px;
      }
      .download_section{
            padding: 70px 0;
      }
}