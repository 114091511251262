.boxOffer_section{
	float: left;
	width: 100%;
	margin-bottom: 60px;
	.box{
		background-repeat: no-repeat;
		padding: 60px 0px 45px 0px;
		float: left;
		background-size: 100%;
		height: 510px;
		background-position: center bottom;
		display: flex;
		 align-content: space-between;
		 flex-wrap: wrap;
		 transition-duration: .4s;

		 &.box_1{
			 width: 100%;
			 background-position: center ;
			 background-size: cover;
			 align-content: flex-end;
			 .box_header{
				 h3{margin: 0;}
			 }
			 .box_link{
				 a{
					 margin-top: 30px;
				 }
			 }
		 }
		 &.box_2{
			 width: 50%;
			 background-position: center ;
			 background-size: cover;
		 }
		.box_header{
			width: 100%;
			text-align: center;
			h3{
				font-weight: 300;
				line-height: normal;
				font-size: 39.35px;
				text-align: center;
				color: #fff;
				p{
					line-height: 1.2em;
					margin: 0;
					padding: 0;
				}
			}
		}

		.box_link{
			width: 100%;
			float: left;
			text-align: center;
			a{
				width: 220px;
				display: inline-block;
				background-color: $blue;
				box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
				border-radius: 3px;
				text-align: center;
				position: relative;
				padding: 15px 10px;
				color: #fff;
				margin-top: 35px;
								font-weight: 500;
				line-height: normal;
				font-size: 16px;
				span{
					position: relative;
					z-index: 1;
				}

				@include buttonAnimation(#fff);
				&:hover{
					span{
						color:$darkBlue;
					}
				}
			}
		}


		&:hover{
			box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.35);
		}
	}
}

@media (max-width: 1024px){

	.boxOffer_section .box .box_header h3 p{
		font-weight: 300;
		line-height: normal;
		font-size: 27px;
		text-align: center;
		letter-spacing: -0.2px;
	}

	.boxOffer_section .box{
		height: 390px;
	}

}

@media (max-width: 767px){

	.boxOffer_section .box{
		height: 390px;
		padding: 45px 0px 45px 0px;
	}
	.boxOffer_section .box.box_2{
		width: 100%;
	}
}
