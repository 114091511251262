.row_3_box_icon{
	float: left;
	width: 100%;
	margin-bottom: 30px;
	.row_3_box_icon__item{
		margin: 0px 30px;
		margin-bottom: 60px;
		width: calc(100% - 60px);
		.icon{
			float: left;
			width: 100%;
			margin-bottom: 30px;
			height: 90px;
			display: flex;
			align-items: flex-end;
			svg{
				max-width: 80px;
				max-height: 90px;
			}
		}
		h4{
			font-style: normal;
			font-weight: 300;
			line-height: normal;
			font-size: 20px;
			letter-spacing: -0.2px;
		}
	}
}

@media (max-width: 1024px){

	.row_3_box_icon{
		float: left;
		width: 100%;
		margin-bottom: 30px;
		.row_3_box_icon__item{
			padding: 0 52px;
			.icon{
				float: left;
				width: 100%;
				margin-bottom: 30px;
				height: 90px;
				display: flex;
				align-items: flex-end;
				svg{
					max-width: 80px;
					max-height: 90px;
				}
			}
			h4{
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				font-size: 20px;
				letter-spacing: -0.2px;
			}
		}
	}


}
@media (max-width: 767px){
	.row_3_box_icon{
		float: left;
		width: 100%;
		margin-bottom: 30px;
		.grid-margin-x > .small-12 {

		width: 100%;

		}
		.grid-margin-x > .cell {

			width: calc(100% - 1.25rem);
			margin-left: 0;
			margin-right: 0;
			width: 100%;

		}
		.row_3_box_icon__item{
			padding: 0 45px;
			.icon{
				float: left;
				width: 100%;
				margin-bottom: 30px;
				height: 90px;
				display: flex;
				align-items: flex-end;
				svg{
					max-width: 80px;
					max-height: 90px;
				}
			}
			h4{
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				font-size: 17px;
				letter-spacing: -0.2px;
			}
		}
	}
}
