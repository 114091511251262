.recent_post{
      float: left;
      width: 100%;
      padding-top: 120px;
      padding-bottom: 90px;
      background-color: #FAFAFA;
      .title{
            font-weight: bold;
            line-height: normal;
            font-size: 46px;
            text-align: center;
            letter-spacing: -0.2px;
            margin-bottom: 50px;
      }
      .blog_section__article{
            padding: 30px;
            background-color: #fff;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            float: left;
            width: 100%;
            height: 100%;

            .article__top{
                  float: left;
                  width: 100%;

                  .data{
                        float: left;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 12px;
                        font-size: 12px;
                        color: #002E5D;
                        letter-spacing: 0.7px;
                        text-transform: uppercase;


                  }
                  .category{
                        float: left;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 11px;
                        font-size: 12px;
                        text-transform: uppercase;
                        display: flex;align-items: center;

                                    a{
                                          float: left;
                                          margin-right: 10px;
                                          color: $darkBlue;
                                          display: inline-block;
                                    }


                        &::before{
                              content: '';
                              width: 2px; height: 2px;
                              background-color: #002E5D;
                              float: left;
                              margin: 0 12px;
                              border-radius: 10px;
                        }
                  }
            }
            .article_title{
                  padding: 30px 0;
                  float: left;
                  width: 100%;
                  h2{
                        line-height: 30px;
                        a{
                              font-style: normal;
                              font-weight: 500;
                              font-size: 28px;
                              letter-spacing: 0.2px;
                              color: $darkBlue;
                        }
                  }
            }

            .article_image{
                  height: 200px;
                  float: left;
                  margin-bottom: 30px;
                  width: 100%;
                  background-position: center;
                  background-size: cover;
            }

            .article_content{
                  margin-bottom: 35px;
                  float: left;
                  width: 100%;
                  p{
                        margin: 0; padding: 0;
                  }
            }

            .article_more{
                  font-weight: 500;
                  line-height: 25px;
                  font-size: 16px;
                  letter-spacing: 0.2px;
                  color: $blue;

                  img{
                        height: 7px;
                        margin-left: 5px;
                  }
            }
      }

}



@media (max-width: 1024px){


      .recent_post .title{
            font-style: normal;
            font-weight: bold;
            line-height: normal;
            font-size: 37px;
            text-align: center;
            letter-spacing: -0.2px;
      }
      .recent_post .cell{
            margin-bottom: 30px;
            &:last-child{
                  margin-bottom: 0;
            }
      }
}
@media (max-width: 767px){

      .recent_post{
            padding-top: 60px;
            padding-bottom: 60px;
      }
      .recent_post .title{
            font-weight: bold;
            line-height: normal;
            font-size: 28px;
            text-align: center;
            letter-spacing: -0.2px;


      }
      .recent_post .cell{
            margin-bottom: 30px;
            &:last-child{
                  margin-bottom: 0;
            }
      }
      .blog_post{
            padding-top: 30px;
            padding-bottom: 45px;
      }

      .recent_post .blog_section__article .article_title h2 a{
            font-weight: 500;
            line-height: 27px;
            font-size: 19px;
            letter-spacing: 0.2px;


      }
      .recent_post .blog_section__article .article_content p{
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
            font-size: 16px;


      }
}
