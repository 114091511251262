%noPadding {
	list-style: none;
	padding: 0; margin: 0;
}
	// @extend %noPadding;

@mixin buttonAnimation($color) {
	overflow: hidden;
	&::after{
		transition-duration: .3s;
		position: absolute;
		display: block;
		height: 1000%;
		left: -100px; right: -100px;
		top: 140%; bottom: 0;
		border-radius: 1000px;
		background-color: $color;
		content: '';
	}

	&::before{
		transition-duration: .3s;
		position: absolute;
		display: block;
		height: 1000%;
		left: -100px; right: -100px;
		top: 100%; bottom: 0;
		border-radius: 1000px;
		background-color: $color;
		opacity: .4;
		content: '';
	}
	&:hover{
		&::after{
			top: -100%;
		}
		&::before{
			top: -120%;
		}
	}
}
// @include buttonAnimation(red); 