.blog_section{

      background-color: #FAFAFA;
      float: left;
      width: 100%;

      .blog_section__header{
            padding: 45px 0;
            float: left;
            width: 100%;
            display: flex;align-items: center;
            .title{
                  float: left;
                  text-transform: uppercase;
                  color: $darkBlue;
                  font-size: 20px;
                  letter-spacing: 0.4em;
                  font-weight: 600;
            }
            .categoryShow{
                  margin-left: auto;
                  ul{
                        float: left;
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        li{
                              float: left;
                              font-size: 11px;
                              font-weight: 500;
                              position: relative;
                              width: auto;
                              overflow: hidden;
                              margin-left: 15px;
                              border: 1px solid rgba(21, 21, 21, 0.15);
                              border-radius: 40px;
                              .bg{
                                    position: absolute;
                                    top: 100%; bottom: 0; right: 0; left: 0;
                                    transition-duration: .2s;

                              }
                              a{
                                    display: block;
                                    padding: 7px 15px;
                                    float: left;
                                    position: relative;
                                    z-index: 1;
                                    text-transform: uppercase;
                              }
                              &:hover{
                                    border: 1px solid transparent;
                                    transition-delay: .2s;
                                    .bg{
                                          top: 0;
                                          background-color: #000;
                                    }
                                    a{
                                          color: #fff!important;
                                    }
                              }
                        }
                  }
            }
      }


      .blog_section__content{
            max-width: 700px;
            margin: 0 auto;
            .blog_section__article{
                  padding: 30px;
                  background-color: #fff;
                  margin-bottom: 30px;
                  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
                  border-radius: 4px;
                  float: left;
                  width: 100%;

                  .article__top{
                        float: left;
                        width: 100%;

                        .data{
                              float: left;
                              font-style: normal;
                              font-weight: 500;
                              line-height: 12px;
                              font-size: 12px;
                              color: #002E5D;
                              letter-spacing: 0.7px;
                              text-transform: uppercase;


                        }
                        .category{
                              float: left;
                              font-style: normal;
                              font-weight: 500;
                              line-height: 11px;
                              font-size: 12px;
                              text-transform: uppercase;
                              display: flex;align-items: center;

                                          a{
                                                float: left;
                                                margin-right: 10px;
                                                color: $darkBlue;
                                                display: inline-block;
                                          }


                              &::before{
                                    content: '';
                                    width: 2px; height: 2px;
                                    background-color: #002E5D;
                                    float: left;
                                    margin: 0 12px;
                                    border-radius: 10px;
                              }
                        }
                  }
                  .article_title{
                        padding: 30px 0;
                        float: left;
                        width: 100%;
                        h2{
                              line-height: 1em;


                              a{
                              font-style: normal;
                              font-weight: 500;
                              line-height: 1em;
                              font-size: 28px;
                              letter-spacing: 0.2px;
                              color: $darkBlue;
                              }
                        }
                  }

                  .article_image{
                        height: 200px;
                        float: left;
                        margin-bottom: 30px;
                        width: 100%;
                        background-position: center;
                        background-size: cover;
                  }

                  .article_content{
                        margin-bottom: 35px;
                        float: left;
                        width: 100%;
                        p{
                              margin: 0; padding: 0;
                              line-height: 1.5em;
                        }
                  }

                  .article_more{
                        font-weight: 500;
                        line-height: 25px;
                        font-size: 16px;
                        letter-spacing: 0.2px;
                        color: $blue;

                        img{
                              height: 7px;
                              margin-left: 5px;
                        }
                  }
            }



            .blog_section__pagination, .blog_section__paginationMobile{
                  float: left;
      		width: 100%;
      		text-align: center;
      		display: flex;
                  margin-top: 30px;
                  margin-bottom: 60px;
      		justify-content: center;
      		a, span{
      			border: 1px solid #D0D0D0;
      			box-sizing: border-box;
      			border-radius: 100px;
      			width: 50px;
      			height: 50px;
      			display: flex;
      			align-items: center;
      			color: $blue;
      			font-size: 22px;
      			font-weight: 500;
      			justify-content: center;
      			line-height: 1em;
      			margin: 0 5px;
      			transition-duration: .3s;

      			li{
      				list-style: none;
      				display: inline-block;
      			}

      			&.prev{
      				margin-right: 60px;
      				img{
      					height: 15px;
      				}
      			}
      			&.next{
      				margin-left: 60px;
      				img{
      					height: 15px;
      				}
      			}

      			&:hover{
      				background-color: #fff;
      			}
      		}
      		span{
      			border: 1px solid $blue;

      		}
            }

            .blog_section__paginationMobile{
                  position: relative;
                  display: none;
                  span{
                        border: none;
                  }
                  .left{
                        position: absolute;
                        left: 0;
                  }
                  .right{
                        position: absolute;
                        right: 0;

                  }
                  a{
                        img{
                              height: 15px;
                        }
                  }
            }
      }
}



@media (max-width: 1024px){
      .blog_section .blog_section__header .title{
            font-style: normal;
            font-weight: bold;
            line-height: normal;
            font-size: 13.1618px;
            letter-spacing: 0.4em;
            text-transform: uppercase;
      }
      .blog_section .grid-container{
            max-width: 640px;
      }
}

@media (max-width: 767px){
      .blog_section .blog_section__header .categoryShow{
            display: none;
      }
      .blog_section .blog_section__header .title{
            font-size: 10px;
      }
      .blog_section .blog_section__content .blog_section__article .article_title h2 a{
            font-style: normal;
            font-weight: 500;
            line-height: 27px;
            font-size: 19px;
            letter-spacing: 0.2px;
      }
      .blog_section .blog_section__content .blog_section__article .article_title h2{
            margin: 0;
      }
      .blog_section .blog_section__content .blog_section__pagination{
            display: none;
      }
      .blog_section .blog_section__content .blog_section__paginationMobile{
            display: flex;
      }
}
