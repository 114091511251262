@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 48em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.swiper-invisible-blank-slide {
  visibility: hidden; }

/* Auto Height */
.swiper-container-autoheight {
  height: auto; }
  .swiper-container-autoheight .swiper-slide {
    height: auto; }
  .swiper-container-autoheight .swiper-wrapper {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transition-property: height, -webkit-transform;
    transition-property: height, -webkit-transform;
    -o-transition-property: transform, height;
    transition-property: transform, height;
    transition-property: transform, height, -webkit-transform; }

/* 3D Effects */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px; }
  .swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10; }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }
  .swiper-container-wp8-horizontal > .swiper-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y; }

.swiper-container-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }
  .swiper-container-wp8-vertical > .swiper-wrapper {
    -ms-touch-action: pan-x;
    touch-action: pan-x; }

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-lock {
  display: none; }

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms opacity;
  -o-transition: 300ms opacity;
  transition: 300ms opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    -webkit-transform: scale(0.33);
    -ms-transform: scale(0.33);
    transform: scale(0.33);
    position: relative; }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    -webkit-transform: scale(0.66);
    -ms-transform: scale(0.66);
    transform: scale(0.66); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    -webkit-transform: scale(0.33);
    -ms-transform: scale(0.33);
    transform: scale(0.33); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    -webkit-transform: scale(0.66);
    -ms-transform: scale(0.66);
    transform: scale(0.66); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    -webkit-transform: scale(0.33);
    -ms-transform: scale(0.33);
    transform: scale(0.33); }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block; }
  .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 8px; }
    .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
      display: inline-block;
      -webkit-transition: 200ms top, 200ms -webkit-transform;
      transition: 200ms top, 200ms -webkit-transform;
      -o-transition: 200ms transform, 200ms top;
      transition: 200ms transform, 200ms top;
      transition: 200ms transform, 200ms top, 200ms -webkit-transform; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap; }
  .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    -webkit-transition: 200ms left, 200ms -webkit-transform;
    transition: 200ms left, 200ms -webkit-transform;
    -o-transition: 200ms transform, 200ms left;
    transition: 200ms transform, 200ms left;
    transition: 200ms transform, 200ms left, 200ms -webkit-transform; }

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms right, 200ms -webkit-transform;
  transition: 200ms right, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right, 200ms -webkit-transform; }

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progressbar {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }
  .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0; }

.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #ffffff; }

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25); }
  .swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
    background: #ffffff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000000; }

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25); }
  .swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
    background: #000000; }

.swiper-pagination-lock {
  display: none; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

.swiper-scrollbar-lock {
  display: none; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center; }
  .swiper-zoom-container > img, .swiper-zoom-container > svg, .swiper-zoom-container > canvas {
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain; }

.swiper-slide-zoomed {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }
  .swiper-lazy-preloader:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }
  .swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none; }

.swiper-container-fade .swiper-slide-active {
  pointer-events: auto; }
  .swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto; }

.swiper-container-cube {
  overflow: visible; }
  .swiper-container-cube .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
    visibility: hidden;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 100%;
    height: 100%; }
    .swiper-container-cube .swiper-slide .swiper-slide {
      pointer-events: none; }
  .swiper-container-cube.swiper-container-rtl .swiper-slide {
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0; }
  .swiper-container-cube .swiper-slide-active {
    pointer-events: auto;
    pointer-events: auto;
    visibility: visible; }
    .swiper-container-cube .swiper-slide-active .swiper-slide-active {
      pointer-events: auto; }
  .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible; }
  .swiper-container-cube .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
  .swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: 0; }

.swiper-container-flip {
  overflow: visible; }
  .swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1; }
    .swiper-container-flip .swiper-slide .swiper-slide {
      pointer-events: none; }
  .swiper-container-flip .swiper-slide-active {
    pointer-events: auto; }
    .swiper-container-flip .swiper-slide-active .swiper-slide-active {
      pointer-events: auto; }
  .swiper-container-flip .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }

.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=48em&large=64em&xlarge=76.875em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Work Sans", "Fira Sans", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 76.875rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 48em) {
    .grid-container {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .grid-container.fluid {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 48em) {
      .grid-container.fluid {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 48em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 48em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.875rem);
      margin-left: 0.9375rem;
      margin-right: 0.9375rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.25rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.25rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.25rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.25rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.25rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.25rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.25rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.25rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.875rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.875rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-x > .cell {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 48em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.25rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.25rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.25rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem); }

@media print, screen and (min-width: 48em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 48em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.625rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.625rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.625rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.625rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.625rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.625rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.625rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.625rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.625rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.625rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.625rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.625rem); }

@media print, screen and (min-width: 48em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 48em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 48em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }

.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-y > .cell {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 48em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 48em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 48em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem); }
  @media print, screen and (min-width: 48em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 48em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Work Sans", "Fira Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 48em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1468a0; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 76.875rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 48em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #14679e;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #fefefe; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1779ba;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.hollow {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #fefefe; }
  .badge.primary {
    background: #1779ba;
    color: #fefefe; }
  .badge.secondary {
    background: #767676;
    color: #fefefe; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #1779ba; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 48em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 47.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 48em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu .active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #1779ba; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 48em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }
  .label.primary {
    background: #1779ba;
    color: #fefefe; }
  .label.secondary {
    background: #767676;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 47.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 47.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 48em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 48em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 48em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #1779ba;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #14679e; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 48em) {
    .reveal {
      min-height: 0; } }
  .reveal .column {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 48em) {
    .reveal {
      width: 600px;
      max-width: 76.875rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 48em) {
    .reveal.tiny {
      width: 30%;
      max-width: 76.875rem; } }
  @media print, screen and (min-width: 48em) {
    .reveal.small {
      width: 50%;
      max-width: 76.875rem; } }
  @media print, screen and (min-width: 48em) {
    .reveal.large {
      width: 90%;
      max-width: 76.875rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 47.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  thead {
    background: #f8f8f8;
    color: #0a0a0a; }
  tfoot {
    background: #f1f1f1;
    color: #0a0a0a; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #fefefe; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #1779ba; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #1468a0; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #1779ba; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 48em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 76.8125em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 47.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 48em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 47.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 76.8125em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 76.875em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 48em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 48em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

html, body {
  max-width: 100%;
  min-width: 320px;
  color: #002E5D; }

h2, p {
  line-height: 1.2em; }

.header .headerSticky .header_menu .header_menu__list, .header .headerSticky .header_changeLanguage .header_changeLanguage__list, .offerMenu_show .offerMenu_show_tabs ul, .mobileNav .mobileMenu ul, .mobileNav .mobileMenu_lang ul, .mobileNav .mobileMenu .mobileMenu_lang__bottom ul, .mobileNav .mobileMenu_lang .mobileMenu_lang__bottom ul, .siteFooter .siteFooter_grid .siteFooter_right ul, .mainPageIntro .mainPageIntro_tabs ul, .footerWorks .footerWorks_tabs ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.header {
  float: left;
  width: 100%; }
  .header .headerSticky {
    padding: 0 25px;
    display: flex;
    z-index: 100;
    align-items: center; }
    .header .headerSticky:hover {
      background-color: #fff;
      opacity: 1;
      pointer-events: inherit; }
      .header .headerSticky:hover .header_menu.mainPageMenu {
        opacity: 1;
        pointer-events: inherit; }
    .header .headerSticky.is-stuck {
      background-color: #fff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25); }
      .header .headerSticky.is-stuck .header_menu.mainPageMenu {
        opacity: 1;
        pointer-events: inherit; }
    .header .headerSticky.headerSticky_openOffer {
      background-color: transparent;
      transition-duration: 0s;
      box-shadow: none; }
      .header .headerSticky.headerSticky_openOffer .header_title .header_link .header_logo path {
        fill: #fff; }
      .header .headerSticky.headerSticky_openOffer .header_menu .header_menu__list li a {
        color: #fff; }
      .header .headerSticky.headerSticky_openOffer .header_menu .header_menu__list li {
        opacity: .3; }
        .header .headerSticky.headerSticky_openOffer .header_menu .header_menu__list li.offerShow {
          opacity: 1; }
      .header .headerSticky.headerSticky_openOffer .header_menu.mainPageMenu {
        opacity: 1;
        pointer-events: inherit; }
    .header .headerSticky.headerSticky_openOffer:hover {
      background-color: transparent;
      transition-duration: 0s; }
      .header .headerSticky.headerSticky_openOffer:hover .header_title .header_link .header_logo path {
        fill: #fff; }
      .header .headerSticky.headerSticky_openOffer:hover .header_menu .header_menu__list li a {
        color: #fff; }
      .header .headerSticky.headerSticky_openOffer:hover .header_menu.mainPageMenu {
        opacity: 1;
        pointer-events: inherit; }
    .header .headerSticky .header_title {
      margin: 0;
      padding: 0;
      line-height: 0em;
      float: left;
      font-size: 1px; }
      .header .headerSticky .header_title .header_link {
        line-height: 1em; }
        .header .headerSticky .header_title .header_link .header_logo {
          width: 156px; }
    .header .headerSticky .header_menu {
      float: left;
      flex-grow: 10;
      transition-duration: .3s; }
      .header .headerSticky .header_menu.mainPageMenu {
        opacity: 0;
        pointer-events: none; }
      .header .headerSticky .header_menu .header_menu__list {
        float: left;
        text-align: center;
        width: 100%; }
        .header .headerSticky .header_menu .header_menu__list li {
          display: inline-block;
          margin: 0 40px; }
          .header .headerSticky .header_menu .header_menu__list li a {
            padding: 22px 10px;
            font-size: 16px;
            display: block;
            color: #002E5D;
            font-weight: 500; }
            .header .headerSticky .header_menu .header_menu__list li a:hover {
              opacity: .6; }
          .header .headerSticky .header_menu .header_menu__list li ul {
            display: none; }
    .header .headerSticky .loginHeader {
      position: relative;
      border-radius: 3px;
      cursor: pointer;
      display: inline-block;
      border: 1px solid #39A9DC;
      color: #39A9DC;
      padding: 5px 12px;
      float: left;
      margin-right: 10px;
      transition-duration: .3s;
      font-size: 12px;
      font-weight: 600; }
      .header .headerSticky .loginHeader:hover {
        opacity: .6; }
      .header .headerSticky .loginHeader img {
        height: 10px;
        margin-left: 5px;
        position: relative;
        top: -1px; }
    .header .headerSticky .header_changeLanguage {
      margin-left: auto;
      font-weight: 700;
      position: relative; }
      .header .headerSticky .header_changeLanguage .showMobile {
        display: none; }
      .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile {
        display: none; }
      .header .headerSticky .header_changeLanguage .header_changeLanguage__wrap, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile {
        position: relative;
        float: none;
        border-radius: 3px;
        background-color: #39A9DC;
        cursor: pointer;
        padding: 10px 12px;
        overflow: hidden; }
        .header .headerSticky .header_changeLanguage .header_changeLanguage__wrap::after, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile::after {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 140%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #002E5D;
          content: ''; }
        .header .headerSticky .header_changeLanguage .header_changeLanguage__wrap::before, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile::before {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 100%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #002E5D;
          opacity: .4;
          content: ''; }
        .header .headerSticky .header_changeLanguage .header_changeLanguage__wrap:hover::after, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile:hover::after {
          top: -100%; }
        .header .headerSticky .header_changeLanguage .header_changeLanguage__wrap:hover::before, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile:hover::before {
          top: -120%; }
        .header .headerSticky .header_changeLanguage .header_changeLanguage__wrap.active, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile.active {
          border-radius: 3px 3px 0 0; }
        .header .headerSticky .header_changeLanguage .header_changeLanguage__wrap .header_changeLanguage__title, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__title {
          position: relative;
          z-index: 1;
          font-size: 12px;
          color: #fff;
          float: left; }
        .header .headerSticky .header_changeLanguage .header_changeLanguage__wrap .header_changeLanguage__currentLanguage, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__currentLanguage {
          position: relative;
          z-index: 1;
          font-size: 13px;
          color: #fff;
          text-transform: uppercase;
          margin-left: 15px;
          float: left;
          line-height: 1em;
          top: 2px; }
          .header .headerSticky .header_changeLanguage .header_changeLanguage__wrap .header_changeLanguage__currentLanguage img, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__currentLanguage img {
            position: relative;
            top: -1px; }
      .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore {
        float: left;
        display: none;
        border-radius: 0 0 3px 3px;
        background-color: #39A9DC;
        cursor: pointer;
        width: 100%;
        position: absolute; }
        .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item {
          float: left;
          padding: 10px 12px;
          border-top: 1px solid rgba(255, 255, 255, 0.3);
          width: 100%;
          overflow: hidden;
          position: relative;
          overflow: hidden; }
          .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item::after {
            transition-duration: .3s;
            position: absolute;
            display: block;
            height: 1000%;
            left: -100px;
            right: -100px;
            top: 140%;
            bottom: 0;
            border-radius: 1000px;
            background-color: #EF4253;
            content: ''; }
          .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item::before {
            transition-duration: .3s;
            position: absolute;
            display: block;
            height: 1000%;
            left: -100px;
            right: -100px;
            top: 100%;
            bottom: 0;
            border-radius: 1000px;
            background-color: #EF4253;
            opacity: .4;
            content: ''; }
          .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item:hover::after {
            top: -100%; }
          .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item:hover::before {
            top: -120%; }
          .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item .lang {
            position: relative;
            z-index: 1;
            font-size: 12px;
            color: #fff;
            float: left; }
          .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item .langS {
            position: relative;
            z-index: 1;
            font-size: 13px;
            color: #fff;
            float: right;
            text-transform: uppercase; }
      .header .headerSticky .header_changeLanguage .header_changeLanguage__list {
        float: left;
        position: absolute;
        top: 0; }
        .header .headerSticky .header_changeLanguage .header_changeLanguage__list .header_changeLanguage__item {
          line-height: initial; }
          .header .headerSticky .header_changeLanguage .header_changeLanguage__list .header_changeLanguage__item a {
            font-size: 13px;
            color: #fff; }

.header_menuHamburger {
  margin-left: 20px;
  display: none; }
  .header_menuHamburger span {
    width: 20px;
    height: 3px;
    background-color: #39A9DC;
    display: block;
    margin-bottom: 3px; }
    .header_menuHamburger span:last-child {
      margin-bottom: 0; }

.offerMenu_show {
  float: left;
  width: 100%;
  display: block;
  position: fixed;
  top: 0;
  z-index: 99;
  overflow: hidden;
  display: none;
  transition-duration: .2s; }
  .offerMenu_show.active {
    display: block; }
  .offerMenu_show .offerMenu_show_tabs {
    float: left;
    padding: 100px;
    background-color: #002E5D;
    padding-top: 120px;
    width: 100%;
    z-index: 2; }
    .offerMenu_show .offerMenu_show_tabs ul {
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 3px;
      overflow: hidden; }
      .offerMenu_show .offerMenu_show_tabs ul li.box-1 {
        width: 100%; }
      .offerMenu_show .offerMenu_show_tabs ul li.box-2 {
        width: 50%; }
      .offerMenu_show .offerMenu_show_tabs ul li a {
        border-right: 1px solid rgba(255, 255, 255, 0.25);
        display: block;
        font-weight: 500;
        line-height: 26px;
        font-size: 24px;
        align-items: center;
        justify-content: center;
        color: #fff;
        display: flex; }
        .offerMenu_show .offerMenu_show_tabs ul li a .tabs_icon {
          width: 120px;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center; }
          .offerMenu_show .offerMenu_show_tabs ul li a .tabs_icon .tabs_icon__svg {
            max-width: 60px;
            max-height: 50px;
            width: 100%; }
            .offerMenu_show .offerMenu_show_tabs ul li a .tabs_icon .tabs_icon__svg path {
              fill: #fff; }
        .offerMenu_show .offerMenu_show_tabs ul li a .tabs_arrow .tabs_arrow__svg path {
          fill: #fff; }
        .offerMenu_show .offerMenu_show_tabs ul li a .tabs_content {
          float: left; }
          .offerMenu_show .offerMenu_show_tabs ul li a .tabs_content .tabs_title {
            font-weight: 500;
            line-height: 26px;
            font-size: 24px; }
          .offerMenu_show .offerMenu_show_tabs ul li a .tabs_content .tabs_info {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 1.4px; }
        .offerMenu_show .offerMenu_show_tabs ul li a .tabs_arrow {
          width: 60px;
          float: left;
          display: flex;
          align-items: center;
          justify-content: center; }
          .offerMenu_show .offerMenu_show_tabs ul li a .tabs_arrow .tabs_arrow__svg {
            width: 9px; }
        .offerMenu_show .offerMenu_show_tabs ul li a:hover {
          background-color: #EF4253;
          transition-duration: .3s; }
          .offerMenu_show .offerMenu_show_tabs ul li a:hover .tabs_icon .tabs_icon__svg path, .offerMenu_show .offerMenu_show_tabs ul li a:hover .tabs_icon .tabs_arrow__svg path, .offerMenu_show .offerMenu_show_tabs ul li a:hover .tabs_arrow .tabs_icon__svg path, .offerMenu_show .offerMenu_show_tabs ul li a:hover .tabs_arrow .tabs_arrow__svg path {
            fill: #fff;
            transition-duration: .3s; }
          .offerMenu_show .offerMenu_show_tabs ul li a:hover .tabs_content {
            color: #fff;
            transition-duration: .3s; }
      .offerMenu_show .offerMenu_show_tabs ul li:last-child a {
        border: none; }

.header_menu .offerShow ul {
  display: none; }

.mobileMenu .offerShow ul {
  display: block; }

.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item .langS {
  overflow: hidden;
  margin-right: 17px; }

.header .headerSticky .header_changeLanguage .header_changeLanguage__wrap .header_changeLanguage__currentLanguage img, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__currentLanguage img {
  margin-left: 1px; }

.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item,
.header .headerSticky .header_changeLanguage .header_changeLanguage__wrap, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile {
  padding: 5px 12px;
  padding-right: 7px; }

.header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMore .item .lang,
.header .headerSticky .header_changeLanguage .header_changeLanguage__wrap .header_changeLanguage__title, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__title {
  position: relative;
  top: 1px; }

.mobileNav .mobileMenu ul li a img, .mobileNav .mobileMenu_lang ul li a img {
  margin-right: 25px; }

@media (max-width: 1100px) {
  .header .headerSticky .header_menu .header_menu__list li {
    margin: 0 28px; } }

@media (max-width: 1024px) {
  .header_menu__list {
    display: none; }
  .header_menuHamburger {
    display: block; }
  .header .headerSticky {
    background-color: #fff;
    padding: 15px 20px; }
  .header_changeLanguage__title {
    display: none; }
  .header .headerSticky .header_menu.mainPageMenu {
    display: none; }
  .header .headerSticky .header_title {
    float: left; }
  .header .headerSticky .header_changeLanguage {
    float: right; }
  .header .headerSticky .header_changeLanguage .header_changeLanguage__wrap {
    display: none; }
  .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile {
    background-color: transparent;
    background-image: url(../img/icons/union.svg);
    background-size: 100%;
    width: 20px;
    display: block;
    text-align: center;
    padding: 0;
    margin-bottom: -3px;
    height: 24px;
    background-repeat: no-repeat; }
  .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__currentLanguage {
    margin: 0; }
  .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__currentLanguage {
    font-size: 9px;
    position: relative;
    top: 5.5px;
    text-align: center;
    width: 100%; }
  .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile .header_changeLanguage__currentLanguage img {
    display: none; }
  .header .headerSticky .header_changeLanguage .showMobile {
    display: block; }
  .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile:hover::before, .header .headerSticky .header_changeLanguage .header_changeLanguage__wrapMobile:hover::after {
    display: none; }
  .header .headerSticky .loginHeader {
    margin-right: 30px;
    margin-left: auto; }
  .header .headerSticky .header_changeLanguage {
    margin-left: 0px; } }

@media (max-width: 400px) {
  .header .headerSticky .loginHeader img {
    display: none; }
  .header .headerSticky .loginHeader {
    margin-right: 10px; }
  .header .headerSticky .header_title {
    min-width: 120px; }
  .header .headerSticky .header_title .header_link .header_logo {
    width: 120px; } }

.mobileNav .mobileMenu, .mobileNav .mobileMenu_lang {
  background-color: #002E5D;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  bottom: 0;
  top: 0;
  display: none;
  position: fixed;
  z-index: 1000; }
  .mobileNav .mobileMenu::before, .mobileNav .mobileMenu_lang::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    background-color: #002E5D; }
  .mobileNav .mobileMenu .mobileMenu_top, .mobileNav .mobileMenu_lang .mobileMenu_top {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    .mobileNav .mobileMenu .mobileMenu_top .mobileMenu_top__logo svg, .mobileNav .mobileMenu_lang .mobileMenu_top .mobileMenu_top__logo svg {
      width: 156px; }
      .mobileNav .mobileMenu .mobileMenu_top .mobileMenu_top__logo svg path, .mobileNav .mobileMenu_lang .mobileMenu_top .mobileMenu_top__logo svg path {
        fill: #fff; }
    .mobileNav .mobileMenu .mobileMenu_top .mobileMenu_topClose, .mobileNav .mobileMenu_lang .mobileMenu_top .mobileMenu_topClose {
      margin-left: auto; }
  .mobileNav .mobileMenu ul li, .mobileNav .mobileMenu_lang ul li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    .mobileNav .mobileMenu ul li a, .mobileNav .mobileMenu_lang ul li a {
      display: block;
      width: 100%;
      font-weight: 700;
      font-size: 15px;
      color: #fff;
      padding: 30px 20px; }
    .mobileNav .mobileMenu ul li ul li, .mobileNav .mobileMenu_lang ul li ul li {
      border: none; }
      .mobileNav .mobileMenu ul li ul li a, .mobileNav .mobileMenu_lang ul li ul li a {
        font-weight: 500;
        padding: 30px 20px 30px 50px; }
  .mobileNav .mobileMenu .mobileMenu_lang__bottom, .mobileNav .mobileMenu_lang .mobileMenu_lang__bottom {
    float: left;
    width: 100%;
    display: block; }
    .mobileNav .mobileMenu .mobileMenu_lang__bottom ul, .mobileNav .mobileMenu_lang .mobileMenu_lang__bottom ul {
      display: flex;
      float: left;
      width: 100%; }
      .mobileNav .mobileMenu .mobileMenu_lang__bottom ul li, .mobileNav .mobileMenu_lang .mobileMenu_lang__bottom ul li {
        display: inline-block;
        flex-grow: 1;
        text-align: center; }
        .mobileNav .mobileMenu .mobileMenu_lang__bottom ul li a, .mobileNav .mobileMenu_lang .mobileMenu_lang__bottom ul li a {
          color: #fff;
          font-weight: 400;
          border-right: 1px solid rgba(255, 255, 255, 0.3); }
        .mobileNav .mobileMenu .mobileMenu_lang__bottom ul li.activeLang a, .mobileNav .mobileMenu_lang .mobileMenu_lang__bottom ul li.activeLang a {
          font-weight: 700; }
        .mobileNav .mobileMenu .mobileMenu_lang__bottom ul li:last-child a, .mobileNav .mobileMenu_lang .mobileMenu_lang__bottom ul li:last-child a {
          border-right: none; }

.siteFooter {
  padding: 35px 0;
  float: left;
  width: 100%; }
  .siteFooter .siteFooter_grid {
    display: flex;
    align-items: center;
    float: left;
    width: 100%; }
    .siteFooter .siteFooter_grid .siteFooter_left {
      font-size: 16px; }
    .siteFooter .siteFooter_grid .siteFooter_right {
      margin-left: auto; }
      .siteFooter .siteFooter_grid .siteFooter_right ul li {
        float: left;
        margin-left: 30px; }
        .siteFooter .siteFooter_grid .siteFooter_right ul li a svg {
          height: 19px; }
          .siteFooter .siteFooter_grid .siteFooter_right ul li a svg path {
            fill: #002E5D;
            transition-duration: .2s; }
          .siteFooter .siteFooter_grid .siteFooter_right ul li a svg:hover path {
            fill: #39A9DC; }

@media (max-width: 768px) {
  .siteFooter .siteFooter_grid {
    display: block; }
  .siteFooter .siteFooter_grid .siteFooter_left,
  .siteFooter .siteFooter_grid .siteFooter_right {
    float: left;
    width: 100%;
    text-align: center; }
  .siteFooter .siteFooter_grid .siteFooter_right ul {
    margin-top: 30px; }
  .siteFooter .siteFooter_grid .siteFooter_right ul li {
    float: none;
    margin: 0 15px;
    display: inline-block; } }

.mainPageIntro {
  position: relative;
  float: left;
  height: auto;
  margin-top: -76px;
  width: 100%; }
  .mainPageIntro .mainPageIntro_content {
    width: 55%;
    position: absolute;
    max-width: 1920px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
    .mainPageIntro .mainPageIntro_content::before {
      background-image: url(../img/circle/blue.svg);
      background-size: auto 120%;
      background-position: right bottom;
      content: '';
      width: 100%;
      right: 0;
      height: 100%;
      float: right;
      position: absolute;
      background-repeat: no-repeat; }
    .mainPageIntro .mainPageIntro_content h2 {
      width: 30vw;
      font-size: 2.5vw;
      position: relative;
      z-index: 1;
      font-size: 2.5vw;
      margin-left: -100px;
      margin-top: -100px;
      color: #002E5D; }
      .mainPageIntro .mainPageIntro_content h2 strong {
        width: 100%;
        display: block; }
  .mainPageIntro .mainPageIntro_image {
    position: relative;
    right: 0;
    top: 0;
    left: 0;
    margin-left: auto; }
    .mainPageIntro .mainPageIntro_image img {
      width: 77vw;
      margin-left: auto;
      float: right; }
  .mainPageIntro .mainPageIntro_tabs {
    position: absolute;
    float: left;
    width: 100%;
    z-index: 2;
    bottom: -10px; }
    .mainPageIntro .mainPageIntro_tabs ul {
      background: #FAFAFA;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      overflow: hidden; }
      .mainPageIntro .mainPageIntro_tabs ul li {
        border-right: 1px solid #ccc; }
        .mainPageIntro .mainPageIntro_tabs ul li a {
          display: block;
          font-weight: 500;
          line-height: 26px;
          font-size: 24px;
          align-items: center;
          justify-content: center;
          color: #002E5D;
          display: flex; }
          .mainPageIntro .mainPageIntro_tabs ul li a .tabs_icon {
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .mainPageIntro .mainPageIntro_tabs ul li a .tabs_icon .tabs_icon__svg {
              max-width: 60px;
              max-height: 50px;
              width: 100%; }
              .mainPageIntro .mainPageIntro_tabs ul li a .tabs_icon .tabs_icon__svg path {
                fill: #002E5D; }
          .mainPageIntro .mainPageIntro_tabs ul li a .tabs_content {
            float: left;
            flex-grow: 100; }
            .mainPageIntro .mainPageIntro_tabs ul li a .tabs_content .tabs_title {
              font-weight: 500;
              line-height: 26px;
              font-size: 24px; }
            .mainPageIntro .mainPageIntro_tabs ul li a .tabs_content .tabs_info {
              text-transform: uppercase;
              font-weight: 500;
              font-size: 14px;
              letter-spacing: 1.4px; }
          .mainPageIntro .mainPageIntro_tabs ul li a .tabs_arrow {
            width: 60px;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center; }
            .mainPageIntro .mainPageIntro_tabs ul li a .tabs_arrow .tabs_arrow__svg {
              width: 9px; }
          .mainPageIntro .mainPageIntro_tabs ul li a:hover {
            background-color: #EF4253;
            transition-duration: .3s; }
            .mainPageIntro .mainPageIntro_tabs ul li a:hover .tabs_icon .tabs_icon__svg path, .mainPageIntro .mainPageIntro_tabs ul li a:hover .tabs_icon .tabs_arrow__svg path, .mainPageIntro .mainPageIntro_tabs ul li a:hover .tabs_arrow .tabs_icon__svg path, .mainPageIntro .mainPageIntro_tabs ul li a:hover .tabs_arrow .tabs_arrow__svg path {
              fill: #fff;
              transition-duration: .3s; }
            .mainPageIntro .mainPageIntro_tabs ul li a:hover .tabs_content {
              color: #fff;
              transition-duration: .3s; }
        .mainPageIntro .mainPageIntro_tabs ul li:last-child {
          border-right: none; }

@media (max-width: 1024px) {
  .header .headerSticky .header_title {
    min-width: 140px; }
  .mainPageIntro .mainPageIntro_tabs ul li a .tabs_icon {
    min-width: 74px; }
  .mainPageIntro .mainPageIntro_content:before {
    width: 65%;
    background-size: auto 101%;
    background-position: right bottom 0px; }
  .mainPageIntro .mainPageIntro_tabs ul li a .tabs_content .tabs_title {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 5px; }
  .mainPageIntro .mainPageIntro_tabs {
    margin-top: -30px;
    position: relative; }
  .mainPageIntro .mainPageIntro_image img {
    float: right; }
  .mainPageIntro {
    margin-top: 0; }
  .mainPageIntro .mainPageIntro_content h2 {
    width: 34vw;
    font-size: 3.0vw;
    margin-top: -80px; }
  .mainPageIntro .mainPageIntro_image img {
    width: 70vw; } }

@media (max-width: 767px) {
  .mainPageIntro .mainPageIntro_image img {
    display: none; }
  .mainPageIntro .mainPageIntro_tabs {
    margin-top: 0px;
    bottom: 0; }
    .mainPageIntro .mainPageIntro_tabs .grid-container {
      padding: 0;
      margin: 0; }
  .mainPageIntro .mainPageIntro_content h2 {
    margin-top: 0;
    float: left;
    font-size: 8vw;
    width: 100%;
    margin-left: 0px;
    padding: 0px;
    padding-top: 40px; }
  .mainPageIntro .mainPageIntro_content {
    width: 100%;
    position: relative;
    padding: 0 20px;
    padding-bottom: 40vw; }
  .mainPageIntro .mainPageIntro_content:before {
    width: 100%;
    background-size: 100%;
    bottom: 0;
    background-position: center bottom;
    background-image: url(../img/circle/circleMobile.svg); }
  .mainPageIntro .mainPageIntro_tabs ul li {
    border-bottom: 1px solid #C4C4C4; }
  .mainPageIntro .mainPageIntro_tabs ul li a .tabs_content {
    flex-grow: 10; }
  .mainPageIntro .mainPageIntro_tabs ul {
    box-shadow: none; } }

.whatIsThreeZones {
  float: left;
  width: 100%;
  padding: 90px 0; }
  .whatIsThreeZones .whatIsThreeZones_content {
    max-width: 860px;
    margin: 0 auto; }
    .whatIsThreeZones .whatIsThreeZones_content .whatIsThreeZones_title {
      font-weight: 700;
      line-height: 54px;
      font-size: 46px;
      text-align: center;
      letter-spacing: -0.2px;
      color: #002E5D;
      margin-bottom: 40px; }
    .whatIsThreeZones .whatIsThreeZones_content p {
      font-weight: 300;
      line-height: 38px;
      text-align: center;
      font-size: 28px;
      color: #002E5D; }

@media (max-width: 1440px) {
  .whatIsThreeZones {
    padding: 60px 0; } }

@media (max-width: 1024px) {
  .whatIsThreeZones {
    padding: 90px 0;
    padding-bottom: 30px; }
  .whatIsThreeZones .whatIsThreeZones_content .whatIsThreeZones_title {
    font-size: 37px;
    margin-bottom: 30px; }
  .whatIsThreeZones .whatIsThreeZones_content p {
    line-height: 31px;
    font-size: 23px; }
  .whatIsThreeZones .grid-container {
    max-width: 610px; } }

@media (max-width: 767px) {
  .whatIsThreeZones {
    padding-top: 60px; }
  .whatIsThreeZones .whatIsThreeZones_content .whatIsThreeZones_title {
    font-size: 26px; }
  .whatIsThreeZones .whatIsThreeZones_content p {
    line-height: 26px;
    font-size: 19px; } }

.workBox {
  float: left;
  width: 100%;
  padding-bottom: 75px; }
  .workBox .number-2 {
    width: 50%; }
  .workBox .workBox_box {
    padding: 60px 30px 90px 30px;
    float: left;
    width: 100%;
    transition-duration: .2s;
    height: 100%;
    position: relative; }
    .workBox .workBox_box header {
      position: relative;
      margin-bottom: 40px; }
      .workBox .workBox_box header h3 {
        font-size: 24px;
        color: #002E5D;
        margin-bottom: 15px;
        font-weight: 500; }
      .workBox .workBox_box header::before {
        width: 50%;
        content: '';
        height: 3px;
        background-color: #002E5D;
        position: absolute;
        bottom: -3px;
        right: 0; }
    .workBox .workBox_box .workBox_content {
      font-weight: normal;
      line-height: 25px;
      font-size: 16px;
      margin-bottom: 30px;
      opacity: 1;
      color: #002E5D;
      float: left;
      width: 100%; }
      .workBox .workBox_box .workBox_content p {
        margin-bottom: 0; }
      .workBox .workBox_box .workBox_content strong {
        font-size: 28px;
        line-height: 38px;
        display: block;
        opacity: 1;
        font-weight: 300;
        margin-bottom: 25px; }
    .workBox .workBox_box .workBox_more {
      background: #39A9DC;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      font-size: 16px;
      text-align: center;
      min-width: 220px;
      color: #fff;
      font-weight: 400;
      padding: 18px;
      display: block;
      position: absolute;
      bottom: 30px;
      overflow: hidden; }
      .workBox .workBox_box .workBox_more::after {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 140%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #EF4253;
        content: ''; }
      .workBox .workBox_box .workBox_more::before {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 100%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #EF4253;
        opacity: .4;
        content: ''; }
      .workBox .workBox_box .workBox_more:hover::after {
        top: -100%; }
      .workBox .workBox_box .workBox_more:hover::before {
        top: -120%; }
      .workBox .workBox_box .workBox_more span {
        position: relative;
        z-index: 1; }
    .workBox .workBox_box:hover {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 3px; }

@media (max-width: 1440px) {
  .workBox {
    padding-bottom: 60px; } }

@media (max-width: 1024px) {
  .workBox {
    padding-bottom: 45px; }
  .workBox .workBox_box {
    text-align: center; }
  .workBox .workBox_box header h3 {
    line-height: 26px;
    font-size: 21px;
    text-align: left; }
  .workBox .workBox_box .workBox_content {
    font-size: 16px;
    margin-bottom: 45px;
    text-align: left; }
  .workBox .workBox_box .workBox_content strong {
    line-height: 31px;
    font-size: 23px;
    text-align: left; }
  .workBox .workBox_box .workBox_more {
    display: inline-block;
    left: 30px; }
  .workBox .workBox_box header {
    margin-bottom: 45px; } }

@media (max-width: 767px) {
  .workBox {
    padding-bottom: 35px; }
  .workBox .workBox_box {
    text-align: center; }
  .workBox .workBox_box header h3 {
    line-height: 25px;
    font-size: 18px;
    text-align: left; }
  .workBox .workBox_box .workBox_content {
    font-size: 16px;
    text-align: left; }
  .workBox .workBox_box .workBox_content strong {
    line-height: 26px;
    font-size: 19px;
    text-align: left; }
  .workBox .workBox_box .workBox_more {
    display: inline-block;
    width: calc(100% - 60px);
    left: 30px; }
  .workBox .workBox_box header {
    margin-bottom: 40px; }
  .workBox .workBox_box {
    padding-top: 40px; }
  .workBox .workBox_box .workBox_content {
    margin-bottom: 30px; } }

.workBox_1col {
  float: left;
  width: 100%;
  padding-bottom: 75px; }
  .workBox_1col .grid-container {
    padding: 0 50px; }
  .workBox_1col .wImage {
    padding-right: 90px; }
  .workBox_1col .workBox_box {
    float: left;
    width: 100%;
    transition-duration: .2s;
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between; }
    .workBox_1col .workBox_box header {
      position: relative;
      margin-bottom: 40px; }
      .workBox_1col .workBox_box header h3 {
        font-size: 24px;
        color: #002E5D;
        margin-bottom: 15px;
        font-weight: 500; }
      .workBox_1col .workBox_box header::before {
        width: 50%;
        content: '';
        height: 3px;
        background-color: #002E5D;
        position: absolute;
        bottom: -3px;
        right: 0; }
    .workBox_1col .workBox_box .workBox_content {
      font-weight: normal;
      line-height: 25px;
      font-size: 16px;
      margin-bottom: 30px;
      opacity: 1;
      color: #002E5D;
      float: left;
      width: 100%; }
      .workBox_1col .workBox_box .workBox_content p {
        margin-bottom: 0; }
      .workBox_1col .workBox_box .workBox_content strong {
        font-size: 28px;
        line-height: 38px;
        display: block;
        opacity: 1;
        font-weight: 300;
        margin-bottom: 25px; }
    .workBox_1col .workBox_box .workBox_more {
      background: #39A9DC;
      border-radius: 3px;
      font-size: 16px;
      text-align: center;
      min-width: 220px;
      color: #fff;
      font-weight: 400;
      padding: 18px;
      display: block;
      position: relative;
      float: left;
      bottom: 0px;
      overflow: hidden; }
      .workBox_1col .workBox_box .workBox_more::after {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 140%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #EF4253;
        content: ''; }
      .workBox_1col .workBox_box .workBox_more::before {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 100%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #EF4253;
        opacity: .4;
        content: ''; }
      .workBox_1col .workBox_box .workBox_more:hover::after {
        top: -100%; }
      .workBox_1col .workBox_box .workBox_more:hover::before {
        top: -120%; }
      .workBox_1col .workBox_box .workBox_more span {
        position: relative;
        z-index: 1; }

@media (max-width: 1440px) {
  .workBox {
    padding-bottom: 60px; } }

@media (max-width: 1024px) {
  .workBox {
    padding-bottom: 45px; }
  .workBox .workBox_box {
    text-align: center; }
  .workBox .workBox_box header h3 {
    line-height: 26px;
    font-size: 21px;
    text-align: left; }
  .workBox .workBox_box .workBox_content {
    font-size: 16px;
    margin-bottom: 45px;
    text-align: left; }
  .workBox .workBox_box .workBox_content strong {
    line-height: 31px;
    font-size: 23px;
    text-align: left; }
  .workBox .workBox_box .workBox_more {
    display: inline-block;
    left: 30px; }
  .workBox .workBox_box header {
    margin-bottom: 45px; }
  .workBox_1col .wImage {
    padding-right: 50px; } }

@media (max-width: 767px) {
  .workBox_1col .wImage {
    padding-right: 0;
    margin-bottom: 30px; }
  .workBox {
    padding-bottom: 35px; }
  .workBox .workBox_box {
    text-align: center; }
  .workBox .workBox_box header h3 {
    line-height: 25px;
    font-size: 18px;
    text-align: left; }
  .workBox .workBox_box .workBox_content {
    font-size: 16px;
    text-align: left; }
  .workBox .workBox_box .workBox_content strong {
    line-height: 26px;
    font-size: 19px;
    text-align: left; }
  .workBox .workBox_box .workBox_more {
    display: inline-block;
    width: calc(100% - 60px);
    left: 30px; }
  .workBox .workBox_box header {
    margin-bottom: 40px; }
  .workBox .workBox_box {
    padding-top: 40px; }
  .workBox .workBox_box .workBox_content {
    margin-bottom: 30px; } }

.textModule {
  padding: 55px 0;
  padding-bottom: 120px;
  float: left;
  width: 100%; }
  .textModule p {
    line-height: 38px;
    font-size: 28px;
    text-align: center;
    max-width: 686px;
    margin: 0 auto;
    font-weight: 300;
    color: #002E5D; }
    .textModule p strong {
      font-weight: 700; }

@media (max-width: 1440px) {
  .textModule {
    padding: 60px 0; } }

@media (max-width: 1024px) {
  .textModule {
    padding: 45px 0;
    float: left;
    width: 100%; }
    .textModule p {
      line-height: 31px;
      font-size: 23px; }
      .textModule p strong {
        font-weight: 700; } }

@media (max-width: 767px) {
  .textModule {
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 35px;
    width: 100%; }
    .textModule p {
      line-height: 26px;
      font-size: 19px; }
      .textModule p strong {
        font-weight: 700; } }

.franchiseModule {
  float: left;
  width: 100%;
  padding: 75px 0; }
  .franchiseModule .franchiseModule_content {
    float: left;
    width: 100%;
    background-color: #f5d362;
    background-size: 90%;
    background-position: left -100px top -50px;
    background-repeat: no-repeat;
    color: #002E5D;
    padding: 161px 60px;
    position: relative;
    overflow: hidden; }
    .franchiseModule .franchiseModule_content .cell {
      margin-left: auto;
      max-width: 320px;
      position: relative; }
      .franchiseModule .franchiseModule_content .cell::before {
        position: absolute;
        bottom: -600px;
        top: -600px;
        background-color: red;
        background: linear-gradient(270deg, #f5d362 56.91%, rgba(252, 203, 0, 0) 100%);
        content: '';
        z-index: 0;
        left: -100px;
        right: -60px; }
      .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box {
        position: relative;
        z-index: 12; }
        .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box h2 {
          font-size: 39.35px;
          line-height: normal; }
          .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box h2 strong {
            font-weight: bold; }
        .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box p {
          line-height: 25px;
          font-size: 16px;
          margin-bottom: 30px; }
        .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box .franchiseModule_more {
          width: 220px;
          display: block;
          background-color: #39A9DC;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          text-align: center;
          position: relative;
          padding: 15px 10px;
          color: #fff;
          overflow: hidden; }
          .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box .franchiseModule_more::after {
            transition-duration: .3s;
            position: absolute;
            display: block;
            height: 1000%;
            left: -100px;
            right: -100px;
            top: 140%;
            bottom: 0;
            border-radius: 1000px;
            background-color: #002E5D;
            content: ''; }
          .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box .franchiseModule_more::before {
            transition-duration: .3s;
            position: absolute;
            display: block;
            height: 1000%;
            left: -100px;
            right: -100px;
            top: 100%;
            bottom: 0;
            border-radius: 1000px;
            background-color: #002E5D;
            opacity: .4;
            content: ''; }
          .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box .franchiseModule_more:hover::after {
            top: -100%; }
          .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box .franchiseModule_more:hover::before {
            top: -120%; }
          .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box .franchiseModule_more span {
            z-index: 1;
            position: relative; }

@media (max-width: 1440px) {
  .franchiseModule {
    padding: 60px 0; }
  .franchiseModule .franchiseModule_content {
    padding: 76px 60px; } }

@media (max-width: 1024px) {
  .franchiseModule {
    padding: 45px 10px; }
  .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box,
  .franchiseModule .franchiseModule_content .cell {
    position: initial; }
  .franchiseModule .franchiseModule_content .cell {
    max-width: 100%; }
  .franchiseModule .franchiseModule_content .cell::before {
    display: none; }
  .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box .franchiseModule_more {
    position: absolute;
    margin-left: 50%;
    left: -110px;
    bottom: 50px; }
  .franchiseModule .franchiseModule_content .cell {
    width: 50%;
    margin-left: 0; }
  .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box h2 {
    font-size: 28px; }
  .franchiseModule .franchiseModule_content {
    background-size: 115%;
    background-position: left 0vw bottom -30vw;
    height: 540px; } }

@media (max-width: 767px) {
  .franchiseModule .franchiseModule_content .cell {
    width: 100%;
    margin-left: 0; }
  .franchiseModule {
    padding: 30px 0; }
  .franchiseModule .franchiseModule_content {
    height: auto; }
  .franchiseModule .franchiseModule_content {
    padding: 60px 20px;
    background-position: left bottom 0vw;
    padding-bottom: 240px; }
  .franchiseModule .franchiseModule_content .cell .franchiseModule_content__box h2 {
    font-size: 26px; }
  .franchiseModule .franchiseModule_content {
    background-position: left bottom -38vw; } }

@media (max-width: 650px) {
  .franchiseModule .franchiseModule_content {
    background-position: left bottom -29vw; } }

@media (max-width: 550px) {
  .franchiseModule .franchiseModule_content {
    background-position: left bottom -22vw; } }

@media (max-width: 500px) {
  .franchiseModule .franchiseModule_content {
    background-position: left bottom -15vw; } }

@media (max-width: 450px) {
  .franchiseModule .franchiseModule_content {
    background-position: left bottom -10vw; } }

@media (max-width: 400px) {
  .franchiseModule .franchiseModule_content {
    background-position: left bottom 0vw; } }

.troopsModule {
  float: left;
  width: 100%;
  background-color: #FAFAFA;
  padding-top: 120px;
  margin-top: 0px; }
  .troopsModule .troopsModule_header h2 {
    font-weight: bold;
    font-size: 46px;
    text-align: center; }
  .troopsModule .troopsModule_wrap {
    position: relative; }
    .troopsModule .troopsModule_wrap .swiper-container {
      margin: 40px 115px 50px 115px;
      padding: 10px;
      overflow: hidden; }
      .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item {
        padding: 30px;
        background-color: transparent;
        box-sizing: border-box;
        transition-duration: .3s; }
        .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__header {
          font-weight: 500;
          line-height: 26px;
          font-size: 24px;
          margin-bottom: 20px; }
          .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__header span.city {
            display: block; }
        .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content p {
          font-weight: normal;
          line-height: 25px;
          font-size: 16px;
          margin: 0;
          padding: 0;
          float: left;
          width: 100%; }
          .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content p::after {
            content: '';
            width: 100px;
            height: 1px;
            color: #39A9DC; }
        .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content a {
          font-weight: normal;
          line-height: normal;
          font-size: 16px;
          float: left;
          position: relative;
          color: #39A9DC;
          margin-top: 10px;
          display: block;
          width: 100%; }
          .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content a::after {
            content: '';
            width: 60%;
            margin-top: 5px;
            height: 1px;
            display: block !important;
            transition-duration: .3s;
            background-color: #39A9DC;
            margin: 0 auto;
            margin-top: 5px; }
          .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content a:hover {
            color: #EF4253; }
            .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content a:hover::after {
              width: 100%;
              background-color: #EF4253; }
        .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item.activeInMap {
          background-color: #FFFFFF;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          border-radius: 4px; }
          .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item.activeInMap:hover {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25); }
        .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item:hover {
          background-color: #FFFFFF;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
          border-radius: 4px; }
    .troopsModule .troopsModule_wrap .troopsModule_arrows {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center; }
      .troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__left, .troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__right {
        line-height: 1em; }
        .troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__left svg, .troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__right svg {
          line-height: 1em;
          transition-duration: .2s; }
          .troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__left svg path, .troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__right svg path {
            transition-duration: .2s; }
          .troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__left svg:hover path, .troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__right svg:hover path {
            fill: #002E5D; }
      .troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__right {
        margin-left: auto; }
  .troopsModule .swiper-pagination {
    margin-top: 50px;
    position: relative; }
    .troopsModule .swiper-pagination .swiper-pagination-bullet {
      width: 12px;
      height: 12px; }

.swiper-pagination-bullet-active {
  background: #39A9DC; }

#mapSite {
  height: 450px;
  width: 100%; }

@media (max-width: 1440px) {
  .troopsModule {
    padding-top: 90px; } }

@media (max-width: 1024px) {
  .troopsModule .troopsModule_wrap .troopsModule_arrows {
    display: none; }
  .troopsModule {
    overflow: hidden; }
  .troopsModule .troopsModule_wrap .swiper-container {
    margin: 40px 0;
    width: calc(100% + 300px);
    margin-left: -150px;
    margin-right: -150px; }
  .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item {
    background-color: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px; }
  .troopsModule .troopsModule_header h2 {
    font-size: 37px; } }

@media (max-width: 767px) {
  .troopsModule .troopsModule_wrap .troopsModule_arrows {
    display: none; }
  .troopsModule {
    overflow: hidden;
    padding-top: 60px; }
  .troopsModule .troopsModule_wrap .swiper-container {
    margin: 40px 0;
    width: calc(100% + 0px);
    margin-right: 0px; }
  .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item {
    background-color: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px; }
  .troopsModule .troopsModule_header h2 {
    font-size: 28px; }
  .troopsModule .swiper-pagination {
    margin-top: 30px; }
  .troopsModule .troopsModule_wrap .swiper-container {
    margin-bottom: 10px; }
  .troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item {
    left: -20px; }
  .swiper-wrapper, .troopsModule .troopsModule_wrap .swiper-container {
    overflow: visible; } }

.footerWorks {
  float: left;
  width: 100%;
  background-color: #002E5D;
  padding: 255px 0;
  background-size: cover;
  background-position: center top -100px;
  background-size: auto 120%; }
  .footerWorks .title {
    font-weight: bold;
    line-height: normal;
    font-size: 46px;
    text-align: center;
    margin-bottom: 45px;
    color: #fff; }
  .footerWorks .footerWorks_tabs {
    position: relative;
    float: left;
    width: 100%;
    margin-top: 0px;
    z-index: 2; }
    .footerWorks .footerWorks_tabs ul {
      background: #FAFAFA;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      overflow: hidden; }
      .footerWorks .footerWorks_tabs ul li {
        border-right: 1px solid #ccc; }
        .footerWorks .footerWorks_tabs ul li.style-for-2 {
          width: 50%; }
        .footerWorks .footerWorks_tabs ul li.style-for-1 {
          width: 100%; }
        .footerWorks .footerWorks_tabs ul li a {
          display: block;
          font-weight: 500;
          line-height: 26px;
          font-size: 24px;
          align-items: center;
          justify-content: center;
          color: #002E5D;
          display: flex; }
          .footerWorks .footerWorks_tabs ul li a .tabs_icon {
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .footerWorks .footerWorks_tabs ul li a .tabs_icon .tabs_icon__svg {
              max-width: 60px;
              width: 100%;
              max-height: 50px; }
              .footerWorks .footerWorks_tabs ul li a .tabs_icon .tabs_icon__svg path {
                fill: #002E5D; }
          .footerWorks .footerWorks_tabs ul li a .tabs_content {
            float: left;
            flex-grow: 100; }
            .footerWorks .footerWorks_tabs ul li a .tabs_content .tabs_title {
              font-weight: 500;
              line-height: 26px;
              font-size: 24px; }
            .footerWorks .footerWorks_tabs ul li a .tabs_content .tabs_info {
              text-transform: uppercase;
              font-weight: 500;
              font-size: 14px;
              letter-spacing: 1.4px; }
          .footerWorks .footerWorks_tabs ul li a .tabs_arrow {
            width: 60px;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center; }
            .footerWorks .footerWorks_tabs ul li a .tabs_arrow .tabs_arrow__svg {
              width: 9px; }
          .footerWorks .footerWorks_tabs ul li a:hover {
            background-color: #EF4253;
            transition-duration: .3s; }
            .footerWorks .footerWorks_tabs ul li a:hover .tabs_icon .tabs_icon__svg path, .footerWorks .footerWorks_tabs ul li a:hover .tabs_icon .tabs_arrow__svg path, .footerWorks .footerWorks_tabs ul li a:hover .tabs_arrow .tabs_icon__svg path, .footerWorks .footerWorks_tabs ul li a:hover .tabs_arrow .tabs_arrow__svg path {
              fill: #fff;
              transition-duration: .3s; }
            .footerWorks .footerWorks_tabs ul li a:hover .tabs_content {
              color: #fff;
              transition-duration: .3s; }
        .footerWorks .footerWorks_tabs ul li:last-child {
          border-right: none; }

@media (max-width: 1024px) {
  .footerWorks {
    padding: 135px 15px; } }

@media (max-width: 767px) {
  .footerWorks {
    padding: 60px 10px; } }

@media (max-width: 1024px) {
  .footerWorks {
    background-position: center top -110px;
    background-size: auto 130%; }
  .footerWorks .title {
    font-size: 37px;
    margin-bottom: 35px; }
  .footerWorks .footerWorks_tabs ul li a .tabs_icon {
    min-width: 74px; }
  .footerWorks .footerWorks_content:before {
    width: 50%;
    background-size: auto 90%;
    background-position: right bottom 50px; }
  .footerWorks .footerWorks_tabs ul li a .tabs_content .tabs_title {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 5px; }
  .footerWorks .footerWorks_tabs {
    margin-top: -30px;
    position: relative; }
  .footerWorks .footerWorks_image img {
    float: right; }
  .footerWorks {
    margin-top: 0; }
  .footerWorks .footerWorks_content h2 {
    width: 34vw;
    font-size: 3.0vw;
    margin-top: -160px; }
  .footerWorks .footerWorks_image img {
    width: 70vw; } }

@media (max-width: 767px) {
  .footerWorks {
    background-position: center top 0; }
  .footerWorks .title {
    font-size: 28px;
    margin-bottom: 35px; }
  .footerWorks .footerWorks_image img {
    display: none; }
  .footerWorks .footerWorks_tabs {
    margin-top: 0px;
    bottom: 0; }
    .footerWorks .footerWorks_tabs .grid-container {
      padding: 0;
      margin: 0; }
  .footerWorks .footerWorks_content h2 {
    margin-top: 0;
    float: left;
    font-size: 8vw;
    width: 100%;
    margin-left: 0px;
    padding: 20px 0; }
  .footerWorks .footerWorks_content {
    width: 100%;
    position: relative;
    padding: 0 20px;
    padding-bottom: 40vw; }
  .footerWorks .footerWorks_content:before {
    width: 100%;
    background-size: 100%;
    bottom: 0;
    background-position: center bottom;
    background-image: url(../img/circle/circleMobile.svg); }
  .footerWorks .footerWorks_tabs ul li {
    border-bottom: 1px solid #C4C4C4; }
  .footerWorks .footerWorks_tabs ul li a .tabs_content {
    flex-grow: 10; }
  .footerWorks .footerWorks_tabs ul {
    box-shadow: none; }
  .footerWorks .footerWorks_tabs ul li.style-for-2 {
    width: 100%; }
  .footerWorks .footerWorks_tabs ul li {
    border-right: none; } }

.news_box {
  float: left;
  background-color: #FAFAFA;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 90px; }
  .news_box .header {
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 60px;
    font-size: 46px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #002E5D; }
  .news_box .read_more {
    float: left;
    width: 100%;
    text-align: center; }
    .news_box .read_more a {
      min-width: 220px;
      display: inline-block;
      background-color: #39A9DC;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      text-align: center;
      position: relative;
      padding: 15px 10px;
      color: #fff;
      font-size: 16px;
      margin-top: 60px;
      overflow: hidden; }
      .news_box .read_more a span {
        position: relative;
        z-index: 1; }
      .news_box .read_more a::after {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 140%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #002E5D;
        content: ''; }
      .news_box .read_more a::before {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 100%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #002E5D;
        opacity: .4;
        content: ''; }
      .news_box .read_more a:hover::after {
        top: -100%; }
      .news_box .read_more a:hover::before {
        top: -120%; }
  .news_box .blog_section__content {
    float: left;
    width: 100%; }
    .news_box .blog_section__content .blog_section__article {
      padding: 30px;
      background-color: #fff;
      margin-bottom: 30px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border-radius: 4px; }
      .news_box .blog_section__content .blog_section__article .article__top {
        float: left;
        width: 100%; }
        .news_box .blog_section__content .blog_section__article .article__top .data {
          float: left;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          font-size: 12px;
          color: #002E5D;
          letter-spacing: 0.7px;
          text-transform: uppercase; }
        .news_box .blog_section__content .blog_section__article .article__top .category {
          float: left;
          font-style: normal;
          font-weight: 500;
          line-height: 11px;
          font-size: 12px;
          text-transform: uppercase;
          display: flex;
          align-items: center; }
          .news_box .blog_section__content .blog_section__article .article__top .category a {
            float: left;
            margin-right: 10px;
            color: #002E5D;
            display: inline-block; }
          .news_box .blog_section__content .blog_section__article .article__top .category::before {
            content: '';
            width: 2px;
            height: 2px;
            background-color: #002E5D;
            float: left;
            margin: 0 12px;
            border-radius: 10px; }
      .news_box .blog_section__content .blog_section__article .article_title {
        padding: 30px 0;
        float: left;
        width: 100%; }
        .news_box .blog_section__content .blog_section__article .article_title h2 {
          line-height: 1em; }
          .news_box .blog_section__content .blog_section__article .article_title h2 a {
            font-style: normal;
            font-weight: 500;
            line-height: 1em;
            font-size: 28px;
            letter-spacing: 0.2px;
            color: #002E5D; }
      .news_box .blog_section__content .blog_section__article .article_image {
        height: 200px;
        float: left;
        margin-bottom: 30px;
        width: 100%;
        background-position: center;
        background-size: cover; }
      .news_box .blog_section__content .blog_section__article .article_content {
        margin-bottom: 35px;
        float: left;
        width: 100%; }
        .news_box .blog_section__content .blog_section__article .article_content p {
          margin: 0;
          padding: 0; }
      .news_box .blog_section__content .blog_section__article .article_more {
        font-weight: 500;
        line-height: 25px;
        font-size: 16px;
        letter-spacing: 0.2px;
        color: #39A9DC; }
        .news_box .blog_section__content .blog_section__article .article_more img {
          height: 7px;
          margin-left: 5px; }
    .news_box .blog_section__content .blog_section__pagination {
      float: left;
      width: 100%;
      text-align: center;
      display: flex;
      margin-top: 30px;
      margin-bottom: 60px;
      justify-content: center; }
      .news_box .blog_section__content .blog_section__pagination a, .news_box .blog_section__content .blog_section__pagination span {
        border: 1px solid #D0D0D0;
        box-sizing: border-box;
        border-radius: 100px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        color: #39A9DC;
        font-size: 22px;
        font-weight: 500;
        justify-content: center;
        line-height: 1em;
        margin: 0 5px;
        transition-duration: .3s; }
        .news_box .blog_section__content .blog_section__pagination a li, .news_box .blog_section__content .blog_section__pagination span li {
          list-style: none;
          display: inline-block; }
        .news_box .blog_section__content .blog_section__pagination a.prev, .news_box .blog_section__content .blog_section__pagination span.prev {
          margin-right: 60px; }
          .news_box .blog_section__content .blog_section__pagination a.prev img, .news_box .blog_section__content .blog_section__pagination span.prev img {
            height: 15px; }
        .news_box .blog_section__content .blog_section__pagination a.next, .news_box .blog_section__content .blog_section__pagination span.next {
          margin-left: 60px; }
          .news_box .blog_section__content .blog_section__pagination a.next img, .news_box .blog_section__content .blog_section__pagination span.next img {
            height: 15px; }
        .news_box .blog_section__content .blog_section__pagination a:hover, .news_box .blog_section__content .blog_section__pagination span:hover {
          background-color: #fff; }
      .news_box .blog_section__content .blog_section__pagination span {
        border: 1px solid #39A9DC; }

@media (max-width: 767px) {
  .news_box .blog_section__content .blog_section__article .article_title h2 a {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    font-size: 19px;
    letter-spacing: 0.2px; }
  .news_box .header {
    font-size: 26px; }
  .news_box {
    padding-top: 60px;
    padding-bottom: 60px; }
  .news_box .read_more a {
    margin-top: 30px; } }

.intro_page {
  float: left;
  width: 100%;
  padding: 60px 0px; }
  .intro_page .intro_page__leftSection {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative; }
    .intro_page .intro_page__leftSection h2 {
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: .4em;
      font-weight: 700;
      margin-top: -60px;
      position: absolute;
      left: 0; }
    .intro_page .intro_page__leftSection .leftIcon {
      margin-left: auto; }
      .intro_page .intro_page__leftSection .leftIcon svg {
        height: 300px; }
  .intro_page .intro_page__rightSection {
    display: flex;
    align-items: center;
    height: 100%; }
    .intro_page .intro_page__rightSection .content {
      font-weight: 400;
      line-height: normal;
      font-size: 39.35px; }
      .intro_page .intro_page__rightSection .content a {
        min-width: 220px;
        float: left;
        display: block;
        background-color: #39A9DC;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        text-align: center;
        position: relative;
        padding: 15px 10px;
        color: #fff;
        font-size: 16px;
        margin-top: 35px;
        overflow: hidden; }
        .intro_page .intro_page__rightSection .content a span {
          position: relative;
          z-index: 1; }
        .intro_page .intro_page__rightSection .content a::after {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 140%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #002E5D;
          content: ''; }
        .intro_page .intro_page__rightSection .content a::before {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 100%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #002E5D;
          opacity: .4;
          content: ''; }
        .intro_page .intro_page__rightSection .content a:hover::after {
          top: -100%; }
        .intro_page .intro_page__rightSection .content a:hover::before {
          top: -120%; }

@media (max-width: 1230px) {
  .intro_page {
    padding: 60px 45px; } }

@media (max-width: 1024px) {
  .intro_page {
    padding: 35px 45px; }
  .intro_page .intro_page__leftSection h2 {
    font-size: 13px; }
  .intro_page .intro_page__rightSection .content {
    font-size: 28px; }
  .intro_page .intro_page__leftSection .leftIcon svg {
    height: 200px; } }

@media (max-width: 767px) {
  .intro_page {
    padding: 15px 0;
    padding-bottom: 30px; }
  .intro_page .intro_page__leftSection h2 {
    font-size: 10px;
    left: auto;
    right: 80px;
    margin-top: 0px;
    line-height: 1em;
    margin-bottom: 0px;
    bottom: 90px; }
  .intro_page .intro_page__rightSection .content {
    font-size: 26px;
    margin-top: 20px; }
  .intro_page .intro_page__leftSection .leftIcon svg {
    height: 140px; }
  .intro_page .intro_page__rightSection .content a {
    margin-top: 50px; } }

.textWithTitle {
  padding: 60px 0;
  float: left;
  width: 100%; }
  .textWithTitle .textWithTitle_content {
    max-width: 1000px;
    margin: 0 auto; }
    .textWithTitle .textWithTitle_content h3 {
      font-size: 14px;
      letter-spacing: 1.4px;
      font-weight: 500;
      text-transform: uppercase;
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: 20px; }
    .textWithTitle .textWithTitle_content .content {
      font-style: normal;
      font-weight: 300;
      line-height: 1.2em;
      font-size: 46px;
      text-align: center;
      letter-spacing: -0.2px; }
      .textWithTitle .textWithTitle_content .content p {
        margin: 0;
        padding: 0; }

@media (max-width: 1024px) {
  .textWithTitle {
    padding: 35px 0;
    padding-bottom: 60px;
    float: left;
    width: 100%; }
    .textWithTitle .textWithTitle_content {
      max-width: 540px;
      margin: 0 auto; }
      .textWithTitle .textWithTitle_content h3 {
        font-size: 14px;
        letter-spacing: 1.4px;
        font-weight: 500;
        text-transform: uppercase;
        float: left;
        width: 100%;
        text-align: center; }
      .textWithTitle .textWithTitle_content .content {
        font-style: normal;
        font-weight: bold;
        line-height: 1.2em;
        font-size: 37px;
        text-align: center;
        letter-spacing: -0.2px; } }

@media (max-width: 767px) {
  .textWithTitle {
    padding: 35px 0;
    padding-bottom: 35px;
    float: left;
    width: 100%; }
    .textWithTitle .textWithTitle_content {
      max-width: 540px;
      margin: 0 auto; }
      .textWithTitle .textWithTitle_content h3 {
        font-size: 14px;
        letter-spacing: 1.4px;
        font-weight: 500;
        text-transform: uppercase;
        float: left;
        width: 100%;
        text-align: center; }
      .textWithTitle .textWithTitle_content .content {
        font-style: normal;
        font-weight: bold;
        line-height: 1.2em;
        font-size: 28px;
        text-align: center;
        letter-spacing: -0.2px; } }

.textWithTitle_subtitle {
  padding: 60px 0;
  padding-bottom: 30px;
  float: left;
  width: 100%; }
  .textWithTitle_subtitle .textWithTitle_subtitle_content {
    max-width: 1000px;
    margin: 0 auto; }
    .textWithTitle_subtitle .textWithTitle_subtitle_content h3 {
      font-size: 14px;
      letter-spacing: 1.4px;
      font-weight: 500;
      text-transform: uppercase;
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: 20px; }
    .textWithTitle_subtitle .textWithTitle_subtitle_content .content {
      font-style: normal;
      font-weight: 300;
      line-height: 1.2em;
      font-size: 46px;
      text-align: center;
      letter-spacing: -0.2px; }
      .textWithTitle_subtitle .textWithTitle_subtitle_content .content p {
        margin: 0;
        padding: 0; }
    .textWithTitle_subtitle .textWithTitle_subtitle_content .content_more {
      text-align: center;
      max-width: 834px;
      margin: 0 auto;
      margin-top: 20px;
      line-height: 38px;
      font-size: 28px;
      font-weight: 300; }

@media (max-width: 1024px) {
  .textWithTitle_subtitle {
    padding: 35px 0;
    padding-bottom: 60px;
    float: left;
    width: 100%; }
    .textWithTitle_subtitle .textWithTitle_subtitle_content {
      max-width: 540px;
      margin: 0 auto; }
      .textWithTitle_subtitle .textWithTitle_subtitle_content h3 {
        font-size: 14px;
        letter-spacing: 1.4px;
        font-weight: 500;
        text-transform: uppercase;
        float: left;
        width: 100%;
        text-align: center; }
      .textWithTitle_subtitle .textWithTitle_subtitle_content .content {
        font-style: normal;
        font-weight: bold;
        line-height: 1.2em;
        font-size: 37px;
        text-align: center;
        letter-spacing: -0.2px; }
  .textWithTitle_subtitle .textWithTitle_subtitle_content .content_more {
    margin-top: 30px;
    font-size: 23px;
    line-height: 1.2em; } }

@media (max-width: 767px) {
  .textWithTitle_subtitle {
    padding: 35px 0;
    padding-bottom: 35px;
    float: left;
    width: 100%; }
    .textWithTitle_subtitle .textWithTitle_subtitle_content {
      max-width: 540px;
      margin: 0 auto; }
      .textWithTitle_subtitle .textWithTitle_subtitle_content h3 {
        font-size: 14px;
        letter-spacing: 1.4px;
        font-weight: 500;
        text-transform: uppercase;
        float: left;
        width: 100%;
        text-align: center; }
      .textWithTitle_subtitle .textWithTitle_subtitle_content .content {
        font-style: normal;
        font-weight: bold;
        line-height: 1.2em;
        font-size: 28px;
        text-align: center;
        letter-spacing: -0.2px; }
  .textWithTitle_subtitle .textWithTitle_subtitle_content .content_more {
    font-size: 19px; } }

.imageTextBox {
  float: left;
  width: 100%;
  padding-top: 75px;
  padding-bottom: 120px; }
  .imageTextBox .imageTextBox_content {
    padding: 75px 60px;
    margin: 0 auto; }
    .imageTextBox .imageTextBox_content .imageTextBox_content__image {
      padding-right: 60px; }
    .imageTextBox .imageTextBox_content .imageTextBox_content__text {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .imageTextBox .imageTextBox_content .imageTextBox_content__text .wrap {
        width: 360px;
        margin-left: -30px;
        font-size: 28px;
        line-height: 39px;
        font-weight: 300; }
    .imageTextBox .imageTextBox_content:nth-child(2n + 2) .grid-x {
      flex-direction: row-reverse; }
      .imageTextBox .imageTextBox_content:nth-child(2n + 2) .grid-x .imageTextBox_content__image {
        padding-right: 0;
        padding-left: 60px; }
      .imageTextBox .imageTextBox_content:nth-child(2n + 2) .grid-x .imageTextBox_content__text .wrap {
        margin-left: 0;
        margin-right: -30px; }
    .imageTextBox .imageTextBox_content:first-child {
      padding-top: 0; }
    .imageTextBox .imageTextBox_content:last-child {
      padding-bottom: 0; }

@media (max-width: 1024px) {
  .imageTextBox .imageTextBox_content .imageTextBox_content__image,
  .imageTextBox .imageTextBox_content:nth-child(2n+2) .grid-x .imageTextBox_content__image {
    padding-right: 0px;
    padding-left: 0; }
  .imageTextBox .imageTextBox_content .grid-x, .imageTextBox .imageTextBox_content:nth-child(2n+2) .grid-x {
    flex-flow: column-reverse wrap; }
  .imageTextBox .imageTextBox_content {
    max-width: 492px;
    padding: 75px 0;
    padding-top: 0px; }
  .imageTextBox .imageTextBox_content:nth-child(2n+2) .grid-x .imageTextBox_content__text .wrap {
    margin-right: 0; }
  .imageTextBox .imageTextBox_content .imageTextBox_content__text .wrap {
    width: 100%;
    margin-left: 0;
    margin-bottom: 60px; }
    .imageTextBox .imageTextBox_content .imageTextBox_content__text .wrap p {
      width: 100%;
      margin: 0;
      font-size: 23px; }
  .imageTextBox {
    padding-bottom: 75px; } }

@media (max-width: 767px) {
  .imageTextBox {
    padding-top: 40px; }
  .imageTextBox .imageTextBox_content .imageTextBox_content__text .wrap {
    text-align: center;
    line-height: 27px;
    font-size: 19px;
    margin-bottom: 40px; }
    .imageTextBox .imageTextBox_content .imageTextBox_content__text .wrap p {
      line-height: 27px;
      font-size: 19px; }
  .imageTextBox .imageTextBox_content .imageTextBox_content__text {
    padding: 0 20px; } }

.imageTextBoxColor {
  float: left;
  width: 100%;
  padding-top: 75px;
  padding-bottom: 120px; }
  .imageTextBoxColor .imageTextBoxColor_content {
    padding: 75px 60px; }
    .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__image {
      padding-right: 60px; }
    .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap {
        width: 360px;
        margin-left: -30px;
        font-size: 16px;
        font-weight: 300;
        line-height: 39px;
        line-height: 24px; }
        .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap p {
          line-height: 1.5em;
          font-weight: 400; }
        .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap .title {
          line-height: 39px;
          font-size: 28px;
          font-weight: 300;
          position: relative;
          padding-bottom: 30px;
          margin-bottom: 30px; }
          .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap .title::after {
            display: block;
            bottom: -3px;
            height: 3px;
            width: 50%;
            right: 0;
            background-color: #002E5D;
            position: absolute;
            content: ''; }
    .imageTextBoxColor .imageTextBoxColor_content:nth-child(2n + 2) .grid-x {
      flex-direction: row-reverse; }
      .imageTextBoxColor .imageTextBoxColor_content:nth-child(2n + 2) .grid-x .imageTextBoxColor_content__image {
        padding-right: 0;
        padding-left: 60px; }
      .imageTextBoxColor .imageTextBoxColor_content:nth-child(2n + 2) .grid-x .imageTextBoxColor_content__text .wrap {
        margin-left: 0;
        margin-right: -30px; }
    .imageTextBoxColor .imageTextBoxColor_content:first-child {
      padding-top: 0; }
    .imageTextBoxColor .imageTextBoxColor_content:last-child {
      padding-bottom: 0; }

@media (max-width: 1024px) {
  .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap .title {
    font-size: 23px;
    line-height: 1.2em; }
  .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap p {
    font-size: 16px;
    line-height: 1.3em; }
  .imageTextBoxColor .imageTextBoxColor_content {
    margin: 0 auto; }
  .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__image,
  .imageTextBoxColor .imageTextBoxColor_content:nth-child(2n+2) .grid-x .imageTextBoxColor_content__image {
    padding-right: 0px;
    padding-left: 0; }
  .imageTextBoxColor .imageTextBoxColor_content .grid-x, .imageTextBoxColor .imageTextBoxColor_content:nth-child(2n+2) .grid-x {
    flex-flow: column-reverse wrap; }
  .imageTextBoxColor .imageTextBoxColor_content {
    max-width: 492px;
    padding: 75px 0;
    padding-top: 0px; }
  .imageTextBoxColor .imageTextBoxColor_content:nth-child(2n+2) .grid-x .imageTextBoxColor_content__text .wrap {
    margin-right: 0; }
  .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap {
    width: 100%;
    margin-left: 0;
    margin-bottom: 60px; }
  .imageTextBoxColor {
    padding-bottom: 75px; } }

@media (max-width: 767px) {
  .imageTextBoxColor {
    padding: 0 20px;
    padding-top: 40px; }
  .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap {
    text-align: center;
    line-height: 27px;
    font-size: 19px;
    margin-bottom: 40px; }
    .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap p {
      line-height: 27px;
      font-size: 19px;
      line-height: 1.3em; }
  .imageTextBoxColor .imageTextBoxColor_content .imageTextBoxColor_content__text .wrap .title {
    margin-bottom: 17px;
    font-weight: 400;
    line-height: 1.2em;
    padding-bottom: 17px; } }

.formSection {
  float: left;
  width: 100%;
  text-align: center;
  padding: 140px 0;
  color: #fff;
  margin-top: 60px; }
  .formSection h2 {
    font-weight: 700;
    line-height: normal;
    font-size: 46px;
    margin: 0;
    padding: 0;
    letter-spacing: 0px;
    font-weight: 300;
    padding: 0 20px; }
  .formSection .content {
    max-width: 695px;
    margin: 0 auto;
    margin-top: 40px; }
    .formSection .content p {
      font-size: 28px;
      line-height: 39px;
      margin: 0;
      letter-spacing: -0.2px;
      padding: 0; }
    .formSection .content a {
      margin-top: 50px;
      display: inline-block;
      font-size: 16px;
      width: 220px;
      background-color: #002E5D;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      text-align: center;
      position: relative;
      padding: 15px 10px;
      color: #fff;
      overflow: hidden; }
      .formSection .content a::after {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 140%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #fff;
        content: ''; }
      .formSection .content a::before {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 100%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #fff;
        opacity: .4;
        content: ''; }
      .formSection .content a:hover::after {
        top: -100%; }
      .formSection .content a:hover::before {
        top: -120%; }
      .formSection .content a span {
        z-index: 1;
        position: relative; }
      .formSection .content a:hover span {
        color: #002E5D; }

@media (max-width: 1024px) {
  .formSection {
    padding: 110px 0; }
  .formSection h2 {
    font-size: 37px;
    max-width: 540px;
    margin: 0 auto;
    padding: 0; }
  .formSection .content {
    max-width: 492px; }
    .formSection .content p {
      line-height: 1.4em;
      font-size: 28px;
      max-width: 500px;
      margin: 0 auto; } }

@media (max-width: 767px) {
  .formSection {
    margin-top: 0px;
    padding: 80px 0; }
  .formSection h2 {
    font-size: 28px; }
  .formSection .content {
    padding: 0 20px; }
    .formSection .content p {
      font-size: 19px;
      line-height: 1.4em; } }

.industries {
  float: left;
  width: 100%;
  padding: 0px 0;
  padding-bottom: 90px; }
  .industries .industries_items {
    display: flex;
    align-items: flex-end; }
    .industries .industries_items .industries_item {
      flex-grow: 1;
      text-align: center;
      height: 100%; }
      .industries .industries_items .industries_item .industries_item__icon svg {
        max-width: 92px;
        max-height: 90px; }
      .industries .industries_items .industries_item .industries_item__title {
        font-size: 20px;
        font-weight: 300;
        margin-top: 20px; }

@media (max-width: 1024px) {
  .industries {
    padding: 35px 30px;
    padding-bottom: 75px; }
  .industries .industries_items .industries_item .industries_item__title {
    font-size: 16px; } }

@media (max-width: 767px) {
  .industries .industries_items {
    flex-wrap: wrap; }
  .industries .industries_items .industries_item {
    width: 100%;
    margin-bottom: 70px; }
    .industries .industries_items .industries_item:last-child {
      margin-bottom: 0; } }

.industries_with_positions {
  float: left;
  width: 100%;
  padding: 0px 0;
  padding-bottom: 120px; }
  .industries_with_positions .industries_items {
    display: flex;
    flex-wrap: wrap; }
    .industries_with_positions .industries_items .industries_item {
      width: 20%;
      text-align: center;
      border-left: 1px solid #EEEEEE;
      border-right: 1px solid #EEEEEE;
      margin: 0 -0.5px;
      padding: 0 30px; }
      .industries_with_positions .industries_items .industries_item .industries_item__icon {
        height: 90px; }
        .industries_with_positions .industries_items .industries_item .industries_item__icon svg {
          max-width: 92px;
          max-height: 90px; }
      .industries_with_positions .industries_items .industries_item .industries_item__title {
        font-size: 20px;
        font-weight: 500;
        margin-top: 20px; }
      .industries_with_positions .industries_items .industries_item .postion {
        margin-top: 40px; }

@media (max-width: 1024px) {
  .industries_with_positions {
    padding: 35px 30px;
    padding-bottom: 75px; }
  .industries_with_positions .industries_items .industries_item .industries_item__title {
    font-size: 21px; }
  .industries_with_positions .industries_items .industries_item {
    width: 33.33%;
    margin-bottom: 60px; }
  .industries_with_positions {
    padding-bottom: 15px; } }

@media (max-width: 767px) {
  .industries_with_positions .industries_items {
    flex-wrap: wrap; }
  .industries_with_positions .industries_items .industries_item {
    width: 100%;
    margin-bottom: 70px; }
    .industries_with_positions .industries_items .industries_item:last-child {
      margin-bottom: 0; }
  .industries_with_positions .industries_items .industries_item {
    border-left: none;
    border-right: none;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    margin: -0.5px 0;
    padding: 40px 0px; }
  .industries_with_positions .industries_items .industries_item .industries_item__title {
    font-size: 18px; }
  .industries_with_positions .industries_items .industries_item .postion {
    margin-top: 30px; } }

.row_3_box_icon {
  float: left;
  width: 100%;
  margin-bottom: 30px; }
  .row_3_box_icon .row_3_box_icon__item {
    margin: 0px 30px;
    margin-bottom: 60px;
    width: calc(100% - 60px); }
    .row_3_box_icon .row_3_box_icon__item .icon {
      float: left;
      width: 100%;
      margin-bottom: 30px;
      height: 90px;
      display: flex;
      align-items: flex-end; }
      .row_3_box_icon .row_3_box_icon__item .icon svg {
        max-width: 80px;
        max-height: 90px; }
    .row_3_box_icon .row_3_box_icon__item h4 {
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      font-size: 20px;
      letter-spacing: -0.2px; }

@media (max-width: 1024px) {
  .row_3_box_icon {
    float: left;
    width: 100%;
    margin-bottom: 30px; }
    .row_3_box_icon .row_3_box_icon__item {
      padding: 0 52px; }
      .row_3_box_icon .row_3_box_icon__item .icon {
        float: left;
        width: 100%;
        margin-bottom: 30px;
        height: 90px;
        display: flex;
        align-items: flex-end; }
        .row_3_box_icon .row_3_box_icon__item .icon svg {
          max-width: 80px;
          max-height: 90px; }
      .row_3_box_icon .row_3_box_icon__item h4 {
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 20px;
        letter-spacing: -0.2px; } }

@media (max-width: 767px) {
  .row_3_box_icon {
    float: left;
    width: 100%;
    margin-bottom: 30px; }
    .row_3_box_icon .grid-margin-x > .small-12 {
      width: 100%; }
    .row_3_box_icon .grid-margin-x > .cell {
      width: calc(100% - 1.25rem);
      margin-left: 0;
      margin-right: 0;
      width: 100%; }
    .row_3_box_icon .row_3_box_icon__item {
      padding: 0 45px; }
      .row_3_box_icon .row_3_box_icon__item .icon {
        float: left;
        width: 100%;
        margin-bottom: 30px;
        height: 90px;
        display: flex;
        align-items: flex-end; }
        .row_3_box_icon .row_3_box_icon__item .icon svg {
          max-width: 80px;
          max-height: 90px; }
      .row_3_box_icon .row_3_box_icon__item h4 {
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 17px;
        letter-spacing: -0.2px; } }

.row_5_circle_box {
  float: left;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 100px; }
  .row_5_circle_box .items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .row_5_circle_box .items .item {
      width: 20%;
      float: left;
      position: relative;
      padding: 0 13px;
      transition-duration: .3s; }
      .row_5_circle_box .items .item .content {
        padding: 40px;
        padding-top: 50px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        font-weight: normal;
        line-height: 18px;
        font-size: 16px;
        text-align: center;
        transition-duration: .3s; }
        .row_5_circle_box .items .item .content .count {
          float: left;
          margin-bottom: 25px;
          width: 100%;
          text-align: center;
          font-weight: bold;
          line-height: 18px;
          font-size: 32px;
          text-align: center;
          color: #FCCB00; }
      .row_5_circle_box .items .item .bg img {
        transition-duration: .1s;
        fill: #fff;
        width: 100%; }

@media (max-width: 1024px) {
  .row_5_circle_box .items .wrap {
    width: 220px;
    position: relative; }
  .row_5_circle_box .items .item {
    width: 33.333%;
    display: flex;
    margin-bottom: 30px;
    justify-content: center; } }

@media (max-width: 767px) {
  .row_5_circle_box .items {
    width: 230px;
    margin: 0 auto; }
  .row_5_circle_box .items .item {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 230px;
    font-size: 16px;
    display: inline-block;
    float: none; }
  .row_5_circle_box .items .item .content .count {
    margin-bottom: 20px; } }

.row_2_box {
  float: left;
  width: 100%; }
  .row_2_box .cell .item {
    padding: 0 100px 0 140px;
    margin-bottom: 60px; }
    .row_2_box .cell .item .head {
      display: flex;
      align-items: flex-end;
      height: 90px;
      margin-bottom: 30px; }
      .row_2_box .cell .item .head .title {
        font-weight: bold;
        line-height: 1em;
        font-size: 22px;
        letter-spacing: -0.2px;
        margin: 0; }
      .row_2_box .cell .item .head .icon {
        float: right;
        margin-left: auto; }
        .row_2_box .cell .item .head .icon img {
          max-height: 100%; }
    .row_2_box .cell .item .content p {
      font-weight: normal;
      line-height: 1.3em;
      font-size: 18px;
      margin-bottom: 20px; }
      .row_2_box .cell .item .content p:last-child {
        margin-bottom: 0; }
  .row_2_box .cell:nth-child(2n + 2) .item {
    padding: 0 140px 0 100px; }

@media (max-width: 1024px) {
  .row_2_box .grid-container {
    max-width: 492px; }
  .row_2_box .cell .item,
  .row_2_box .cell:nth-child(2n+2) .item {
    padding: 0; }
  .row_2_box .cell .item {
    margin-bottom: 45px; } }

@media (max-width: 767px) {
  .row_2_box .cell .item .head .title {
    font-size: 20px; }
  .row_2_box .cell .item .content p {
    font-size: 16px; } }

.text_full_width {
  float: left;
  padding: 60px 0;
  padding-bottom: 90px;
  width: 100%; }
  .text_full_width .content {
    line-height: 38px;
    max-width: 800px;
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.2px; }
    .text_full_width .content p {
      margin-bottom: 80px; }
      .text_full_width .content p:last-child {
        margin-bottom: 0; }

@media (max-width: 1024px) {
  .text_full_width .content {
    font-size: 23px;
    line-height: 1.2em;
    max-width: 540px;
    margin: 0 auto; } }

@media (max-width: 767px) {
  .text_full_width .content {
    font-size: 19px;
    line-height: 1.2em;
    padding: 0 20px; } }

.margin_chagne {
  float: left;
  width: 100%; }

.row_3_box_icon_withTitle {
  float: left;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 60px; }
  .row_3_box_icon_withTitle .row_3_box_icon_withTitle__items {
    padding: 0 60px; }
    .row_3_box_icon_withTitle .row_3_box_icon_withTitle__items .row_3_box_icon_withTitle__item {
      margin-bottom: 0px;
      padding: 0 40px; }
      .row_3_box_icon_withTitle .row_3_box_icon_withTitle__items .row_3_box_icon_withTitle__item .icon {
        float: left;
        width: 100%;
        margin-bottom: 30px;
        height: 90px;
        display: flex;
        align-items: flex-end; }
        .row_3_box_icon_withTitle .row_3_box_icon_withTitle__items .row_3_box_icon_withTitle__item .icon svg {
          max-width: 80px;
          max-height: 90px; }
      .row_3_box_icon_withTitle .row_3_box_icon_withTitle__items .row_3_box_icon_withTitle__item h4 {
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 20px;
        letter-spacing: -0.2px;
        width: 100px; }
      .row_3_box_icon_withTitle .row_3_box_icon_withTitle__items .row_3_box_icon_withTitle__item .content p {
        line-height: 25px;
        margin-top: 30px;
        font-size: 16px; }

@media (max-width: 1024px) {
  .row_3_box_icon_withTitle .row_3_box_icon_withTitle__items .row_3_box_icon_withTitle__item h4 {
    width: 100%; }
  .row_3_box_icon_withTitle {
    padding-bottom: 60px; }
  .row_3_box_icon_withTitle {
    float: left;
    width: 100%;
    margin-bottom: 0px; }
    .row_3_box_icon_withTitle .row_3_box_icon_withTitle__item {
      padding: 0 52px; }
      .row_3_box_icon_withTitle .row_3_box_icon_withTitle__item .icon {
        float: left;
        width: 100%;
        margin-bottom: 30px;
        height: 90px;
        display: flex;
        align-items: flex-end; }
        .row_3_box_icon_withTitle .row_3_box_icon_withTitle__item .icon svg {
          max-width: 80px;
          max-height: 90px; }
      .row_3_box_icon_withTitle .row_3_box_icon_withTitle__item h4 {
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 20px;
        letter-spacing: -0.2px; }
  .row_3_box_icon_withTitle .row_3_box_icon_withTitle__items .row_3_box_icon_withTitle__item {
    margin-bottom: 60px; } }

@media (max-width: 767px) {
  .row_2_box .cell .item,
  .row_2_box .cell:nth-child(2n+2) .item {
    padding: 0 20px; }
  .row_3_box_icon_withTitle .row_3_box_icon_withTitle__items {
    padding: 0; }
  .row_3_box_icon_withTitle {
    float: left;
    width: 100%; }
    .row_3_box_icon_withTitle .grid-margin-x > .small-12 {
      width: 100%; }
    .row_3_box_icon_withTitle .grid-margin-x > .cell {
      width: calc(100% - 1.25rem);
      margin-left: 0;
      margin-right: 0;
      width: 100%; }
    .row_3_box_icon_withTitle .row_3_box_icon_withTitle__item {
      padding: 0 45px; }
      .row_3_box_icon_withTitle .row_3_box_icon_withTitle__item .icon {
        float: left;
        width: 100%;
        margin-bottom: 30px;
        height: 90px;
        display: flex;
        align-items: flex-end; }
        .row_3_box_icon_withTitle .row_3_box_icon_withTitle__item .icon svg {
          max-width: 80px;
          max-height: 90px; }
      .row_3_box_icon_withTitle .row_3_box_icon_withTitle__item h4 {
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 17px;
        letter-spacing: -0.2px; } }

.person {
  padding: 0;
  padding-bottom: 120px;
  float: left;
  width: 100%; }
  .person .title {
    text-align: center;
    font-weight: 300;
    line-height: 1.2em;
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.2px;
    margin-bottom: 50px; }
  .person .items {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .person .items .item {
      padding: 0 60px;
      text-align: center;
      transition-duration: .3s; }
      .person .items .item .image {
        width: 140px;
        display: inline-block;
        height: 140px;
        border-radius: 140px;
        overflow: hidden; }
        .person .items .item .image img {
          width: 100%; }
      .person .items .item .name {
        font-size: 20px;
        text-align: center;
        font-weight: 300;
        margin-top: 30px; }
      .person .items .item:hover {
        transform: scale(1.02); }

@media (max-width: 1024px) {
  .person {
    padding-top: 60px;
    padding-bottom: 60px; } }

@media (max-width: 767px) {
  .person .items .item {
    width: 100%;
    margin-bottom: 60px; } }

.textSection_bigTitle {
  padding: 60px 0;
  float: left;
  width: 100%; }
  .textSection_bigTitle .textSection_bigTitle_content {
    max-width: 1000px;
    margin: 0 auto; }
    .textSection_bigTitle .textSection_bigTitle_content h3 {
      font-size: 46px;
      text-align: center;
      font-weight: 700;
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: 30px; }
    .textSection_bigTitle .textSection_bigTitle_content .content {
      font-style: normal;
      font-weight: 300;
      line-height: 26px;
      font-size: 20px;
      text-align: center;
      letter-spacing: -0.2px; }
      .textSection_bigTitle .textSection_bigTitle_content .content p {
        margin: 0;
        padding: 0; }
    .textSection_bigTitle .textSection_bigTitle_content .content_more {
      text-align: center;
      max-width: 834px;
      margin: 0 auto;
      margin-top: 50px;
      line-height: 38px;
      font-size: 28px;
      font-weight: 300; }

@media (max-width: 1024px) {
  .textSection_bigTitle {
    padding: 35px 0;
    padding-bottom: 60px;
    float: left;
    width: 100%; }
    .textSection_bigTitle .textSection_bigTitle_content {
      max-width: 540px;
      margin: 0 auto; }
      .textSection_bigTitle .textSection_bigTitle_content h3 {
        line-height: normal;
        font-size: 37px;
        text-align: center; }
      .textSection_bigTitle .textSection_bigTitle_content .content {
        font-weight: normal;
        line-height: 28px;
        font-size: 20px; }
  .textSection_bigTitle .textSection_bigTitle_content .content_more {
    margin-top: 30px;
    font-size: 23px;
    line-height: 1.2em; } }

@media (max-width: 767px) {
  .textSection_bigTitle {
    padding: 35px 0;
    padding-bottom: 35px;
    float: left;
    width: 100%; }
    .textSection_bigTitle .textSection_bigTitle_content {
      max-width: 100%;
      margin: 0 auto;
      float: left;
      width: 100%; }
      .textSection_bigTitle .textSection_bigTitle_content h3 {
        font-weight: bold;
        line-height: normal;
        font-size: 26px;
        text-align: left; }
      .textSection_bigTitle .textSection_bigTitle_content .content {
        font-weight: normal;
        line-height: 28px;
        text-align: left;
        font-size: 20px; }
  .textWithTitle_subtitle .textWithTitle_subtitle_content .content_more {
    font-size: 19px; }
  .textSection_bigTitle .textSection_bigTitle_content h3 {
    display: none; }
  .textSection_bigTitle {
    padding: 0;
    padding-bottom: 45px; } }

.boxOffer_section {
  float: left;
  width: 100%;
  margin-bottom: 60px; }
  .boxOffer_section .box {
    background-repeat: no-repeat;
    padding: 60px 0px 45px 0px;
    float: left;
    background-size: 100%;
    height: 510px;
    background-position: center bottom;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    transition-duration: .4s; }
    .boxOffer_section .box.box_1 {
      width: 100%;
      background-position: center;
      background-size: cover;
      align-content: flex-end; }
      .boxOffer_section .box.box_1 .box_header h3 {
        margin: 0; }
      .boxOffer_section .box.box_1 .box_link a {
        margin-top: 30px; }
    .boxOffer_section .box.box_2 {
      width: 50%;
      background-position: center;
      background-size: cover; }
    .boxOffer_section .box .box_header {
      width: 100%;
      text-align: center; }
      .boxOffer_section .box .box_header h3 {
        font-weight: 300;
        line-height: normal;
        font-size: 39.35px;
        text-align: center;
        color: #fff; }
        .boxOffer_section .box .box_header h3 p {
          line-height: 1.2em;
          margin: 0;
          padding: 0; }
    .boxOffer_section .box .box_link {
      width: 100%;
      float: left;
      text-align: center; }
      .boxOffer_section .box .box_link a {
        width: 220px;
        display: inline-block;
        background-color: #39A9DC;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        text-align: center;
        position: relative;
        padding: 15px 10px;
        color: #fff;
        margin-top: 35px;
        font-weight: 500;
        line-height: normal;
        font-size: 16px;
        overflow: hidden; }
        .boxOffer_section .box .box_link a span {
          position: relative;
          z-index: 1; }
        .boxOffer_section .box .box_link a::after {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 140%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #fff;
          content: ''; }
        .boxOffer_section .box .box_link a::before {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 100%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #fff;
          opacity: .4;
          content: ''; }
        .boxOffer_section .box .box_link a:hover::after {
          top: -100%; }
        .boxOffer_section .box .box_link a:hover::before {
          top: -120%; }
        .boxOffer_section .box .box_link a:hover span {
          color: #002E5D; }
    .boxOffer_section .box:hover {
      box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.35); }

@media (max-width: 1024px) {
  .boxOffer_section .box .box_header h3 p {
    font-weight: 300;
    line-height: normal;
    font-size: 27px;
    text-align: center;
    letter-spacing: -0.2px; }
  .boxOffer_section .box {
    height: 390px; } }

@media (max-width: 767px) {
  .boxOffer_section .box {
    height: 390px;
    padding: 45px 0px 45px 0px; }
  .boxOffer_section .box.box_2 {
    width: 100%; } }

.row_3_only_text {
  float: left;
  width: 100%;
  padding: 120px 0;
  padding-bottom: 80px; }
  .row_3_only_text .item {
    display: flex;
    justify-content: center;
    margin-bottom: 40px; }
    .row_3_only_text .item .content {
      width: 100%;
      max-width: 280px; }
      .row_3_only_text .item .content p {
        margin-bottom: 60px;
        font-weight: normal;
        line-height: 24px;
        font-size: 16px; }
        .row_3_only_text .item .content p strong {
          line-height: 26px;
          font-weight: normal;
          font-size: 20px;
          margin-bottom: 20px;
          float: left;
          width: 100%; }
        .row_3_only_text .item .content p:last-child {
          margin-bottom: 0; }

@media (max-width: 1024px) {
  .row_3_only_text {
    padding: 90px 0; } }

@media (max-width: 767px) {
  .row_3_only_text {
    padding: 50px 0; }
  .row_3_only_text .item .content {
    width: 100%;
    margin-bottom: 60px; }
  .row_3_only_text .item:last-child .content:last-child {
    margin-bottom: 0; } }

.contact_full_width {
  float: left;
  width: 100%;
  padding: 120px 0;
  background-color: #FAFAFA; }
  .contact_full_width .item {
    width: 50%;
    float: left;
    text-align: center; }
    .contact_full_width .item a {
      display: inline-block;
      font-weight: 300;
      line-height: normal;
      font-size: 39.35px;
      text-align: center;
      color: #39A9DC; }
      .contact_full_width .item a::after {
        display: block;
        content: '';
        width: 30%;
        height: 1px;
        position: relative;
        transition-duration: .3s;
        background-color: #39A9DC;
        margin-top: 10px;
        margin-bottom: 20px; }
      .contact_full_width .item a:hover::after {
        width: 100%; }
    .contact_full_width .item span {
      width: 100%;
      display: block;
      color: #002E5D; }

@media (max-width: 1024px) {
  .contact_full_width {
    padding: 90px 0; }
    .contact_full_width .item {
      width: 100%; }
      .contact_full_width .item a {
        font-weight: 300;
        line-height: normal;
        font-size: 28px; }
      .contact_full_width .item span {
        font-size: 14px; }
      .contact_full_width .item:first-child {
        margin-bottom: 40px; } }

@media (max-width: 767px) {
  .contact_full_width {
    padding: 50px 0; }
    .contact_full_width .item {
      width: 100%; }
      .contact_full_width .item a {
        font-weight: 300;
        line-height: normal;
        font-size: 26px; }
      .contact_full_width .item span {
        font-size: 14px; }
      .contact_full_width .item:first-child {
        margin-bottom: 40px; } }

.textWithTitle_subtitle_button {
  padding: 75px 0;
  padding-bottom: 120px;
  float: left;
  width: 100%; }
  .textWithTitle_subtitle_button .textWithTitle_subtitle_content {
    max-width: 1000px;
    margin: 0 auto; }
    .textWithTitle_subtitle_button .textWithTitle_subtitle_content h3 {
      font-size: 14px;
      letter-spacing: 1.4px;
      font-weight: 500;
      text-transform: uppercase;
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: 20px; }
    .textWithTitle_subtitle_button .textWithTitle_subtitle_content .content {
      font-style: normal;
      font-weight: 300;
      line-height: 1.2em;
      font-size: 46px;
      text-align: center;
      letter-spacing: -0.2px; }
      .textWithTitle_subtitle_button .textWithTitle_subtitle_content .content p {
        margin: 0;
        padding: 0; }
    .textWithTitle_subtitle_button .textWithTitle_subtitle_content .content_more {
      text-align: center;
      max-width: 834px;
      margin: 0 auto;
      margin-top: 50px;
      line-height: 38px;
      font-size: 28px;
      font-weight: 300; }
  .textWithTitle_subtitle_button .read_more {
    float: left;
    width: 100%;
    text-align: center; }
    .textWithTitle_subtitle_button .read_more a {
      min-width: 220px;
      display: inline-block;
      background-color: #39A9DC;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      text-align: center;
      position: relative;
      padding: 15px 10px;
      color: #fff;
      font-size: 16px;
      margin-top: 40px;
      overflow: hidden; }
      .textWithTitle_subtitle_button .read_more a span {
        position: relative;
        z-index: 1; }
      .textWithTitle_subtitle_button .read_more a::after {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 140%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #002E5D;
        content: ''; }
      .textWithTitle_subtitle_button .read_more a::before {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 100%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #002E5D;
        opacity: .4;
        content: ''; }
      .textWithTitle_subtitle_button .read_more a:hover::after {
        top: -100%; }
      .textWithTitle_subtitle_button .read_more a:hover::before {
        top: -120%; }

@media (max-width: 1024px) {
  .textWithTitle_subtitle_button {
    padding: 35px 0;
    padding-bottom: 60px;
    float: left;
    width: 100%; }
    .textWithTitle_subtitle_button .textWithTitle_subtitle_content {
      max-width: 540px;
      margin: 0 auto; }
      .textWithTitle_subtitle_button .textWithTitle_subtitle_content h3 {
        font-size: 14px;
        letter-spacing: 1.4px;
        font-weight: 500;
        text-transform: uppercase;
        float: left;
        width: 100%;
        text-align: center; }
      .textWithTitle_subtitle_button .textWithTitle_subtitle_content .content {
        font-style: normal;
        font-weight: bold;
        line-height: 1.2em;
        font-size: 37px;
        text-align: center;
        letter-spacing: -0.2px; }
  .textWithTitle_subtitle_button .textWithTitle_subtitle_content .content_more {
    margin-top: 30px;
    font-size: 23px;
    line-height: 1.2em; } }

@media (max-width: 767px) {
  .textWithTitle_subtitle_button {
    padding: 35px 0;
    padding-bottom: 35px;
    float: left;
    width: 100%; }
    .textWithTitle_subtitle_button .textWithTitle_subtitle_content {
      max-width: 540px;
      margin: 0 auto; }
      .textWithTitle_subtitle_button .textWithTitle_subtitle_content h3 {
        font-size: 14px;
        letter-spacing: 1.4px;
        font-weight: 500;
        text-transform: uppercase;
        float: left;
        width: 100%;
        text-align: center; }
      .textWithTitle_subtitle_button .textWithTitle_subtitle_content .content {
        font-style: normal;
        font-weight: bold;
        line-height: 1.2em;
        font-size: 28px;
        text-align: center;
        letter-spacing: -0.2px; }
  .textWithTitle_subtitle_button .textWithTitle_subtitle_content .content_more {
    font-size: 19px; } }

.box_image_background {
  float: left;
  width: 100%;
  padding: 75px 0;
  padding-top: 0; }
  .box_image_background .box_image_background_content {
    float: left;
    width: 100%;
    background-size: 75%;
    background-position: left 0px top -50px;
    background-repeat: no-repeat;
    color: #002E5D;
    padding: 161px 60px;
    position: relative;
    overflow: hidden; }
    .box_image_background .box_image_background_content::after {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #fff;
      content: '';
      opacity: .04; }
    .box_image_background .box_image_background_content .cell {
      margin-left: auto;
      max-width: 320px;
      position: relative; }
      .box_image_background .box_image_background_content .cell .box_image_background_content__box {
        z-index: 12;
        position: relative; }
        .box_image_background .box_image_background_content .cell .box_image_background_content__box h3 p {
          font-size: 39.35px;
          line-height: normal; }
          .box_image_background .box_image_background_content .cell .box_image_background_content__box h3 p strong {
            font-weight: bold; }
        .box_image_background .box_image_background_content .cell .box_image_background_content__box p {
          line-height: 25px;
          font-size: 16px;
          margin-bottom: 30px; }
        .box_image_background .box_image_background_content .cell .box_image_background_content__box .box_image_background_more {
          width: 220px;
          display: block;
          background-color: #39A9DC;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          text-align: center;
          position: relative;
          padding: 15px 10px;
          color: #fff;
          overflow: hidden; }
          .box_image_background .box_image_background_content .cell .box_image_background_content__box .box_image_background_more::after {
            transition-duration: .3s;
            position: absolute;
            display: block;
            height: 1000%;
            left: -100px;
            right: -100px;
            top: 140%;
            bottom: 0;
            border-radius: 1000px;
            background-color: #002E5D;
            content: ''; }
          .box_image_background .box_image_background_content .cell .box_image_background_content__box .box_image_background_more::before {
            transition-duration: .3s;
            position: absolute;
            display: block;
            height: 1000%;
            left: -100px;
            right: -100px;
            top: 100%;
            bottom: 0;
            border-radius: 1000px;
            background-color: #002E5D;
            opacity: .4;
            content: ''; }
          .box_image_background .box_image_background_content .cell .box_image_background_content__box .box_image_background_more:hover::after {
            top: -100%; }
          .box_image_background .box_image_background_content .cell .box_image_background_content__box .box_image_background_more:hover::before {
            top: -120%; }
          .box_image_background .box_image_background_content .cell .box_image_background_content__box .box_image_background_more span {
            z-index: 1;
            position: relative; }

@media (max-width: 1440px) {
  .box_image_background {
    padding: 60px 0; }
  .box_image_background .box_image_background_content {
    padding: 76px 60px; } }

@media (max-width: 1024px) {
  .box_image_background {
    padding: 45px 10px; }
  .box_image_background .box_image_background_content .cell .box_image_background_content__box,
  .box_image_background .box_image_background_content .cell {
    position: initial;
    width: 100%; }
  .box_image_background .box_image_background_content .cell {
    max-width: 100%; }
  .box_image_background .box_image_background_content .cell::before {
    display: none; }
  .box_image_background .box_image_background_content .cell .box_image_background_content__box .box_image_background_more {
    position: absolute;
    margin-left: 50%;
    left: -110px;
    bottom: 50px; }
  .box_image_background .box_image_background_content .cell {
    width: 100%;
    margin-left: 0; }
  .box_image_background .box_image_background_content .cell .box_image_background_content__box h2 {
    font-size: 28px; }
  .box_image_background .box_image_background_content {
    background-size: 115%;
    background-position: left 0vw bottom -30vw;
    height: 540px; }
  .box_image_background .box_image_background_content .cell .box_image_background_content__box h3, .box_image_background .box_image_background_content .cell .box_image_background_content__box p {
    width: 50%;
    display: block;
    float: left; }
  .box_image_background .box_image_background_content .cell .box_image_background_content__box h3 {
    font-size: 28px; }
    .box_image_background .box_image_background_content .cell .box_image_background_content__box h3 p {
      font-size: 28px; }
  .box_image_background .box_image_background_content {
    background-size: 100%;
    background-position: center bottom -25vw; } }

@media (max-width: 767px) {
  .box_image_background .box_image_background_content .cell {
    width: 100%;
    margin-left: 0; }
  .box_image_background {
    padding: 30px 0; }
  .box_image_background .box_image_background_content {
    height: auto; }
  .box_image_background .box_image_background_content {
    padding: 60px 20px;
    background-position: left bottom 0vw;
    padding-bottom: 240px; }
  .box_image_background .box_image_background_content .cell .box_image_background_content__box h2 {
    font-size: 26px; }
  .box_image_background .box_image_background_content {
    background-position: left bottom -29vw; }
  .box_image_background .box_image_background_content .cell .box_image_background_content__box h3, .box_image_background .box_image_background_content .cell .box_image_background_content__box p {
    width: 100%;
    display: block;
    float: left; }
  .box_image_background .box_image_background_content .cell .box_image_background_content__box h3 {
    font-size: 28px; }
    .box_image_background .box_image_background_content .cell .box_image_background_content__box h3 p {
      font-size: 28px; }
  .box_image_background .box_image_background_content .cell .box_image_background_content__box p br {
    display: none; } }

@media (max-width: 650px) {
  .box_image_background .box_image_background_content {
    background-position: left bottom -21vw; } }

@media (max-width: 550px) {
  .box_image_background .box_image_background_content {
    background-position: left bottom -14vw; } }

@media (max-width: 450px) {
  .box_image_background .box_image_background_content {
    background-position: left bottom -10vw; } }

@media (max-width: 400px) {
  .box_image_background .box_image_background_content {
    background-position: left bottom 0vw; } }

.new_offers {
  padding-top: 120px;
  padding-bottom: 90px;
  float: left;
  width: 100%;
  background-color: #FAFAFA; }
  .new_offers .read_more {
    float: left;
    width: 100%;
    text-align: center; }
    .new_offers .read_more a {
      min-width: 220px;
      display: inline-block;
      background-color: #39A9DC;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      text-align: center;
      position: relative;
      padding: 15px 10px;
      color: #fff;
      font-size: 16px;
      margin-top: 60px;
      overflow: hidden; }
      .new_offers .read_more a span {
        position: relative;
        z-index: 1; }
      .new_offers .read_more a::after {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 140%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #002E5D;
        content: ''; }
      .new_offers .read_more a::before {
        transition-duration: .3s;
        position: absolute;
        display: block;
        height: 1000%;
        left: -100px;
        right: -100px;
        top: 100%;
        bottom: 0;
        border-radius: 1000px;
        background-color: #002E5D;
        opacity: .4;
        content: ''; }
      .new_offers .read_more a:hover::after {
        top: -100%; }
      .new_offers .read_more a:hover::before {
        top: -120%; }
  .new_offers .new_offers_tmpl .new_offers_tmpl__header {
    margin-bottom: 60px; }
    .new_offers .new_offers_tmpl .new_offers_tmpl__header h2 {
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 46px;
      text-align: center;
      line-height: 1em;
      color: #002E5D; }

@keyframes mymove {
  0% {
    top: 0px; }
  30% {
    top: 3px; }
  60% {
    top: -3px; }
  100% {
    top: 0px; } }
  .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 30px;
    background-color: #fff;
    transition-duration: .3s; }
    .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item:hover {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25); }
    .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent {
      display: block;
      float: left; }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .category {
        width: 100%;
        float: left;
        margin-bottom: 20px; }
        .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .category .category_item {
          background-color: rgba(2, 183, 164, 0.05);
          color: #02B7A4;
          padding: 8px 15px;
          border-radius: 20px;
          font-size: 10px;
          font-weight: 700;
          float: left;
          letter-spacing: 1px;
          text-transform: uppercase; }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .title {
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 28px;
        width: 100%;
        margin-bottom: 15px;
        float: left; }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .forWho {
        width: 100%;
        float: left;
        font-weight: bold;
        line-height: 12px;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 40px; }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .date {
        width: 50%;
        float: left;
        font-weight: 500;
        line-height: 12px;
        font-size: 12px;
        display: flex;
        align-items: center;
        text-transform: uppercase; }
        .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .date img, .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .date svg {
          margin-right: 10px; }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .place {
        width: 50%;
        float: left;
        font-weight: 500;
        line-height: 12px;
        font-size: 12px;
        display: flex;
        align-items: center;
        text-transform: uppercase; }
        .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .place img, .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .place svg {
          margin-right: 10px; }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .content {
        margin-top: 25px;
        font-style: normal;
        font-weight: normal;
        float: left;
        width: 100%; }
        .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .content p {
          line-height: 1.5em;
          font-size: 16px;
          opacity: .8; }
          .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .content p:last-child {
            margin-bottom: 0; }
    .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .goTo {
      margin-top: 25px;
      float: left;
      width: 100%; }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .goTo a {
        font-size: 16px;
        color: #39A9DC; }
        .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .goTo a img, .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .goTo a svg {
          margin-left: 5px;
          position: relative; }
        .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .goTo a:hover img, .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .goTo a:hover svg {
          animation: mymove .8s forwards; }
  .new_offers .rightBox {
    display: none; }
    .new_offers .rightBox .date {
      width: 100%;
      float: left;
      font-weight: 500;
      line-height: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      text-transform: uppercase; }
      .new_offers .rightBox .date img, .new_offers .rightBox .date svg {
        margin-right: 10px; }
    .new_offers .rightBox .place {
      width: 100%;
      float: left;
      font-weight: 500;
      line-height: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      text-transform: uppercase; }
      .new_offers .rightBox .place img, .new_offers .rightBox .place svg {
        margin-right: 10px; }
  .new_offers .category {
    width: 100%;
    float: left;
    margin-bottom: 30px; }
    .new_offers .category .category_item {
      background-color: rgba(2, 183, 164, 0.05);
      color: #02B7A4;
      padding: 8px 15px;
      border-radius: 20px;
      font-size: 10px;
      font-weight: 700;
      float: left;
      letter-spacing: 1px;
      text-transform: uppercase; }

@media (max-width: 1024px) {
  .new_offers .new_offers_tmpl .new_offers_tmpl__header h2 {
    font-size: 37px; }
  .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .content {
    margin-top: 0; }
  .new_offers {
    padding-top: 90px; }
  .mobileNone {
    display: none !important; }
  .new_offers_tmpl__itemWrap {
    width: calc(100% - 210px);
    float: left;
    margin-right: 30px; }
  .new_offers .rightBox {
    display: block;
    width: 180px;
    float: left; }
  .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item {
    margin-bottom: 30px; }
  .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item:last-child {
    margin-bottom: 0; }
  .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .title {
    font-size: 23px; } }

@media (max-width: 767px) {
  .mobileNone {
    display: flex !important; }
  .new_offers_tmpl__itemWrap {
    width: 100%; }
  .new_offers .rightBox {
    display: none; }
  .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .content {
    margin-top: 25px; }
  .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .title {
    font-size: 28px; } }

.text_full_width--media {
  float: left;
  padding: 90px 0;
  padding-bottom: 120px;
  width: 100%; }
  .text_full_width--media .content {
    line-height: 38px;
    max-width: 800px;
    margin: 0 auto;
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.2px;
    margin-bottom: 60px; }
    .text_full_width--media .content p {
      margin-bottom: 80px; }
      .text_full_width--media .content p:last-child {
        margin-bottom: 0; }
  .text_full_width--media .person {
    padding: 0; }
    .text_full_width--media .person .person_image {
      float: left;
      width: 50%;
      padding-right: 50px; }
      .text_full_width--media .person .person_image .person_image_wrap {
        width: 140px;
        height: 140px;
        border-radius: 100%;
        overflow: hidden;
        float: right; }
        .text_full_width--media .person .person_image .person_image_wrap img {
          width: 100%; }
    .text_full_width--media .person .person_info {
      padding-left: 50px;
      float: left;
      width: 50%; }
      .text_full_width--media .person .person_info .name {
        line-height: 26px;
        font-size: 24px;
        font-weight: 500; }
      .text_full_width--media .person .person_info .name_more {
        margin-top: 20px; }
        .text_full_width--media .person .person_info .name_more p {
          font-size: 16px;
          margin-bottom: 5px; }
      .text_full_width--media .person .person_info .name_contact {
        margin-top: 20px; }
        .text_full_width--media .person .person_info .name_contact p {
          margin: 0;
          padding: 0;
          margin-bottom: 15px;
          width: 100%;
          float: left; }
          .text_full_width--media .person .person_info .name_contact p:last-child {
            margin-bottom: 0; }
          .text_full_width--media .person .person_info .name_contact p a {
            font-size: 16px;
            text-decoration: none;
            color: #39A9DC;
            float: left;
            transition-duration: .3s; }
            .text_full_width--media .person .person_info .name_contact p a::after {
              transition-duration: .3s;
              content: '';
              width: 0%;
              background-color: #EF4253;
              height: 1px;
              float: none;
              margin-top: 5px;
              display: block; }
            .text_full_width--media .person .person_info .name_contact p a:hover {
              color: #EF4253; }
              .text_full_width--media .person .person_info .name_contact p a:hover::after {
                width: 50%; }

@media (max-width: 1024px) {
  .text_full_width--media {
    padding: 35px 0;
    padding-bottom: 70px; }
  .text_full_width--media .content {
    font-size: 23px;
    line-height: 1.2em;
    max-width: 540px;
    margin: 0 auto;
    margin-bottom: 50px; }
  .text_full_width--media .person .person_image {
    padding-right: 20px; }
  .text_full_width--media .person .person_info {
    padding-left: 20px; } }

@media (max-width: 767px) {
  .text_full_width--media .content {
    font-size: 19px;
    line-height: 1.2em;
    padding: 0 20px; }
  .text_full_width--media .person .person_image {
    width: 100%;
    text-align: center;
    padding-right: 0;
    margin-bottom: 30px; }
  .text_full_width--media .person .person_image .person_image_wrap {
    float: none;
    display: inline-block; }
  .text_full_width--media .person .person_info {
    width: 100%; }
  .text_full_width--media .person .person_info {
    text-align: center;
    padding-left: 0; }
  .text_full_width--media .person .person_info .name_contact p {
    text-align: center; }
  .text_full_width--media .person .person_info .name_contact p a {
    float: none;
    display: inline-block; } }

@keyframes mymove2 {
  0% {
    top: 0px; }
  30% {
    top: 3px; }
  60% {
    top: -3px; }
  100% {
    top: 0px; } }

.download_section {
  float: left;
  width: 100%;
  background-color: #FAFAFA;
  padding: 120px 0; }
  .download_section .download_section_header {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 60px; }
    .download_section .download_section_header .download_section_subtitle {
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-size: 14px;
      text-align: center;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      margin-bottom: 30px; }
    .download_section .download_section_header .download_section_title {
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 46px;
      text-align: center; }
  .download_section .download_section_items {
    float: left;
    width: 100%; }
    .download_section .download_section_items .item {
      background-color: #fff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 30px;
      padding-bottom: 35px;
      display: flex;
      transition-duration: .3s;
      margin-bottom: 30px;
      flex-wrap: wrap; }
      .download_section .download_section_items .item:last-child {
        margin-bottom: 0; }
      .download_section .download_section_items .item:hover {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25); }
      .download_section .download_section_items .item .item_left {
        width: calc(100% - 80px); }
        .download_section .download_section_items .item .item_left .item_left_fileName {
          font-style: normal;
          font-weight: normal;
          line-height: 38px;
          font-size: 28px; }
        .download_section .download_section_items .item .item_left .item_left_fileType {
          font-style: normal;
          font-weight: bold;
          line-height: 12px;
          font-size: 12px;
          letter-spacing: 0.7px;
          margin-top: 15px;
          text-transform: uppercase; }
      .download_section .download_section_items .item .item_right {
        margin-left: auto; }
        .download_section .download_section_items .item .item_right a {
          font-style: normal;
          font-weight: normal;
          line-height: 25px;
          font-size: 16px;
          display: block;
          position: relative;
          margin-top: 5px;
          color: #39A9DC; }
          .download_section .download_section_items .item .item_right a img, .download_section .download_section_items .item .item_right a svg {
            margin-left: 5px;
            position: relative; }
          .download_section .download_section_items .item .item_right a:hover img, .download_section .download_section_items .item .item_right a:hover svg {
            animation: mymove2 .8s forwards; }

@media (max-width: 767px) {
  .download_section .download_section_header .download_section_title {
    font-size: 28px; }
  .download_section .download_section_header .download_section_subtitle {
    margin-bottom: 15px; }
  .download_section .download_section_items .item .item_left .item_left_fileName {
    font-size: 22px; }
  .download_section {
    padding: 70px 0; } }

.offersNav {
  float: left;
  width: 100%; }
  .offersNav .offersMenu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center; }
    .offersNav .offersMenu li {
      width: 20%;
      text-align: center;
      float: left; }
      .offersNav .offersMenu li a {
        display: inline-block;
        color: #39A9DC;
        font-size: 20px;
        font-weight: 300; }
        .offersNav .offersMenu li a .menu-image-hover-wrapper {
          margin-right: 25px; }
          .offersNav .offersMenu li a .menu-image-hover-wrapper .hovered-image {
            display: none; }
        .offersNav .offersMenu li a:hover {
          color: #02B7A4; }
          .offersNav .offersMenu li a:hover .menu-image-hover-wrapper .menu-image {
            display: none; }
          .offersNav .offersMenu li a:hover .menu-image-hover-wrapper .hovered-image {
            display: inline-block;
            margin-left: 0px !important; }
      .offersNav .offersMenu li.current-menu-item a {
        color: #02B7A4; }
        .offersNav .offersMenu li.current-menu-item a .menu-image-hover-wrapper .menu-image {
          display: none; }
        .offersNav .offersMenu li.current-menu-item a .menu-image-hover-wrapper .hovered-image {
          display: inline-block;
          opacity: 1;
          margin-left: 0px !important; }

.menu-item a.menu-image-title-after.menu-image-not-hovered img, .menu-item a.menu-image-hovered.menu-image-title-after .menu-image-hover-wrapper {
  padding-right: 0 !important; }

@media (max-width: 1024px) {
  .offersNav .offersMenu li a .menu-image-hover-wrapper {
    margin-right: 0; }
  .menu-item a.menu-image-title-after.menu-image-not-hovered img, .menu-item a.menu-image-hovered.menu-image-title-after .menu-image-hover-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 10px; }
  .offersNav .offersMenu {
    align-items: flex-end; } }

@media (max-width: 767px) {
  .offersNav .offersMenu li a .menu-image-hover-wrapper {
    margin-right: 0; }
  .menu-item a.menu-image-title-after.menu-image-not-hovered img, .menu-item a.menu-image-hovered.menu-image-title-after .menu-image-hover-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 10px; }
  .offersNav .offersMenu {
    align-items: flex-end; }
  .offersNav .offersMenu li a {
    font-size: 10px; }
  .menu-item a img {
    max-width: 35px;
    max-height: 30px; } }

.offers_section {
  background-color: #FAFAFA;
  float: left;
  width: 100%;
  padding: 50px 0; }

.offersWrap_section {
  float: left;
  width: 100%; }
  .offersWrap_section .offersSection_tmpl {
    padding: 60px 0;
    float: left;
    width: 100%; }
    .offersWrap_section .offersSection_tmpl .offersSection_tmpl__header {
      margin-bottom: 60px; }
      .offersWrap_section .offersSection_tmpl .offersSection_tmpl__header h2 {
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        font-size: 46px;
        text-align: center;
        line-height: 1em;
        color: #002E5D; }
    .offersWrap_section .offersSection_tmpl .readMore {
      float: left;
      width: 100%;
      text-align: center; }
      .offersWrap_section .offersSection_tmpl .readMore a {
        min-width: 220px;
        display: inline-block;
        background-color: #39A9DC;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        text-align: center;
        position: relative;
        padding: 15px 10px;
        color: #fff;
        font-size: 16px;
        margin-top: 60px;
        overflow: hidden; }
        .offersWrap_section .offersSection_tmpl .readMore a span {
          position: relative;
          z-index: 1; }
        .offersWrap_section .offersSection_tmpl .readMore a::after {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 140%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #002E5D;
          content: ''; }
        .offersWrap_section .offersSection_tmpl .readMore a::before {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 100%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #002E5D;
          opacity: .4;
          content: ''; }
        .offersWrap_section .offersSection_tmpl .readMore a:hover::after {
          top: -100%; }
        .offersWrap_section .offersSection_tmpl .readMore a:hover::before {
          top: -120%; }

@media (max-width: 1024px) {
  .offersWrap_section .offersSection_tmpl .offersSection_tmpl__header h2 {
    font-size: 37px; } }

@media (max-width: 767px) {
  .offersWrap_section .offersSection_tmpl {
    padding: 30px 0; }
  .offersWrap_section .offersSection_tmpl .offersSection_tmpl__header h2 {
    font-size: 28px; }
  .offersWrap_section .offersSection_tmpl .offersSection_tmpl__header {
    margin-bottom: 30px; } }

@keyframes mymove {
  0% {
    top: 0px; }
  30% {
    top: 3px; }
  60% {
    top: -3px; }
  100% {
    top: 0px; } }

.offersSection_tmpl__item {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 30px;
  background-color: #fff;
  transition-duration: .3s; }
  .offersSection_tmpl__item:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25); }
  .offersSection_tmpl__item .offersSection_tmpl__itemContent {
    display: block;
    float: left; }
    .offersSection_tmpl__item .offersSection_tmpl__itemContent .category {
      width: 100%;
      float: left;
      margin-bottom: 20px; }
      .offersSection_tmpl__item .offersSection_tmpl__itemContent .category .category_item {
        background-color: rgba(2, 183, 164, 0.05);
        color: #02B7A4;
        padding: 8px 15px;
        border-radius: 20px;
        font-size: 10px;
        font-weight: 700;
        float: left;
        letter-spacing: 1px;
        text-transform: uppercase; }
    .offersSection_tmpl__item .offersSection_tmpl__itemContent .title {
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 28px;
      width: 100%;
      margin-bottom: 15px;
      float: left; }
    .offersSection_tmpl__item .offersSection_tmpl__itemContent .forWho {
      width: 100%;
      float: left;
      font-weight: bold;
      line-height: 12px;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 40px; }
    .offersSection_tmpl__item .offersSection_tmpl__itemContent .date {
      width: 50%;
      float: left;
      font-weight: 500;
      line-height: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      text-transform: uppercase; }
      .offersSection_tmpl__item .offersSection_tmpl__itemContent .date img, .offersSection_tmpl__item .offersSection_tmpl__itemContent .date svg {
        margin-right: 10px; }
    .offersSection_tmpl__item .offersSection_tmpl__itemContent .place {
      width: 50%;
      float: left;
      font-weight: 500;
      line-height: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      text-transform: uppercase; }
      .offersSection_tmpl__item .offersSection_tmpl__itemContent .place img, .offersSection_tmpl__item .offersSection_tmpl__itemContent .place svg {
        margin-right: 10px; }
    .offersSection_tmpl__item .offersSection_tmpl__itemContent .content {
      margin-top: 25px;
      font-style: normal;
      font-weight: normal;
      float: left;
      width: 100%; }
      .offersSection_tmpl__item .offersSection_tmpl__itemContent .content p {
        line-height: 1.5em;
        font-size: 16px;
        opacity: .8; }
        .offersSection_tmpl__item .offersSection_tmpl__itemContent .content p:last-child {
          margin-bottom: 0; }
  .offersSection_tmpl__item .goTo {
    margin-top: 25px;
    float: left;
    width: 100%; }
    .offersSection_tmpl__item .goTo a {
      font-size: 16px;
      color: #39A9DC; }
      .offersSection_tmpl__item .goTo a img, .offersSection_tmpl__item .goTo a svg {
        margin-left: 5px;
        position: relative; }
      .offersSection_tmpl__item .goTo a:hover img, .offersSection_tmpl__item .goTo a:hover svg {
        animation: mymove .8s forwards; }
  .offersSection_tmpl__item .rightBox {
    display: none; }
    .offersSection_tmpl__item .rightBox .date {
      width: 100%;
      float: left;
      font-weight: 500;
      line-height: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      text-transform: uppercase; }
      .offersSection_tmpl__item .rightBox .date img, .offersSection_tmpl__item .rightBox .date svg {
        margin-right: 10px; }
    .offersSection_tmpl__item .rightBox .place {
      width: 100%;
      float: left;
      font-weight: 500;
      line-height: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      text-transform: uppercase; }
      .offersSection_tmpl__item .rightBox .place img, .offersSection_tmpl__item .rightBox .place svg {
        margin-right: 10px; }
  .offersSection_tmpl__item .category {
    width: 100%;
    float: left;
    margin-bottom: 30px; }
    .offersSection_tmpl__item .category .category_item {
      background-color: rgba(2, 183, 164, 0.05);
      color: #02B7A4;
      padding: 8px 15px;
      border-radius: 20px;
      font-size: 10px;
      font-weight: 700;
      float: left;
      letter-spacing: 1px;
      text-transform: uppercase; }

@media (max-width: 1024px) {
  .new_offers_tmpl .new_offers_tmpl__header h2 {
    font-size: 37px; }
  .offersSection_tmpl__item .offersSection_tmpl__itemContent .content {
    margin-top: 0; }
  .new_offers {
    padding-top: 90px; }
  .mobileNone {
    display: none !important; }
  .offersSection_tmpl__itemWrap {
    width: calc(100% - 210px);
    float: left;
    margin-right: 30px; }
  .offersSection_tmpl__item .rightBox {
    display: block;
    width: 180px;
    float: left; }
  .offersSection_tmpl__item {
    margin-bottom: 30px; }
  .offersSection_tmpl__item:last-child {
    margin-bottom: 0; }
  .offersSection_tmpl__item .offersSection_tmpl__itemContent .title {
    font-size: 23px; } }

@media (max-width: 767px) {
  .mobileNone {
    display: flex !important; }
  .offersSection_tmpl__itemWrap {
    width: 100%; }
  .offersSection_tmpl__item .rightBox {
    display: none; }
  .offersSection_tmpl__item .offersSection_tmpl__itemContent .content {
    margin-top: 25px; }
  .offersSection_tmpl__item .offersSection_tmpl__itemContent .title {
    font-size: 28px; } }

.offersPage {
  background-color: #FAFAFA;
  float: left;
  width: 100%;
  padding: 50px 0; }

.offersPageWrap_section {
  float: left;
  width: 100%; }
  .offersPageWrap_section .offersPage_tmpl {
    padding: 60px 0;
    float: left;
    width: 100%; }
    .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__header {
      margin-bottom: 60px; }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__header h2 {
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        font-size: 46px;
        text-align: center;
        line-height: 1em;
        color: #002E5D; }
    .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item {
      margin-bottom: 30px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 30px;
      background-color: #fff;
      transition-duration: .3s; }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item:hover {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25); }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item.offersPage_tmpl__itemOpen .offersPage_tmpl__itemContent .content .contentShort {
        display: none; }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item.offersPage_tmpl__itemOpen .offersPage_tmpl__itemContent .content .contentFull {
        display: block; }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item.offersPage_tmpl__itemOpen .goTo .apply .hideText {
        display: inline-block; }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item.offersPage_tmpl__itemOpen .goTo .apply .applyText {
        display: none; }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item.offersPage_tmpl__itemOpen .goTo .apply img, .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item.offersPage_tmpl__itemOpen .goTo .apply svg {
        transform: rotate(180deg); }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item.offersPage_tmpl__itemOpen .goTo .goToForm {
        display: inline-block; }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent {
        display: block;
        float: left;
        width: calc(100% - 200px);
        padding-right: 30px; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .category {
          width: 100%;
          float: left;
          margin-bottom: 20px; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .category .category_item {
            background-color: rgba(2, 183, 164, 0.05);
            color: #02B7A4;
            padding: 8px 15px;
            border-radius: 20px;
            font-size: 10px;
            font-weight: 700;
            float: left;
            letter-spacing: 1px;
            text-transform: uppercase; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .title {
          font-style: normal;
          font-weight: normal;
          line-height: normal;
          font-size: 28px;
          width: 100%;
          margin-bottom: 15px;
          float: left; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .forWho {
          width: 100%;
          float: left;
          font-weight: bold;
          line-height: 12px;
          font-size: 12px;
          text-transform: uppercase;
          margin-bottom: 40px; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .date {
          width: 50%;
          float: left;
          font-weight: 500;
          line-height: 12px;
          font-size: 12px;
          display: flex;
          align-items: center;
          text-transform: uppercase; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .date img, .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .date svg {
            margin-right: 10px; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .date.mobileShow {
            display: none; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .place {
          width: 50%;
          float: left;
          font-weight: 500;
          line-height: 12px;
          font-size: 12px;
          display: flex;
          align-items: center;
          text-transform: uppercase; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .place img, .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .place svg {
            margin-right: 10px; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .place.mobileShow {
            display: none; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .content {
          margin-top: 0;
          font-style: normal;
          font-weight: normal;
          float: left;
          width: 100%; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .content .contentShort {
            display: block; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .content .contentFull {
            display: none; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .content p {
            line-height: 1.5em;
            font-size: 16px;
            opacity: .8; }
            .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .content p:last-child {
              margin-bottom: 0; }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .itemRight {
        width: 200px;
        float: left; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .itemRight .category {
          width: 100%;
          float: left;
          margin-bottom: 20px; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .itemRight .category .category_item {
            background-color: rgba(2, 183, 164, 0.05);
            color: #02B7A4;
            padding: 8px 15px;
            border-radius: 20px;
            font-size: 10px;
            font-weight: 700;
            float: left;
            letter-spacing: 1px;
            text-transform: uppercase; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .itemRight .date {
          width: 100%;
          float: left;
          font-weight: 500;
          line-height: 12px;
          font-size: 12px;
          display: flex;
          align-items: center;
          text-transform: uppercase; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .itemRight .date span {
            width: 20px;
            text-align: center;
            margin-right: 10px; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .itemRight .date.mobileShow {
            display: none; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .itemRight .place {
          width: 100%;
          float: left;
          font-weight: 500;
          line-height: 12px;
          font-size: 12px;
          display: flex;
          align-items: center;
          margin-top: 25px;
          text-transform: uppercase; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .itemRight .place span {
            width: 20px;
            text-align: center;
            margin-right: 10px; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .itemRight .place.mobileShow {
            display: none; }
      .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo {
        margin-top: 25px;
        float: left;
        width: 100%;
        display: flex;
        align-items: center; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .apply {
          font-size: 16px;
          color: #39A9DC;
          width: 100px;
          display: inline-block; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .apply .applyText {
            display: inline-block; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .apply .hideText {
            display: none; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .apply img, .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .apply svg {
            margin-left: 5px;
            position: relative; }
            .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .apply img.iconRotate, .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .apply svg.iconRotate {
              transform: rotate(180deg); }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .apply:hover img, .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .apply:hover svg {
            animation: mymove .8s forwards; }
        .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .goToForm {
          float: left;
          flex-grow: 100;
          text-align: center;
          display: none; }
          .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .goToForm button {
            min-width: 220px;
            display: inline-block;
            margin-left: -100px;
            background-color: #002E5D;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
            border-radius: 3px;
            text-align: center;
            position: relative;
            padding: 15px 10px;
            color: #fff;
            font-size: 16px;
            color: #fff;
            overflow: hidden; }
            .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .goToForm button span {
              position: relative;
              z-index: 1;
              color: #fff; }
            .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .goToForm button::after {
              transition-duration: .3s;
              position: absolute;
              display: block;
              height: 1000%;
              left: -100px;
              right: -100px;
              top: 140%;
              bottom: 0;
              border-radius: 1000px;
              background-color: #39A9DC;
              content: ''; }
            .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .goToForm button::before {
              transition-duration: .3s;
              position: absolute;
              display: block;
              height: 1000%;
              left: -100px;
              right: -100px;
              top: 100%;
              bottom: 0;
              border-radius: 1000px;
              background-color: #39A9DC;
              opacity: .4;
              content: ''; }
            .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .goToForm button:hover::after {
              top: -100%; }
            .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .goTo .goToForm button:hover::before {
              top: -120%; }
  .offersPageWrap_section .offersPage_pagination {
    float: left;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center; }
    .offersPageWrap_section .offersPage_pagination a, .offersPageWrap_section .offersPage_pagination span {
      border: 1px solid #D0D0D0;
      box-sizing: border-box;
      border-radius: 100px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      color: #39A9DC;
      font-size: 22px;
      font-weight: 500;
      justify-content: center;
      line-height: 1em;
      margin: 0 5px;
      transition-duration: .3s; }
      .offersPageWrap_section .offersPage_pagination a li, .offersPageWrap_section .offersPage_pagination span li {
        list-style: none;
        display: inline-block; }
      .offersPageWrap_section .offersPage_pagination a.prev, .offersPageWrap_section .offersPage_pagination span.prev {
        margin-right: 60px; }
        .offersPageWrap_section .offersPage_pagination a.prev img, .offersPageWrap_section .offersPage_pagination span.prev img {
          height: 15px; }
      .offersPageWrap_section .offersPage_pagination a.next, .offersPageWrap_section .offersPage_pagination span.next {
        margin-left: 60px; }
        .offersPageWrap_section .offersPage_pagination a.next img, .offersPageWrap_section .offersPage_pagination span.next img {
          height: 15px; }
      .offersPageWrap_section .offersPage_pagination a:hover, .offersPageWrap_section .offersPage_pagination span:hover {
        background-color: #fff; }
    .offersPageWrap_section .offersPage_pagination span {
      border: 1px solid #39A9DC; }
  .offersPageWrap_section .mobileShow {
    display: none; }

@media (max-width: 767px) {
  .mobileHide {
    display: none; }
  .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent {
    padding-right: 0;
    width: 100%; }
  .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .date.mobileShow,
  .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .place.mobileShow {
    display: block; }
  .offersPageWrap_section .mobileShow {
    display: block; }
  .offersPageWrap_section .offersPage_tmpl .offersPage_tmpl__items .offersPage_tmpl__item .offersPage_tmpl__itemContent .content {
    margin-top: 25px; } }

#formWorkers {
  float: left;
  width: 100%; }

.headerForm {
  padding: 20px 25px;
  padding-bottom: 40px; }
  .headerForm .header_title {
    margin: 0;
    padding: 0;
    line-height: 0em;
    float: left;
    font-size: 1px; }
    .headerForm .header_title .header_link {
      line-height: 1em; }
      .headerForm .header_title .header_link .header_logo {
        width: 156px; }
  .headerForm .progressG {
    display: flex;
    justify-content: center; }
  .headerForm .progress {
    float: left;
    width: calc(100% - 156px);
    margin-left: -78px;
    height: 6px;
    outline: none;
    background-color: #283890;
    margin-top: 9px;
    white-space: wrap;
    position: relative;
    position: initial; }
    .headerForm .progress .progress-meter-text {
      width: 100%;
      top: calc(100% + 10px);
      transform: none;
      position: relative;
      left: 0; }
      .headerForm .progress .progress-meter-text .info {
        display: inline-block;
        float: left;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        font-size: 16px;
        letter-spacing: 0.2px;
        top: -8px;
        color: #39A9DC;
        width: 80%;
        white-space: normal; }
      .headerForm .progress .progress-meter-text .num {
        display: inline-block;
        float: left;
        font-weight: 500;
        line-height: 26px;
        font-size: 24px;
        text-align: right;
        letter-spacing: 0.2px;
        width: 20%;
        color: #002E5D; }

.formWrap_done {
  height: 100vh;
  min-height: 500px;
  position: relative; }
  .formWrap_done .headerForm .header_title .header_link .header_logo path {
    fill: #fff; }
  .formWrap_done .progress .progress-meter .progress-meter-text .info,
  .formWrap_done .progress .progress-meter .progress-meter-text .num {
    color: #fff; }

.showClass {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  background-color: #02B7A4;
  z-index: 1000; }
  .showClass.red {
    display: block; }

.employee_form {
  min-height: calc(100vh - 91px);
  display: flex;
  align-items: center;
  justify-content: center; }
  .employee_form form {
    min-height: calc(100vh - 91px);
    width: 100%; }
    .employee_form form .fieldGroup {
      float: left;
      width: 100%;
      min-height: 78vh;
      display: flex;
      align-items: center;
      justify-content: center; }
      .employee_form form .fieldGroup .fieldGroup_wrapper {
        width: 100%;
        float: left; }
      .employee_form form .fieldGroup .fieldGroup_title {
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        font-size: 28px;
        text-align: center;
        letter-spacing: -0.2px;
        margin-bottom: 50px; }
      .employee_form form .fieldGroup .fieldGroup_info {
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-size: 16px;
        text-align: center;
        color: #02B7A4;
        min-height: 19px;
        margin-bottom: 60px; }
        .employee_form form .fieldGroup .fieldGroup_info.fieldGroup_info__hide {
          opacity: 0;
          pointer-events: none; }
      .employee_form form .fieldGroup .fieldGroup_info__item {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: center; }
        .employee_form form .fieldGroup .fieldGroup_info__item .fieldGroup_info__itemLabel {
          position: absolute;
          left: 0;
          width: calc(50% - 200px);
          text-align: right;
          padding-right: 20px;
          font-style: normal;
          font-weight: 500;
          top: 15px;
          line-height: 1.2em;
          font-size: 16px;
          text-align: right;
          color: #002E5D; }
        .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup {
          width: 400px; }
          .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup input, .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup textarea {
            width: 100%;
            margin: 0;
            border: 1px solid #E4E4E4;
            box-sizing: border-box;
            border-radius: 4px;
            padding: auto 15px;
            box-shadow: none;
            height: 50px; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup input.validInput, .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup textarea.validInput {
              border: 2px solid #02B7A4; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup input.smallInput, .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup textarea.smallInput {
              margin: 0;
              max-width: 100%;
              margin-right: 20px;
              float: left;
              width: 120px; }
              .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup input.smallInput:last-child, .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup textarea.smallInput:last-child {
                margin-right: 0; }
          .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup textarea {
            height: 100px;
            resize: none; }
          .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup label {
            width: 100%;
            float: left;
            margin-bottom: 20px; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup label input[type="radio"] {
              float: left;
              width: auto;
              height: auto; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup label span {
              float: left;
              line-height: 1em;
              margin-left: 10px;
              font-size: 16px; }
          .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup {
            padding-top: 16px; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup label {
              position: relative;
              padding-left: 30px;
              color: #002E5D;
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 30px; }
              .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup label .smallLabel {
                width: 80%;
                float: right;
                margin-top: -13px; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup .radioButton {
              float: left;
              width: auto;
              height: auto;
              display: block;
              width: 1px;
              height: 1px;
              overflow: hidden;
              opacity: 0; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup .radioEnable {
              float: right;
              width: 80%;
              margin-top: -13px; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup .inputCircle {
              position: absolute;
              width: 20px;
              height: 20px;
              display: block;
              background-color: #fff;
              border: 2px solid #868686;
              border-radius: 20px;
              left: 0;
              margin: 0;
              cursor: pointer; }
              .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup .inputCircle::after {
                display: block;
                width: 10px;
                height: 10px;
                background-color: transparent;
                content: '';
                position: relative;
                left: 3px;
                top: 3px;
                border-radius: 10px; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup .radioButton:hover ~ .inputCircle::after {
              background-color: #868686; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup .radioButton:checked ~ .inputCircle {
              border-color: #39A9DC; }
              .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup .radioButton:checked ~ .inputCircle::after {
                background-color: #39A9DC; }
          .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable {
            padding-top: 16px; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable label {
              position: relative;
              padding-left: 30px;
              color: #002E5D;
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 30px; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioButton {
              float: left;
              width: auto;
              height: auto;
              display: block;
              width: 1px;
              height: 1px;
              overflow: hidden;
              opacity: 0; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioEnable {
              float: right;
              width: 80%;
              margin-top: -13px; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .inputCircle {
              position: absolute;
              width: 20px;
              height: 20px;
              display: block;
              background-color: #fff;
              border: 2px solid #868686;
              border-radius: 20px;
              left: 0;
              margin: 0;
              cursor: pointer; }
              .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .inputCircle::after {
                display: block;
                width: 10px;
                height: 10px;
                background-color: transparent;
                content: '';
                position: relative;
                left: 3px;
                top: 3px;
                border-radius: 10px; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioButton:hover ~ .inputCircle::after {
              background-color: #868686; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioButton:checked ~ .inputCircle {
              border-color: #39A9DC; }
              .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioButton:checked ~ .inputCircle::after {
                background-color: #39A9DC; }
          .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable {
            width: 100%; }
            .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row {
              display: flex;
              flex-wrap: wrap; }
              .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row .top {
                width: 100%;
                float: left;
                display: flex;
                flex-wrap: wrap; }
                .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row .top .item {
                  display: block;
                  width: 16.666%;
                  float: left;
                  border-right: 1px solid #C4C4C4;
                  text-align: center; }
              .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row .languageInfo {
                float: left;
                border-right: 1px solid #C4C4C4;
                width: 16.666%;
                padding: 30px 0;
                text-align: right;
                padding-right: 20px; }
              .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row label {
                float: left;
                width: 16.666%;
                text-align: center;
                margin: 0;
                padding: 30px 0;
                border-right: 1px solid #C4C4C4; }
                .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row label .inputCircle {
                  display: inline-block;
                  float: none;
                  position: relative; }
                .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row label .text {
                  display: none; }
        .employee_form form .fieldGroup .fieldGroup_info__item .form-error {
          display: none;
          position: absolute;
          right: 0;
          width: calc(50% - 200px);
          font-weight: 500;
          line-height: 1.2em;
          font-size: 16px;
          padding: 0;
          margin: 0;
          color: #EF4253;
          padding-left: 20px; }
        .employee_form form .fieldGroup .fieldGroup_info__item.itemMax {
          width: 100%;
          float: left;
          display: block;
          margin-top: 40px; }
          .employee_form form .fieldGroup .fieldGroup_info__item.itemMax label {
            width: 16.666%;
            float: left;
            position: relative;
            top: 3px; }
            .employee_form form .fieldGroup .fieldGroup_info__item.itemMax label span {
              display: block;
              width: 100%;
              font-size: 14px; }
          .employee_form form .fieldGroup .fieldGroup_info__item.itemMax .inputGroup {
            float: left;
            max-width: 300px; }
            .employee_form form .fieldGroup .fieldGroup_info__item.itemMax .inputGroup input {
              background: #FAFAFA;
              border: 1px solid #E4E4E4; }
        .employee_form form .fieldGroup .fieldGroup_info__item.fieldGroup_info__itemError .errorInput {
          border: 2px solid #EF4253;
          background-color: #fff; }
        .employee_form form .fieldGroup .fieldGroup_info__item.fieldGroup_info__itemError .validInput {
          border: 2px solid #02B7A4;
          background-color: #fff; }
        .employee_form form .fieldGroup .fieldGroup_info__item.fieldGroup_info__itemError .form-error {
          display: block; }
    .employee_form form .input_fileGroup {
      position: relative;
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: 100px; }
      .employee_form form .input_fileGroup .input_file {
        width: 0px;
        height: 0px;
        overflow: hidden;
        left: -10000000px;
        top: -1000000px; }
      .employee_form form .input_fileGroup .is-invalid-input {
        border: none !important; }
      .employee_form form .input_fileGroup .is-invalid-input + .input_file__body {
        border: 1px solid #EF4253;
        color: #EF4253; }
      .employee_form form .input_fileGroup .validInput + .input_file__body {
        background-color: #39A9DC;
        color: #fff; }
      .employee_form form .input_fileGroup .input_file__body {
        min-width: 220px;
        background-color: #fff;
        border: 1px solid #39A9DC;
        color: #39A9DC;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        text-align: center;
        position: relative;
        padding: 15px 10px;
        cursor: pointer;
        font-size: 16px;
        display: inline-block;
        margin: 0;
        overflow: hidden; }
        .employee_form form .input_fileGroup .input_file__body span {
          position: relative;
          z-index: 1; }
        .employee_form form .input_fileGroup .input_file__body::after {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 140%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #39A9DC;
          content: ''; }
        .employee_form form .input_fileGroup .input_file__body::before {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 100%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #39A9DC;
          opacity: .4;
          content: ''; }
        .employee_form form .input_fileGroup .input_file__body:hover::after {
          top: -100%; }
        .employee_form form .input_fileGroup .input_file__body:hover::before {
          top: -120%; }
        .employee_form form .input_fileGroup .input_file__body:hover span {
          color: #fff; }
    .employee_form form .inputGroup_checkbox {
      position: relative;
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: 100px;
      margin-top: -20px; }
      .employee_form form .inputGroup_checkbox .input_checkbox {
        width: 0;
        height: 0;
        position: absolute;
        left: -1000px;
        height: -10000px;
        overflow: hidden;
        opacity: 0; }
        .employee_form form .inputGroup_checkbox .input_checkbox:checked ~ .inputStyle {
          background-color: #39A9DC;
          position: relative; }
          .employee_form form .inputGroup_checkbox .input_checkbox:checked ~ .inputStyle::after {
            position: absolute;
            left: 9px;
            top: 1px;
            content: '';
            width: 10px;
            height: 20px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg); }
      .employee_form form .inputGroup_checkbox .is-invalid-input + .inputStyle {
        border: 1px solid #EF4253;
        color: #EF4253; }
      .employee_form form .inputGroup_checkbox .inputStyle {
        width: 29px;
        height: 29px;
        background: #FAFAFA;
        border: 1px solid #E4E4E4;
        display: inline-block;
        margin-bottom: 20px; }
      .employee_form form .inputGroup_checkbox p {
        font-weight: 500;
        line-height: 1.5em;
        font-size: 16px;
        text-align: center;
        color: #002E5D; }
      .employee_form form .inputGroup_checkbox a {
        font-weight: 500;
        line-height: normal;
        font-size: 16px;
        text-align: center;
        color: #39A9DC; }
    .employee_form form .submit, .employee_form form .goToMainPage {
      text-align: center;
      float: left;
      width: 100%;
      position: relative;
      bottom: 0px;
      margin-top: 20px;
      z-index: 1;
      left: 0; }
      .employee_form form .submit .button, .employee_form form .goToMainPage .button {
        min-width: 220px;
        background-color: #39A9DC;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        text-align: center;
        position: relative;
        padding: 15px 10px;
        color: #fff;
        font-size: 16px;
        display: inline-block;
        overflow: hidden; }
        .employee_form form .submit .button span, .employee_form form .goToMainPage .button span {
          position: relative;
          z-index: 1; }
        .employee_form form .submit .button::after, .employee_form form .goToMainPage .button::after {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 140%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #002E5D;
          content: ''; }
        .employee_form form .submit .button::before, .employee_form form .goToMainPage .button::before {
          transition-duration: .3s;
          position: absolute;
          display: block;
          height: 1000%;
          left: -100px;
          right: -100px;
          top: 100%;
          bottom: 0;
          border-radius: 1000px;
          background-color: #002E5D;
          opacity: .4;
          content: ''; }
        .employee_form form .submit .button:hover::after, .employee_form form .goToMainPage .button:hover::after {
          top: -100%; }
        .employee_form form .submit .button:hover::before, .employee_form form .goToMainPage .button:hover::before {
          top: -120%; }
      .employee_form form .submit .buttonHide, .employee_form form .goToMainPage .buttonHide {
        display: none; }

.slider {
  background-color: #84D0F0;
  border-radius: 10px;
  height: 10px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 6px; }
  .slider .sliderLines {
    width: 100%;
    position: absolute;
    top: 0;
    line-height: 0px; }
    .slider .sliderLines span {
      display: block;
      float: left;
      border-right: 1px solid #39A9DC;
      width: 25%;
      height: 10px; }
  .slider .slider-fill {
    background-color: #39A9DC;
    border-radius: 10px 0 0 10px;
    height: 10px; }
  .slider .slider-handle {
    background-color: red;
    background-image: url("../img/icons/sliderRange.svg");
    width: 23px;
    height: 37px;
    background-color: transparent;
    margin-top: 5px; }

.sliderInfo {
  width: 100%; }
  .sliderInfo .item {
    width: 20%;
    float: left;
    text-align: center; }

.formWrap_done.formWrap_done__employee {
  background-image: url(../img/bg_form/1.svg);
  background-size: auto 360px;
  background-repeat: no-repeat;
  position: relative;
  background-position: left -100px center; }
  .formWrap_done.formWrap_done__employee::before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: url(../img/bg_form/2.svg);
    content: '';
    background-size: auto 360px;
    background-repeat: no-repeat;
    background-position: right -100px center; }

.formWrap_done .endGroup {
  position: relative;
  z-index: 1;
  max-width: 686px;
  margin: 0 auto; }
  .formWrap_done .endGroup .fieldGroup_title {
    color: #fff;
    text-align: center;
    width: 100%;
    float: left;
    font-weight: bold;
    line-height: normal;
    font-size: 28px;
    text-align: center;
    margin-bottom: 190px; }
  .formWrap_done .endGroup .moreInfo {
    font-style: normal;
    font-weight: normal;
    line-height: 38px;
    font-size: 28px;
    text-align: center;
    color: #fff;
    max-width: 686px;
    margin: 0 auto;
    margin-top: 100px; }

.is-invalid-input:not(:focus) {
  background-color: #fff;
  border: 2px solid #EF4253 !important; }

.opt {
  display: block;
  width: 100%;
  font-size: 14px;
  opacity: .4; }

.hideItems {
  display: none; }

.fieldGroup_info__itemCheckbox {
  width: 400px;
  margin: 0 auto;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */ }
  .fieldGroup_info__itemCheckbox .checkboxGroup {
    display: block;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    float: left; }
    .fieldGroup_info__itemCheckbox .checkboxGroup span {
      font-size: 16px;
      color: #0C2B83;
      float: left;
      line-height: 1em; }
  .fieldGroup_info__itemCheckbox .checkboxGroup input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0px;
    height: 0px;
    left: -1000px;
    top: -1000px;
    overflow: hidden; }
  .fieldGroup_info__itemCheckbox .checkmark {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    float: left;
    border: 2px solid #DADADA;
    position: relative;
    margin-right: 10px;
    float: left; }
  .fieldGroup_info__itemCheckbox .checkboxGroup:hover input ~ .checkmark {
    background-color: #ccc; }
  .fieldGroup_info__itemCheckbox .checkboxGroup input:checked ~ .checkmark {
    background-color: #39A9DC;
    border-color: #39A9DC; }
  .fieldGroup_info__itemCheckbox .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .fieldGroup_info__itemCheckbox .checkboxGroup input:checked ~ .checkmark:after {
    display: block; }
  .fieldGroup_info__itemCheckbox .checkboxGroup .checkmark:after {
    left: 4px;
    top: 0px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .fieldGroup_info__itemCheckbox .checkboxGroupSmall {
    float: left;
    width: auto; }
  .fieldGroup_info__itemCheckbox .smallLabel {
    float: right;
    width: 80%;
    margin: 0;
    margin-top: -15px;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: auto 15px;
    box-shadow: none;
    height: 50px; }

@media (max-width: 1200px) {
  .formWrap_done.formWrap_done__employee {
    background-image: none; }
  .formWrap_done.formWrap_done__employee::before {
    display: none; } }

@media (max-width: 767px) {
  .headerForm {
    padding: 0; }
  .headerForm .header_title {
    display: none; }
  .headerForm .progressG {
    width: 100%;
    padding: 0; }
  .headerForm .progress {
    width: 100%;
    margin-top: 0;
    margin-left: 0; }
  .employee_form form .submit, .employee_form form .goToMainPage {
    position: relative;
    bottom: 0;
    margin-top: 30px; }
    .employee_form form .submit .button, .employee_form form .goToMainPage .button {
      width: 100%; }
  .headerForm .progress .progress-meter-text {
    padding: 0 20px; }
  .employee_form form .fieldGroup .fieldGroup_info__item .fieldGroup_info__itemLabel {
    position: relative;
    width: 100%;
    float: left;
    top: 0;
    text-align: left;
    padding-right: 0; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup {
    width: 100%; }
  .employee_form form .fieldGroup .fieldGroup_info__item {
    display: block; }
  .employee_form form .fieldGroup .fieldGroup_title {
    margin-bottom: 20px;
    margin-top: 50px; }
  .employee_form form .fieldGroup .fieldGroup_info {
    margin-bottom: 30px; }
  .employee_form form .fieldGroup .fieldGroup_info.fieldGroup_info__hide {
    height: 0px;
    min-height: 0; }
  .employee_form form .fieldGroup .fieldGroup_info__item {
    float: left;
    width: 100%; }
  .employee_form form .fieldGroup .fieldGroup_info__item .fieldGroup_info__itemLabel {
    margin-bottom: 10px; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup input.smallInput {
    margin: 0;
    margin-right: 0px;
    max-width: 100%;
    margin-right: 20px;
    float: left;
    width: calc(33% - 13px); }
  .employee_form form .fieldGroup .fieldGroup_info__item .form-error {
    position: relative;
    width: 100%;
    left: 0;
    margin: 0;
    float: left;
    padding: 0;
    font-size: 12px;
    margin-top: 5px; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup label {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0; }
  .employee_form form .fieldGroup .fieldGroup_info__itemRadio .fieldGroup_info__itemLabel {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0; }
  .fieldGroup_info__itemRadio {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup .inputCircle {
    position: relative;
    margin-right: 10px; }
  .employee_form form .fieldGroup_info__itemMobileStyle .fieldGroup_info__itemLabel {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0; }
  .employee_form form .fieldGroup_info__itemMobileStyle {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px; }
    .employee_form form .fieldGroup_info__itemMobileStyle .inputGroup {
      padding: 0 20px; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup {
    float: left; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup {
    padding-top: 0; }
  .sliderInfo .item {
    font-size: 14px; }
  .sliderInfo {
    float: left;
    width: 100%;
    margin-bottom: 40px; }
  .slider {
    background-color: #84D0F0;
    border-radius: 10px;
    height: 10px;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 6px;
    margin-bottom: 22px;
    margin-top: 40px; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row {
    display: block;
    width: 100%;
    float: left; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row .languageInfo {
    width: 100%;
    border: none;
    padding: 0;
    text-align: left;
    padding: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0;
    margin: 0; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row .top {
    display: none; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row label {
    border: none;
    padding: 0;
    text-align: left;
    border-bottom: 1px solid #C4C4C4;
    width: 100%;
    padding: 20px; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row label .inputCircle {
    float: left; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row label .text {
    display: inline-block; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioTable .radioTable_row {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    margin-bottom: 30px; }
  .employee_form form .fieldGroup .fieldGroup_info__item.itemMax {
    margin-top: 0; }
  .employee_form form .fieldGroup .fieldGroup_info__item.itemMax label {
    width: 100%;
    margin-top: 0; }
  .employee_form form .fieldGroup .fieldGroup_info__item.itemMax .inputGroup {
    width: 100%; }
  .employee_form form .input_fileGroup .input_file__body {
    margin-top: 20px; }
  .employee_form form .inputGroup_checkbox .inputStyle {
    margin-top: 20px; }
  .employee_form form .inputGroup_checkbox p, .employee_form form .inputGroup_checkbox a {
    font-size: 14px; }
  .employee_form form .inputGroup_checkbox {
    margin-bottom: 20px; }
  .employee_form form .input_fileGroup {
    margin-bottom: 50px; }
  .progress-meter-text {
    white-space: normal; }
  .employee_form form .fieldGroup .fieldGroup_info__item .inputGroup.radioGroup label .smallLabel {
    width: 100%;
    margin-top: 20px;
    float: left; }
  .employee_form {
    display: block; }
  .headerForm .progressG {
    height: 80px; }
  .fieldGroup_info__itemCheckbox {
    width: 100%;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12); }
  .fieldGroup_info__itemCheckbox .checkboxGroup {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0; }
  .fieldGroup_info__itemCheckbox .smallLabel {
    float: left;
    width: calc(100% - 40px);
    margin: 20px;
    margin-top: 20px;
    margin-top: 0;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: auto 15px;
    box-shadow: none;
    height: 50px; }
  .fieldGroup_info__itemCheckbox .checkboxGroupSmall {
    width: 100%;
    border: none; }
  .formWrap_done.formWrap_done__employee {
    background-image: none; }
  .formWrap_done .endGroup .fieldGroup_title {
    margin-bottom: 20px; }
  .formWrap_done .endGroup .moreInfo {
    padding: 0 20px;
    font-size: 18px;
    opacity: 1; }
  .formWrap_done.formWrap_done__employee::before {
    display: none; } }

.mainPageButton {
  text-align: center;
  float: left;
  width: 100%;
  position: absolute;
  bottom: 50px;
  z-index: 1;
  left: 0; }
  .mainPageButton .button {
    min-width: 220px;
    background-color: #39A9DC;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    text-align: center;
    position: relative;
    padding: 15px 10px;
    color: #fff;
    font-size: 16px;
    display: inline-block;
    overflow: hidden; }
    .mainPageButton .button span {
      position: relative;
      z-index: 1; }
    .mainPageButton .button::after {
      transition-duration: .3s;
      position: absolute;
      display: block;
      height: 1000%;
      left: -100px;
      right: -100px;
      top: 140%;
      bottom: 0;
      border-radius: 1000px;
      background-color: #002E5D;
      content: ''; }
    .mainPageButton .button::before {
      transition-duration: .3s;
      position: absolute;
      display: block;
      height: 1000%;
      left: -100px;
      right: -100px;
      top: 100%;
      bottom: 0;
      border-radius: 1000px;
      background-color: #002E5D;
      opacity: .4;
      content: ''; }
    .mainPageButton .button:hover::after {
      top: -100%; }
    .mainPageButton .button:hover::before {
      top: -120%; }
  .mainPageButton .buttonHide {
    display: none; }

.forms_modal {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0;
  bottom: 0;
  z-index: 10000;
  padding: 60px 0;
  display: none; }
  .forms_modal.openModal2 {
    display: flex; }
  .forms_modal .forms_modal__bg {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: .2;
    background-color: #000000; }
  .forms_modal .forms_modal__box {
    background-color: #fff;
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: 4px;
    z-index: 1; }
    .forms_modal .forms_modal__box .top {
      width: 100%;
      line-height: 1em;
      padding: 13px;
      float: left;
      border-bottom: 1px solid #EEEEEE; }
      .forms_modal .forms_modal__box .top a {
        float: right;
        line-height: 1em; }
    .forms_modal .forms_modal__box .content {
      padding: 30px 85px;
      height: calc(100% - 44.5px);
      overflow: auto; }
      .forms_modal .forms_modal__box .content .title {
        font-weight: bold;
        line-height: 38px;
        font-size: 28px;
        text-align: center;
        margin-bottom: 45px; }
      .forms_modal .forms_modal__box .content p {
        font-size: 16px;
        line-height: 1.5em; }
        .forms_modal .forms_modal__box .content p strong {
          font-weight: 500;
          line-height: 26px;
          font-size: 24px;
          text-align: center;
          letter-spacing: 0.2px;
          display: block;
          width: 100%;
          margin-bottom: 15px; }

@media (max-width: 767px) {
  .forms_modal .forms_modal__box .content {
    padding: 30px; } }

.blog_section {
  background-color: #FAFAFA;
  float: left;
  width: 100%; }
  .blog_section .blog_section__header {
    padding: 45px 0;
    float: left;
    width: 100%;
    display: flex;
    align-items: center; }
    .blog_section .blog_section__header .title {
      float: left;
      text-transform: uppercase;
      color: #002E5D;
      font-size: 20px;
      letter-spacing: 0.4em;
      font-weight: 600; }
    .blog_section .blog_section__header .categoryShow {
      margin-left: auto; }
      .blog_section .blog_section__header .categoryShow ul {
        float: left;
        list-style: none;
        padding: 0;
        margin: 0; }
        .blog_section .blog_section__header .categoryShow ul li {
          float: left;
          font-size: 11px;
          font-weight: 500;
          position: relative;
          width: auto;
          overflow: hidden;
          margin-left: 15px;
          border: 1px solid rgba(21, 21, 21, 0.15);
          border-radius: 40px; }
          .blog_section .blog_section__header .categoryShow ul li .bg {
            position: absolute;
            top: 100%;
            bottom: 0;
            right: 0;
            left: 0;
            transition-duration: .2s; }
          .blog_section .blog_section__header .categoryShow ul li a {
            display: block;
            padding: 7px 15px;
            float: left;
            position: relative;
            z-index: 1;
            text-transform: uppercase; }
          .blog_section .blog_section__header .categoryShow ul li:hover {
            border: 1px solid transparent;
            transition-delay: .2s; }
            .blog_section .blog_section__header .categoryShow ul li:hover .bg {
              top: 0;
              background-color: #000; }
            .blog_section .blog_section__header .categoryShow ul li:hover a {
              color: #fff !important; }
  .blog_section .blog_section__content {
    max-width: 700px;
    margin: 0 auto; }
    .blog_section .blog_section__content .blog_section__article {
      padding: 30px;
      background-color: #fff;
      margin-bottom: 30px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      float: left;
      width: 100%; }
      .blog_section .blog_section__content .blog_section__article .article__top {
        float: left;
        width: 100%; }
        .blog_section .blog_section__content .blog_section__article .article__top .data {
          float: left;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          font-size: 12px;
          color: #002E5D;
          letter-spacing: 0.7px;
          text-transform: uppercase; }
        .blog_section .blog_section__content .blog_section__article .article__top .category {
          float: left;
          font-style: normal;
          font-weight: 500;
          line-height: 11px;
          font-size: 12px;
          text-transform: uppercase;
          display: flex;
          align-items: center; }
          .blog_section .blog_section__content .blog_section__article .article__top .category a {
            float: left;
            margin-right: 10px;
            color: #002E5D;
            display: inline-block; }
          .blog_section .blog_section__content .blog_section__article .article__top .category::before {
            content: '';
            width: 2px;
            height: 2px;
            background-color: #002E5D;
            float: left;
            margin: 0 12px;
            border-radius: 10px; }
      .blog_section .blog_section__content .blog_section__article .article_title {
        padding: 30px 0;
        float: left;
        width: 100%; }
        .blog_section .blog_section__content .blog_section__article .article_title h2 {
          line-height: 1em; }
          .blog_section .blog_section__content .blog_section__article .article_title h2 a {
            font-style: normal;
            font-weight: 500;
            line-height: 1em;
            font-size: 28px;
            letter-spacing: 0.2px;
            color: #002E5D; }
      .blog_section .blog_section__content .blog_section__article .article_image {
        height: 200px;
        float: left;
        margin-bottom: 30px;
        width: 100%;
        background-position: center;
        background-size: cover; }
      .blog_section .blog_section__content .blog_section__article .article_content {
        margin-bottom: 35px;
        float: left;
        width: 100%; }
        .blog_section .blog_section__content .blog_section__article .article_content p {
          margin: 0;
          padding: 0;
          line-height: 1.5em; }
      .blog_section .blog_section__content .blog_section__article .article_more {
        font-weight: 500;
        line-height: 25px;
        font-size: 16px;
        letter-spacing: 0.2px;
        color: #39A9DC; }
        .blog_section .blog_section__content .blog_section__article .article_more img {
          height: 7px;
          margin-left: 5px; }
    .blog_section .blog_section__content .blog_section__pagination, .blog_section .blog_section__content .blog_section__paginationMobile {
      float: left;
      width: 100%;
      text-align: center;
      display: flex;
      margin-top: 30px;
      margin-bottom: 60px;
      justify-content: center; }
      .blog_section .blog_section__content .blog_section__pagination a, .blog_section .blog_section__content .blog_section__pagination span, .blog_section .blog_section__content .blog_section__paginationMobile a, .blog_section .blog_section__content .blog_section__paginationMobile span {
        border: 1px solid #D0D0D0;
        box-sizing: border-box;
        border-radius: 100px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        color: #39A9DC;
        font-size: 22px;
        font-weight: 500;
        justify-content: center;
        line-height: 1em;
        margin: 0 5px;
        transition-duration: .3s; }
        .blog_section .blog_section__content .blog_section__pagination a li, .blog_section .blog_section__content .blog_section__pagination span li, .blog_section .blog_section__content .blog_section__paginationMobile a li, .blog_section .blog_section__content .blog_section__paginationMobile span li {
          list-style: none;
          display: inline-block; }
        .blog_section .blog_section__content .blog_section__pagination a.prev, .blog_section .blog_section__content .blog_section__pagination span.prev, .blog_section .blog_section__content .blog_section__paginationMobile a.prev, .blog_section .blog_section__content .blog_section__paginationMobile span.prev {
          margin-right: 60px; }
          .blog_section .blog_section__content .blog_section__pagination a.prev img, .blog_section .blog_section__content .blog_section__pagination span.prev img, .blog_section .blog_section__content .blog_section__paginationMobile a.prev img, .blog_section .blog_section__content .blog_section__paginationMobile span.prev img {
            height: 15px; }
        .blog_section .blog_section__content .blog_section__pagination a.next, .blog_section .blog_section__content .blog_section__pagination span.next, .blog_section .blog_section__content .blog_section__paginationMobile a.next, .blog_section .blog_section__content .blog_section__paginationMobile span.next {
          margin-left: 60px; }
          .blog_section .blog_section__content .blog_section__pagination a.next img, .blog_section .blog_section__content .blog_section__pagination span.next img, .blog_section .blog_section__content .blog_section__paginationMobile a.next img, .blog_section .blog_section__content .blog_section__paginationMobile span.next img {
            height: 15px; }
        .blog_section .blog_section__content .blog_section__pagination a:hover, .blog_section .blog_section__content .blog_section__pagination span:hover, .blog_section .blog_section__content .blog_section__paginationMobile a:hover, .blog_section .blog_section__content .blog_section__paginationMobile span:hover {
          background-color: #fff; }
      .blog_section .blog_section__content .blog_section__pagination span, .blog_section .blog_section__content .blog_section__paginationMobile span {
        border: 1px solid #39A9DC; }
    .blog_section .blog_section__content .blog_section__paginationMobile {
      position: relative;
      display: none; }
      .blog_section .blog_section__content .blog_section__paginationMobile span {
        border: none; }
      .blog_section .blog_section__content .blog_section__paginationMobile .left {
        position: absolute;
        left: 0; }
      .blog_section .blog_section__content .blog_section__paginationMobile .right {
        position: absolute;
        right: 0; }
      .blog_section .blog_section__content .blog_section__paginationMobile a img {
        height: 15px; }

@media (max-width: 1024px) {
  .blog_section .blog_section__header .title {
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 13.1618px;
    letter-spacing: 0.4em;
    text-transform: uppercase; }
  .blog_section .grid-container {
    max-width: 640px; } }

@media (max-width: 767px) {
  .blog_section .blog_section__header .categoryShow {
    display: none; }
  .blog_section .blog_section__header .title {
    font-size: 10px; }
  .blog_section .blog_section__content .blog_section__article .article_title h2 a {
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    font-size: 19px;
    letter-spacing: 0.2px; }
  .blog_section .blog_section__content .blog_section__article .article_title h2 {
    margin: 0; }
  .blog_section .blog_section__content .blog_section__pagination {
    display: none; }
  .blog_section .blog_section__content .blog_section__paginationMobile {
    display: flex; } }

.blog_post {
  background-color: #fff;
  float: left;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 90px; }
  .blog_post .blog_post__container {
    max-width: 830px;
    margin: 0 auto; }
  .blog_post .blog_post__top {
    float: left;
    width: 100%; }
    .blog_post .blog_post__top .data {
      float: left;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      font-size: 12px;
      color: #002E5D;
      letter-spacing: 0.7px;
      text-transform: uppercase; }
    .blog_post .blog_post__top .category {
      float: left;
      font-style: normal;
      font-weight: 500;
      line-height: 11px;
      font-size: 12px;
      text-transform: uppercase;
      display: flex;
      align-items: center; }
      .blog_post .blog_post__top .category a {
        float: left;
        margin-right: 10px;
        color: #002E5D;
        display: inline-block; }
      .blog_post .blog_post__top .category::before {
        content: '';
        width: 2px;
        height: 2px;
        background-color: #002E5D;
        float: left;
        margin: 0 12px;
        border-radius: 10px; }
  .blog_post .blog_post__title {
    padding: 30px 0;
    float: left;
    width: 100%;
    padding-bottom: 60px; }
    .blog_post .blog_post__title h2 {
      color: #002E5D;
      font-weight: bold;
      line-height: normal;
      font-size: 46px;
      letter-spacing: -0.2px; }
  .blog_post .blog_post__imageTop {
    margin-bottom: 45px; }
  .blog_post .blog_post__content {
    padding-top: 45px;
    padding-bottom: 60px; }
    .blog_post .blog_post__content p {
      margin: 0 auto;
      margin-bottom: 60px;
      line-height: 33px;
      font-size: 20px;
      letter-spacing: -0.2px; }
    .blog_post .blog_post__content ul, .blog_post .blog_post__content ol {
      margin-bottom: 60px;
      line-height: 33px;
      font-size: 20px;
      letter-spacing: -0.2px; }
    .blog_post .blog_post__content p:empty {
      line-height: 0px; }
    .blog_post .blog_post__content h1, .blog_post .blog_post__content h2, .blog_post .blog_post__content h3, .blog_post .blog_post__content h4, .blog_post .blog_post__content h5, .blog_post .blog_post__content h6 {
      display: block;
      float: left;
      width: 100%; }
    .blog_post .blog_post__content h2 {
      font-style: normal;
      font-weight: normal;
      line-height: 39px;
      font-size: 28px;
      letter-spacing: -0.2px;
      margin-bottom: 60px; }
    .blog_post .blog_post__content h3, .blog_post .blog_post__content h4, .blog_post .blog_post__content h5, .blog_post .blog_post__content h6 {
      margin-bottom: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      font-size: 24px;
      letter-spacing: 0.2px; }
    .blog_post .blog_post__content .alignleft {
      margin-left: -20%;
      float: left;
      margin-right: 60px;
      width: 580px;
      margin-bottom: 36px;
      margin-top: 0px; }
    .blog_post .blog_post__content .alignnone {
      width: 140%;
      max-width: 1200px;
      margin-left: -20%; }
    .blog_post .blog_post__content .blog_post__category {
      padding: 30px 0;
      float: left;
      width: 100%; }
      .blog_post .blog_post__content .blog_post__category ul {
        float: left;
        list-style: none;
        padding: 0;
        margin: 0; }
        .blog_post .blog_post__content .blog_post__category ul li {
          float: left;
          font-size: 11px;
          font-weight: 500;
          position: relative;
          width: auto;
          overflow: hidden;
          margin-right: 15px;
          border: 1px solid rgba(21, 21, 21, 0.15);
          border-radius: 40px; }
          .blog_post .blog_post__content .blog_post__category ul li .bg {
            position: absolute;
            top: 100%;
            bottom: 0;
            right: 0;
            left: 0;
            transition-duration: .2s; }
          .blog_post .blog_post__content .blog_post__category ul li a {
            display: block;
            padding: 7px 15px;
            float: left;
            position: relative;
            z-index: 1;
            text-transform: uppercase; }
          .blog_post .blog_post__content .blog_post__category ul li:hover {
            border: 1px solid transparent;
            transition-delay: .2s; }
            .blog_post .blog_post__content .blog_post__category ul li:hover .bg {
              background-color: #000;
              top: 0; }
            .blog_post .blog_post__content .blog_post__category ul li:hover a {
              color: #fff !important; }
    .blog_post .blog_post__content .blog_post__share {
      padding: 30px 0;
      padding-bottom: 90px;
      float: left;
      width: 100%;
      border-bottom: 1px solid #EEEEEE; }
      .blog_post .blog_post__content .blog_post__share .title {
        line-height: 26px;
        font-size: 20px;
        margin-bottom: 15px;
        float: left;
        width: 100%;
        letter-spacing: 0.2px; }
      .blog_post .blog_post__content .blog_post__share a {
        margin-right: 20px; }
        .blog_post .blog_post__content .blog_post__share a img {
          width: 20px; }
    .blog_post .blog_post__content p + p {
      margin-top: -30px; }
    .blog_post .blog_post__content p + ul {
      margin-top: -30px; }
    .blog_post .blog_post__content .buttonPost {
      min-width: 220px;
      float: left;
      display: block;
      background-color: #39A9DC;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      text-align: center;
      position: relative;
      padding: 15px 10px;
      color: #fff;
      font-size: 16px;
      margin-top: 35px;
      transition-duration: .3s; }
      .blog_post .blog_post__content .buttonPost:hover {
        opacity: .6; }
    .blog_post .blog_post__content .pImg {
      margin-top: 0px;
      margin-bottom: 90px; }
  .blog_post .blog_post__nav {
    float: left;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 90px; }
    .blog_post .blog_post__nav .box {
      padding-right: 15px;
      width: 50%;
      float: left; }
      .blog_post .blog_post__nav .box .title a {
        color: #39A9DC;
        font-weight: 500; }
      .blog_post .blog_post__nav .box .text a {
        color: #002E5D;
        line-height: 24px;
        font-size: 16px; }
      .blog_post .blog_post__nav .box:last-child {
        padding-right: 0px;
        padding-left: 15px;
        text-align: right; }
  .blog_post .comments {
    width: 600px;
    margin: 0 auto; }

@media (max-width: 1440px) {
  .blog_post .blog_post__container {
    max-width: 700px; } }

@media (max-width: 1024px) {
  .blog_post .blog_post__container {
    padding: 0px;
    max-width: 500px; }
  .blog_post .blog_post__content .alignleft {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .blog_post .blog_post__content .alignnone {
    width: 120%;
    margin-left: -10%; }
  .blog_post .grid-container {
    max-width: 640px; }
  .blog_post .comments {
    width: 120%;
    margin-left: -10%; }
  .blog_post .blog_post__title {
    padding-bottom: 45px; }
  .blog_post .blog_post__title h2 {
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 37px;
    letter-spacing: -0.2px; }
  .blog_post .blog_post__content h3 {
    font-style: normal;
    font-weight: normal;
    line-height: 32px;
    font-size: 23px;
    letter-spacing: -0.2px;
    margin-bottom: 45px; }
  .blog_post .blog_post__content p {
    line-height: 30px;
    font-size: 18px;
    letter-spacing: -0.2px;
    margin-bottom: 45px; }
  .blog_post .blog_post__content h4, .blog_post .blog_post__content h5, .blog_post .blog_post__content h6 {
    margin-bottom: 30px;
    font-weight: 500;
    line-height: 26px;
    font-size: 24px;
    letter-spacing: 0.2px; }
  .blog_post .blog_post__content .alignnone {
    margin-bottom: 45px; }
  .blog_post .blog_post__imageTop {
    margin-bottom: 45px; }
  .blog_post .blog_post__content .pImg {
    margin-bottom: 0px;
    float: left;
    margin-bottom: 0px !important;
    margin-top: 45px; }
  .blog_post .blog_post__content .blog_post__category {
    padding: 45px 0; }
  .blog_post .blog_post__content .blog_post__share {
    padding: 0;
    padding-bottom: 70px; }
  .blog_post .blog_post__nav {
    padding-bottom: 70px; }
  .recent_post .title {
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 37px;
    text-align: center;
    letter-spacing: -0.2px; }
  .recent_post .cell {
    margin-bottom: 30px; }
    .recent_post .cell:last-child {
      margin-bottom: 0; } }

@media (max-width: 767px) {
  .blog_post .blog_post__container {
    padding: 0 30px;
    max-width: 500px; }
  .blog_post .blog_post__content .alignleft {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .blog_post .blog_post__content .alignnone {
    width: 100%;
    margin-left: 0%; }
  .blog_post .grid-container {
    max-width: 640px; }
  .blog_post .comments {
    width: 100%;
    margin-left: 0%; }
  .blog_post .blog_post__title {
    padding-bottom: 30px; }
  .blog_post .blog_post__title h2 {
    font-weight: bold;
    line-height: normal;
    font-size: 28px;
    letter-spacing: -0.2px; }
  .blog_post .blog_post__content h3 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 27px;
    font-size: 19px;
    letter-spacing: -0.2px;
    margin-bottom: 30px; }
  .blog_post .blog_post__content p {
    line-height: 26px;
    font-size: 16px;
    letter-spacing: -0.2px;
    margin-bottom: 30px; }
  .blog_post .blog_post__content h4, .blog_post .blog_post__content h5, .blog_post .blog_post__content h6 {
    font-weight: 500;
    line-height: 26px;
    font-size: 24px;
    letter-spacing: 0.2px;
    margin-bottom: 20px; }
  .blog_post .blog_post__content .alignnone {
    margin-bottom: 45px; }
  .blog_post .blog_post__imageTop {
    margin-bottom: 30px; }
  .blog_post .blog_post__content .pImg {
    margin-bottom: 0px;
    float: left;
    margin-bottom: 0px !important;
    margin-top: 45px;
    width: 100%; }
  .blog_post .blog_post__content .blog_post__category {
    padding: 30px 0; }
  .blog_post .blog_post__content .blog_post__share {
    padding: 0;
    padding-bottom: 45px; }
  .blog_post .blog_post__nav {
    padding-bottom: 45px; }
    .blog_post .blog_post__nav .text {
      display: none; }
  .recent_post {
    padding-top: 60px;
    padding-bottom: 60px; }
  .recent_post .title {
    font-weight: bold;
    line-height: normal;
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.2px; }
  .recent_post .cell {
    margin-bottom: 30px; }
    .recent_post .cell:last-child {
      margin-bottom: 0; }
  .blog_post {
    padding-top: 30px;
    padding-bottom: 45px; } }

.recent_post {
  float: left;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 90px;
  background-color: #FAFAFA; }
  .recent_post .title {
    font-weight: bold;
    line-height: normal;
    font-size: 46px;
    text-align: center;
    letter-spacing: -0.2px;
    margin-bottom: 50px; }
  .recent_post .blog_section__article {
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    float: left;
    width: 100%;
    height: 100%; }
    .recent_post .blog_section__article .article__top {
      float: left;
      width: 100%; }
      .recent_post .blog_section__article .article__top .data {
        float: left;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        font-size: 12px;
        color: #002E5D;
        letter-spacing: 0.7px;
        text-transform: uppercase; }
      .recent_post .blog_section__article .article__top .category {
        float: left;
        font-style: normal;
        font-weight: 500;
        line-height: 11px;
        font-size: 12px;
        text-transform: uppercase;
        display: flex;
        align-items: center; }
        .recent_post .blog_section__article .article__top .category a {
          float: left;
          margin-right: 10px;
          color: #002E5D;
          display: inline-block; }
        .recent_post .blog_section__article .article__top .category::before {
          content: '';
          width: 2px;
          height: 2px;
          background-color: #002E5D;
          float: left;
          margin: 0 12px;
          border-radius: 10px; }
    .recent_post .blog_section__article .article_title {
      padding: 30px 0;
      float: left;
      width: 100%; }
      .recent_post .blog_section__article .article_title h2 {
        line-height: 30px; }
        .recent_post .blog_section__article .article_title h2 a {
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          letter-spacing: 0.2px;
          color: #002E5D; }
    .recent_post .blog_section__article .article_image {
      height: 200px;
      float: left;
      margin-bottom: 30px;
      width: 100%;
      background-position: center;
      background-size: cover; }
    .recent_post .blog_section__article .article_content {
      margin-bottom: 35px;
      float: left;
      width: 100%; }
      .recent_post .blog_section__article .article_content p {
        margin: 0;
        padding: 0; }
    .recent_post .blog_section__article .article_more {
      font-weight: 500;
      line-height: 25px;
      font-size: 16px;
      letter-spacing: 0.2px;
      color: #39A9DC; }
      .recent_post .blog_section__article .article_more img {
        height: 7px;
        margin-left: 5px; }

@media (max-width: 1024px) {
  .recent_post .title {
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 37px;
    text-align: center;
    letter-spacing: -0.2px; }
  .recent_post .cell {
    margin-bottom: 30px; }
    .recent_post .cell:last-child {
      margin-bottom: 0; } }

@media (max-width: 767px) {
  .recent_post {
    padding-top: 60px;
    padding-bottom: 60px; }
  .recent_post .title {
    font-weight: bold;
    line-height: normal;
    font-size: 28px;
    text-align: center;
    letter-spacing: -0.2px; }
  .recent_post .cell {
    margin-bottom: 30px; }
    .recent_post .cell:last-child {
      margin-bottom: 0; }
  .blog_post {
    padding-top: 30px;
    padding-bottom: 45px; }
  .recent_post .blog_section__article .article_title h2 a {
    font-weight: 500;
    line-height: 27px;
    font-size: 19px;
    letter-spacing: 0.2px; }
  .recent_post .blog_section__article .article_content p {
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-size: 16px; } }

.blog_newsletter {
  float: left;
  width: 100%;
  background-color: #39A9DC;
  padding-bottom: 140px;
  padding-top: 140px;
  text-align: center;
  color: #fff; }
  .blog_newsletter .blog_newsletter__wrap {
    max-width: 730px;
    margin: 0 auto; }
    .blog_newsletter .blog_newsletter__wrap .header {
      font-weight: bold;
      line-height: 38px;
      font-size: 28px;
      text-align: center;
      margin-bottom: 20px;
      letter-spacing: -0.2px; }
    .blog_newsletter .blog_newsletter__wrap .text {
      float: left;
      width: 100%;
      font-weight: 300;
      line-height: 39px;
      font-size: 28px;
      text-align: center;
      letter-spacing: -0.2px; }
    .blog_newsletter .blog_newsletter__wrap .form div {
      margin-top: 30px;
      width: 100%; }
      .blog_newsletter .blog_newsletter__wrap .form div #fm_form_1 {
        background-color: transparent !important; }
      .blog_newsletter .blog_newsletter__wrap .form div .field {
        width: 100% !important; }
      .blog_newsletter .blog_newsletter__wrap .form div .label {
        display: none !important; }
    .blog_newsletter .blog_newsletter__wrap .form .message_error {
      width: 100% !important;
      display: block;
      float: left;
      margin-bottom: 10px; }
    .blog_newsletter .blog_newsletter__wrap .form form {
      float: left;
      width: 100%;
      margin: 0px 0;
      padding: 0 60px; }
      .blog_newsletter .blog_newsletter__wrap .form form div {
        float: left;
        width: calc(50% - 20px);
        margin: 0 10px !important; }
        .blog_newsletter .blog_newsletter__wrap .form form div input {
          width: calc(50% - 10px);
          margin-right: 10px;
          float: left;
          background: #FAFAFA;
          border: 1px solid #E4E4E4;
          text-indent: 10px;
          box-sizing: border-box;
          border-radius: 4px;
          height: 50px !important; }
      .blog_newsletter .blog_newsletter__wrap .form form p {
        display: flex;
        align-items: center;
        float: left;
        width: 100%;
        color: #fff !important;
        position: relative;
        line-height: 24px !important;
        font-size: 16px !important;
        margin: 0 !important; }
        .blog_newsletter .blog_newsletter__wrap .form form p input {
          margin: 0;
          padding: 0;
          margin-right: 20px;
          position: relative;
          border: none !important;
          margin-left: 10px;
          cursor: pointer; }
        .blog_newsletter .blog_newsletter__wrap .form form p:hover input ~ .checkmark {
          background-color: #ccc; }
        .blog_newsletter .blog_newsletter__wrap .form form p input:checked ~ .checkmark {
          background-color: #000; }
        .blog_newsletter .blog_newsletter__wrap .form form p .checkmark:after {
          content: "";
          position: absolute;
          display: none; }
        .blog_newsletter .blog_newsletter__wrap .form form p .textCheck {
          text-align: left;
          font-style: normal;
          font-weight: normal;
          line-height: 24px;
          font-size: 16px;
          color: #fff;
          width: calc(100% - 40px);
          margin-left: auto; }
      .blog_newsletter .blog_newsletter__wrap .form form button {
        min-width: 180px;
        height: 50px !important;
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        background-color: #002E5D !important;
        color: #fff;
        border-radius: 4px;
        margin-top: 30px;
        position: relative;
        cursor: pointer;
        transition-duration: .3s; }
        .blog_newsletter .blog_newsletter__wrap .form form button:hover {
          opacity: .6; }
    .blog_newsletter .blog_newsletter__wrap .more {
      width: 100%;
      float: left;
      font-weight: normal;
      line-height: 24px;
      font-size: 16px;
      text-align: center; }

@media (max-width: 767px) {
  .blog_newsletter {
    padding: 80px 0; }
  .blog_newsletter .blog_newsletter__wrap .header {
    line-height: 27px;
    font-size: 19px;
    text-align: center;
    letter-spacing: -0.2px; }
  .blog_newsletter .blog_newsletter__wrap .text {
    line-height: 27px;
    font-size: 19px;
    text-align: center;
    letter-spacing: -0.2px; }
  .blog_newsletter .blog_newsletter__wrap form {
    padding: 0; }
  .blog_newsletter .blog_newsletter__wrap form .input input {
    width: 100%;
    margin: 0;
    margin-bottom: 10px; }
  .blog_newsletter .blog_newsletter__wrap form .check {
    flex-wrap: wrap; }
  .blog_newsletter .blog_newsletter__wrap form button {
    width: 100%; }
  .blog_newsletter .blog_newsletter__wrap .form form {
    padding: 0 !important; }
  .blog_newsletter .blog_newsletter__wrap .form form div {
    width: 100%; } }

.imageFullWidth {
  padding: 60px 0;
  float: left;
  width: 100%;
  padding-top: 30px; }

.image_mobile {
  display: none; }

.troopsModule .troopsModule_wrap .troopsModule_arrows .troopsModule_arrows__right {
  display: none; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  display: none; }

.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item {
  text-align: center; }

.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content a::after {
  text-align: center;
  display: inline-block; }

.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content a {
  float: none; }

.troopsModule .troopsModule_wrap .swiper-container .troopsModule_slider__item .troopsModule_slider__content a {
  float: none;
  display: block;
  line-height: 1em; }

@media (min-width: 1320px) {
  .grid-container.grid-container--max {
    max-width: 1680px;
    padding-right: 60px;
    padding-left: 60px; } }

@media (max-width: 1024px) {
  .imageFullWidth {
    padding: 60px 0;
    padding-bottom: 35px;
    float: left;
    width: 100%;
    padding-top: 0px; } }

@media (max-width: 767px) {
  .grid-container {
    padding: 0 20px; }
  .image_mobile {
    display: block; }
  .image_desktop {
    display: none; } }

.hideCookie {
  display: none !important; }
