#formWorkers{
	float: left;
	width: 100%;
}



.headerForm{
	padding: 20px 25px;
	padding-bottom: 40px;
	.header_title{
		margin: 0; padding: 0;
		line-height: 0em;
		float: left;
		font-size: 1px;
		.header_link{
			line-height: 1em;
			.header_logo{
				width: 156px;
			}
		}
	}

	.progressG{
		display: flex;
		justify-content: center;
	}
	.progress{
		float: left;
		width: calc(100% - 156px);
		margin-left: -78px;
		height: 6px;
		outline: none;
		background-color: #283890;
		margin-top: 9px;
		white-space: wrap;
		position: relative;
			position: initial;
			.progress-meter-text{
				width: 100%;
				top: calc(100% + 10px);
				// background-color:  red;
				transform: none;
				position: relative;
				left: 0;

				.info{
					display: inline-block;
					float: left;
					font-style: normal;
					font-weight: 500;
					line-height: 26px;
					font-size: 16px;
					letter-spacing: 0.2px;
					top: -8px;
					color: #39A9DC;
					width: 80%;
					white-space: normal;
				}
				.num{
					display: inline-block;
					float: left;
					font-weight: 500;
					line-height: 26px;
					font-size: 24px;
					text-align: right;
					letter-spacing: 0.2px;
					width: 20%;
					color: #002E5D;
				}

			}
	}
}


.formWrap_done{
	height: 100vh;
	min-height: 500px;
	position: relative;
	.headerForm{
		.header_title .header_link .header_logo path{
			fill: #fff;
		}
	}
	.progress .progress-meter .progress-meter-text .info,
	.progress .progress-meter .progress-meter-text .num{
		color: #fff;
	}
}
