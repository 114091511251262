
.imageTextBox{
	float: left;
	width: 100%;
	padding-top: 75px;
	padding-bottom: 120px;
	.imageTextBox_content{
		padding: 75px 60px;
		margin: 0 auto;
		.imageTextBox_content__image{
			padding-right: 60px;
			img{

			}
		}
		.imageTextBox_content__text{
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.wrap{
				width: 360px;
				margin-left: -30px;
				font-size: 28px;
				line-height: 39px;
				font-weight: $lightFont;
			}
		}
		&:nth-child(2n + 2){
			.grid-x{
				flex-direction: row-reverse;
				.imageTextBox_content__image{
					padding-right: 0;
					padding-left: 60px;
				}
				.imageTextBox_content__text{
					.wrap{
						margin-left: 0;
						margin-right: -30px;
					}
				}
			}
		}
		&:first-child{
			padding-top: 0;
		}
		&:last-child{
			padding-bottom: 0;
		}
	}
}

@media (max-width: 1024px){
	.imageTextBox .imageTextBox_content .imageTextBox_content__image,
	.imageTextBox .imageTextBox_content:nth-child(2n+2) .grid-x .imageTextBox_content__image{
		padding-right: 0px;
		padding-left: 0;
	}
	.imageTextBox .imageTextBox_content .grid-x, .imageTextBox .imageTextBox_content:nth-child(2n+2) .grid-x{
		flex-flow: column-reverse wrap;
	}
	.imageTextBox .imageTextBox_content{
		max-width: 492px;
		padding: 75px 0;
		padding-top: 0px;
	}
	.imageTextBox .imageTextBox_content:nth-child(2n+2) .grid-x .imageTextBox_content__text .wrap{
		margin-right: 0;
	}
	.imageTextBox .imageTextBox_content .imageTextBox_content__text .wrap{
		width: 100%;
		margin-left: 0;
		margin-bottom: 60px;
		p{
			width: 100%;
			margin: 0;
			font-size: 23px;
		}
	}
	.imageTextBox{
		padding-bottom: 75px;
	}

}
@media (max-width: 767px){
	.imageTextBox{
		padding-top: 40px;
	}
	.imageTextBox .imageTextBox_content .imageTextBox_content__text .wrap{
		text-align: center;
		line-height: 27px;
		font-size: 19px;
		margin-bottom: 40px;
		p{
			line-height: 27px;
			font-size: 19px;
		}
	}

	.imageTextBox .imageTextBox_content .imageTextBox_content__text{
		padding: 0 20px;
	}


}