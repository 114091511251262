.new_offers{
      padding-top: 120px;
      padding-bottom: 90px;
      float: left;
      width: 100%;
      background-color: #FAFAFA;


      .read_more{
            float: left;
            width: 100%;
            text-align: center;

            a{
                  min-width: 220px;
                  display: inline-block;
                  background-color: $blue;
                  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
                  border-radius: 3px;
                  text-align: center;
                  position: relative;
                  padding: 15px 10px;
                  color: #fff;
                  font-size: 16px;
                  margin-top: 60px;
                  span{
                        position: relative;
                        z-index: 1;
                  }

                        @include buttonAnimation($darkBlue);

                  }
            }

      .new_offers_tmpl{

		.new_offers_tmpl__header{
			margin-bottom: 60px;
			h2{
				font-style: normal;
				font-weight: bold;
				line-height: normal;
				font-size: 46px;
				text-align: center;
				line-height: 1em;
				color: $darkBlue;
			}
		}
		.new_offers_tmpl__items{
                  @keyframes mymove {
                      0%   {top: 0px;}
                      30%  {top: 3px;}
                      60%  {top: -3px}
                      100% {top: 0px;}
                  }

                  .new_offers_tmpl__item{
                  	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
                  	border-radius: 4px;
                  	padding: 30px;
                  	background-color: #fff;
                  	transition-duration: .3s;

                  	&:hover{
                  		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
                  	}

                  	.new_offers_tmpl__itemContent{
                  		display: block;
                  		float: left;
                  		.category{
                  			width: 100%;
                  			float: left;
                  			margin-bottom: 20px;
                  			.category_item{
                  				background-color: rgba(2, 183, 164, 0.05);
                  				color: $green;
                  				padding: 8px 15px;
                  				border-radius: 20px;
                  				font-size: 10px;
                  				font-weight: 700;
                  				float: left;
                  				letter-spacing: 1px;
                  				text-transform: uppercase;
                  			}
                  		}
                  		.title{
                  			font-style: normal;
                  			font-weight: normal;
                  			line-height: normal;
                  			font-size: 28px;
                  			width: 100%;
                  			margin-bottom: 15px;
                  			float: left;
                  		}
                  		.forWho{
                  			width: 100%;
                  			float: left;
                  			font-weight: bold;
                  			line-height: 12px;
                  			font-size: 12px;
                  			text-transform: uppercase;
                  			margin-bottom: 40px;
                  		}
                  		.date{
                  			width: 50%;
                  			float: left;
                  			font-weight: 500;
                  			line-height: 12px;
                  			font-size: 12px;
                  			display: flex;
                  			align-items: center;
                  			text-transform: uppercase;
                  			img, svg{
                  				margin-right: 10px;
                  			}
                  		}
                  		.place{
                  			width: 50%;
                  			float: left;
                  			font-weight: 500;
                  			line-height: 12px;
                  			font-size: 12px;
                  			display: flex;
                  			align-items: center;
                  			text-transform: uppercase;
                  			img, svg{
                  				margin-right: 10px;
                  			}
                  		}
                  		.content{
                  			margin-top: 25px;
                  			font-style: normal;
                  			font-weight: normal;
                  			float: left;
                  			width: 100%;
                  			p{
                  				line-height: 1.5em;
                  				font-size: 16px;
                  				opacity: .8;
                  				&:last-child{
                  					margin-bottom: 0;
                  				}
                  			}
                  		}
                  	}

                  	.goTo{
                  		margin-top: 25px;
                  		float: left;
                  		width: 100%;
                  		a{
                  			font-size: 16px;
                  			color: $blue;
                  			img,svg{
                  				margin-left: 5px;
                  				position: relative;
                  			}
                  			&:hover{
                  				img,svg{
                  					animation: mymove .8s forwards;
                  				}
                  			}
                  		}
                  	}

                  }
		}
	}
      .rightBox{
            display: none;
            .date{
                  width: 100%;
                  float: left;
                  font-weight: 500;
                  line-height: 12px;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  margin-bottom: 20px;
                  text-transform: uppercase;
                  img, svg{
                        margin-right: 10px;
                  }
            }
            .place{
                  width: 100%;
                  float: left;
                  font-weight: 500;
                  line-height: 12px;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  text-transform: uppercase;
                  img, svg{
                        margin-right: 10px;
                  }
            }
      }


      .category{
            width: 100%;
            float: left;
            margin-bottom: 30px;
            .category_item{
                  background-color: rgba(2, 183, 164, 0.05);
                  color: $green;
                  padding: 8px 15px;
                  border-radius: 20px;
                  font-size: 10px;
                  font-weight: 700;
                  float: left;
                  letter-spacing: 1px;
                  text-transform: uppercase;
            }
      }

}


@media (max-width: 1024px){
      .new_offers .new_offers_tmpl .new_offers_tmpl__header h2{
            font-size: 37px;
      }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .content{
            margin-top: 0;
      }
      .new_offers{
            padding-top: 90px;
      }
      .mobileNone{
            display: none!important;
      }

      .new_offers_tmpl__itemWrap{
            width: calc(100% - 210px);
            float: left;
            margin-right: 30px;
      }
      .new_offers .rightBox{
            display: block;
            width: 180px;
            float: left;
      }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item{
            margin-bottom: 30px;
      }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item:last-child{
            margin-bottom: 0;
      }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .title{
            font-size: 23px;
      }
}

@media (max-width: 767px){
      .mobileNone{
            display: flex!important;
      }
      .new_offers_tmpl__itemWrap{
            width: 100%;

      }
      .new_offers .rightBox{
            display: none;
      }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .content{
            margin-top: 25px;
      }
      .new_offers .new_offers_tmpl .new_offers_tmpl__items .new_offers_tmpl__item .new_offers_tmpl__itemContent .title{
            font-size: 28px;
      }
}
