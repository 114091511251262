.row_2_box{
	float: left;
	width: 100%;

	.cell{
		.item{
			padding: 0 100px 0 140px;
			margin-bottom: 60px;
			.head{
				display: flex;
				align-items: flex-end;
				height: 90px;
				margin-bottom: 30px;
				.title{
					font-weight: bold;
					line-height: 1em;
					font-size: 22px;
					letter-spacing: -0.2px;
					margin: 0;


				}
				.icon{
					float: right;
					margin-left: auto;
					img{
						max-height: 100%;
					}
				}
			}
			.content{
				p{
				font-weight: normal;
				line-height: 1.3em;
				font-size: 18px;
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
				}
			}
		}
		&:nth-child(2n + 2){
			.item{
			padding: 0 140px 0 100px;
			}
		}
	}
}

@media (max-width: 1024px){
	.row_2_box{
		.grid-container{
			max-width: 492px;
		}
	}
	.row_2_box .cell .item,
	.row_2_box .cell:nth-child(2n+2) .item{
		padding: 0;
	}
	.row_2_box .cell .item{
		margin-bottom: 45px;
	}
}
@media (max-width: 767px){
	.row_2_box .cell .item .head .title{
		font-size: 20px;
	}
	.row_2_box .cell .item .content p{
		font-size: 16px;
	}
}