.blog_post{
      background-color: #fff;
      float: left;
      width: 100%;
      padding-top: 60px;
      padding-bottom: 90px;
      .blog_post__container{
            max-width: 830px;
            margin: 0 auto;
      }

      .blog_post__top{
            float: left;
            width: 100%;

            .data{
                  float: left;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 12px;
                  font-size: 12px;
                  color: #002E5D;
                  letter-spacing: 0.7px;
                  text-transform: uppercase;


            }
            .category{
                  float: left;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 11px;
                  font-size: 12px;
                  text-transform: uppercase;
                  display: flex;align-items: center;

                              a{
                                    float: left;
                                    margin-right: 10px;
                                    color: $darkBlue;
                                    display: inline-block;
                              }


                  &::before{
                        content: '';
                        width: 2px; height: 2px;
                        background-color: #002E5D;
                        float: left;
                        margin: 0 12px;
                        border-radius: 10px;
                  }
            }
      }
      .blog_post__title{
            padding: 30px 0;
            float: left;
            width: 100%;
            padding-bottom: 60px;
            h2{

                  color: $darkBlue;
                  font-weight: bold;
                  line-height: normal;
                  font-size: 46px;
                  letter-spacing: -0.2px;
            }
      }

      .blog_post__imageTop{
            margin-bottom: 45px;
      }

      .blog_post__content{
            padding-top: 45px;
            padding-bottom: 60px;
            p{
                  margin: 0 auto;
                  margin-bottom: 60px;
                  line-height: 33px;
                  font-size: 20px;
                  letter-spacing: -0.2px;

            }
            ul, ol{
                  margin-bottom: 60px;
                  line-height: 33px;
                  font-size: 20px;
                  letter-spacing: -0.2px;
            }
            p:empty{
                  line-height: 0px;
            }

            h1,h2,h3,h4,h5,h6{
                  display: block;
                  float: left;
                  width: 100%;
            }
            h2{
                  font-style: normal;
                  font-weight: normal;
                  line-height: 39px;
                  font-size: 28px;
                  letter-spacing: -0.2px;
                  margin-bottom: 60px;
            }
            h3, h4, h5, h6{
                  margin-bottom: 30px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 26px;
                  font-size: 24px;
                  letter-spacing: 0.2px;
            }
            .alignleft{
                  margin-left: -20%;
                  float: left;
                  margin-right: 60px;
                  width: 580px;
                  margin-bottom: 36px;
                  margin-top: 0px;
            }
            .alignnone{
                  width: 140%;
                  max-width: 1200px;
                  margin-left: -20%;
                  // margin-bottom: 60px;
                  // margin-top: 0px;
            }


            .blog_post__category{
                  padding: 30px 0;
                  float: left;
                  width: 100%;
                  ul{
                        float: left;
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        li{
                              float: left;
                              font-size: 11px;
                              font-weight: 500;
                              position: relative;
                              width: auto;
                              overflow: hidden;
                              margin-right: 15px;
                              border: 1px solid rgba(21, 21, 21, 0.15);
                              border-radius: 40px;
                              .bg{
                                    position: absolute;
                                    top: 100%; bottom: 0; right: 0; left: 0;
                                    transition-duration: .2s;

                              }
                              a{
                                    display: block;
                                    padding: 7px 15px;
                                    float: left;
                                    position: relative;
                                    z-index: 1;
                                    text-transform: uppercase;
                              }
                              &:hover{
                                    border: 1px solid transparent;
                                    transition-delay: .2s;
                                    .bg{
                                          background-color: #000;
                                          top: 0;
                                    }
                                    a{
                                          color: #fff!important;
                                    }
                              }
                        }
                  }
            }

            .blog_post__share{
                  padding: 30px 0;
                  padding-bottom: 90px;
                  float: left;
                  width: 100%;
                  border-bottom: 1px solid #EEEEEE;
                  .title{
                        line-height: 26px;
                        font-size: 20px;
                        margin-bottom: 15px;
                        float: left;
                        width: 100%;
                        letter-spacing: 0.2px;
                  }
                  a{
                        margin-right: 20px;
                        img{
                              width: 20px;
                        }
                  }
            }

            p + p{
                  margin-top: -30px;
            }
            p + ul{
                  margin-top: -30px;
            }
            .buttonPost{
                  min-width: 220px;
                  float: left;
                  display: block;
                  background-color: $blue;
                  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
                  border-radius: 3px;
                  text-align: center;
                  position: relative;
                  padding: 15px 10px;
                  color: #fff;
                  font-size: 16px;
                  margin-top: 35px;
                  transition-duration: .3s;
                  &:hover{
                        opacity: .6;
                  }
            }
            .pImg{
                  margin-top: 0px;
                  margin-bottom: 90px;
            }
            // p img{
            //       margin: 30px 0;
            // }
            //
            // p > img{
            //       opacity: .2;
            //       margin: 30px 0;
            //
            //
            // img < p{
            //       opacity: .2;
            //       margin: 30px 0;
            // }



      }

      .blog_post__nav{
            float: left;
            width: 100%;
            padding-top: 30px;
            padding-bottom: 90px;
            .box{
                  padding-right: 15px;
                  width: 50%;
                  float: left;
                  .title{
                        a{
                              color: $blue;
                              font-weight: 500;
                        }
                  }
                  .text{
                        a{
                              color: $darkBlue;
                              line-height: 24px;
                              font-size: 16px;
                        }
                        p{

                        }
                  }
                  &:last-child{
                        padding-right: 0px;
                        padding-left: 15px;
                        text-align: right;
                  }
            }
      }

      .comments{
            width: 600px;
            margin: 0 auto;

      }
}

@media (max-width: 1440px){
      .blog_post .blog_post__container{
            max-width: 700px;
      }
}
@media (max-width: 1024px){
      .blog_section .blog_section__header .title{

      }
      .blog_post .blog_post__container{
            padding: 0px;
            max-width: 500px;
      }
      .blog_post .blog_post__content .alignleft{
            margin-left: 0;
            margin-right: 0;
            width: 100%;
      }
      .blog_post .blog_post__content .alignnone{
            width: 120%;
            margin-left: -10%;
      }
      .blog_post .grid-container{
            max-width: 640px;
      }
      .blog_post .comments{
            width: 120%;
            margin-left: -10%;
      }
      .blog_post .blog_post__title{
            padding-bottom: 45px;
      }
      .blog_post .blog_post__title h2{
            font-style: normal;
            font-weight: bold;
            line-height: normal;
            font-size: 37px;
            letter-spacing: -0.2px;
      }
      .blog_post .blog_post__content h3{
            font-style: normal;
            font-weight: normal;
            line-height: 32px;
            font-size: 23px;
            letter-spacing: -0.2px;
            margin-bottom: 45px;
      }
      .blog_post .blog_post__content p{
            line-height: 30px;
            font-size: 18px;
            letter-spacing: -0.2px;
            margin-bottom: 45px;


      }
      .blog_post .blog_post__content h4, .blog_post .blog_post__content h5, .blog_post .blog_post__content h6{
            margin-bottom: 30px;
            font-weight: 500;
            line-height: 26px;
            font-size: 24px;
            letter-spacing: 0.2px;
      }
      .blog_post .blog_post__content .alignnone{
            margin-bottom: 45px;
      }
      .blog_post .blog_post__imageTop{
            margin-bottom: 45px;
      }
      .blog_post .blog_post__content .pImg{
            margin-bottom: 0px;
            float: left;
            margin-bottom: 0px!important;
            margin-top: 45px;
      }

      .blog_post .blog_post__content .blog_post__category{
            padding: 45px 0;
      }
      .blog_post .blog_post__content .blog_post__share{
            padding: 0;
            padding-bottom: 70px;
      }
      .blog_post .blog_post__nav{
            padding-bottom: 70px;
      }

      .recent_post .title{
            font-style: normal;
            font-weight: bold;
            line-height: normal;
            font-size: 37px;
            text-align: center;
            letter-spacing: -0.2px;
      }
      .recent_post .cell{
            margin-bottom: 30px;
            &:last-child{
                  margin-bottom: 0;
            }
      }
}
@media (max-width: 767px){
      .blog_section .blog_section__header .title{

      }
      .blog_post .blog_post__container{
            padding: 0 30px;
            max-width: 500px;

      }
      .blog_post .blog_post__content .alignleft{
            margin-left: 0;
            margin-right: 0;
            width: 100%;
      }
      .blog_post .blog_post__content .alignnone{
            width: 100%;
            margin-left: 0%;
      }
      .blog_post .grid-container{
            max-width: 640px;
      }
      .blog_post .comments{
            width: 100%;
            margin-left: 0%;
      }
      .blog_post .blog_post__title{
            padding-bottom: 30px;
      }
      .blog_post .blog_post__title h2{
            font-weight: bold;
            line-height: normal;
            font-size: 28px;
            letter-spacing: -0.2px;
      }
      .blog_post .blog_post__content h3{
            font-family: Work Sans;
            font-style: normal;
            font-weight: normal;
            line-height: 27px;
            font-size: 19px;
            letter-spacing: -0.2px;
            margin-bottom: 30px;
      }
      .blog_post .blog_post__content p{
            line-height: 26px;
            font-size: 16px;
            letter-spacing: -0.2px;
            margin-bottom: 30px;


      }
      .blog_post .blog_post__content h4, .blog_post .blog_post__content h5, .blog_post .blog_post__content h6{
            font-weight: 500;
            line-height: 26px;
            font-size: 24px;
            letter-spacing: 0.2px;
            margin-bottom: 20px;
      }
      .blog_post .blog_post__content .alignnone{
            margin-bottom: 45px;
      }
      .blog_post .blog_post__imageTop{
            margin-bottom: 30px;
      }
      .blog_post .blog_post__content .pImg{
            margin-bottom: 0px;
            float: left;
            margin-bottom: 0px!important;
            margin-top: 45px;
            width: 100%;
      }

      .blog_post .blog_post__content .blog_post__category{
            padding: 30px 0;
      }
      .blog_post .blog_post__content .blog_post__share{
            padding: 0;
            padding-bottom: 45px;
      }
      .blog_post .blog_post__nav{
            padding-bottom: 45px;
            .text{
                  display: none;
            }
      }

      .recent_post{
            padding-top: 60px;
            padding-bottom: 60px;
      }
      .recent_post .title{
            font-weight: bold;
            line-height: normal;
            font-size: 28px;
            text-align: center;
            letter-spacing: -0.2px;


      }
      .recent_post .cell{
            margin-bottom: 30px;
            &:last-child{
                  margin-bottom: 0;
            }
      }
      .blog_post{
            padding-top: 30px;
            padding-bottom: 45px;
      }
}
