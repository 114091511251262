.franchiseModule{
	float: left;
	width: 100%;
	padding: 75px 0;
	.franchiseModule_content{
		float: left;width: 100%;
		background-color: #f5d362;
		background-size: 90%;
		background-position: left -100px top -50px; 
		background-repeat: no-repeat;
		color: $darkBlue;
		padding: 161px 60px;
		position: relative;
		overflow: hidden;

		.cell{
			margin-left: auto;
			max-width: 320px;
			position: relative;
			&::before{
				position: absolute;
				bottom: -600px;
				top: -600px;
				background-color: red;
				background: linear-gradient(270deg, #f5d362 56.91%, rgba(252, 203, 0, 0) 100%);
				content: '';
				z-index: 0;
				left: -100px;
				right: -60px;
			}

			.franchiseModule_content__box{
				position: relative;
				z-index: 12;
			
				h2{

					font-size: 39.35px;
					line-height: normal;
					strong{
						font-weight: bold;
					}
				}
				p{
					line-height: 25px;
					font-size: 16px;
					margin-bottom: 30px;
				}
				.franchiseModule_more{
					width: 220px;
					display: block;
					background-color: $blue;
					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
					border-radius: 3px;
					text-align: center;
					position: relative;
					padding: 15px 10px;
					color: #fff;
					@include buttonAnimation($darkBlue); 
					span{
						z-index: 1;
						position: relative;
					}
				}
			}
		}
	}	
}
@media (max-width: 1440px){
	.franchiseModule{
		padding: 60px 0;
	}
	.franchiseModule .franchiseModule_content{
		padding: 76px 60px;
	}
}
@media (max-width: 1024px){
	.franchiseModule{
		padding: 45px 10px;
	}
	.franchiseModule .franchiseModule_content .cell .franchiseModule_content__box,
	.franchiseModule .franchiseModule_content .cell{
		position: initial;
	}
	.franchiseModule .franchiseModule_content .cell{
		max-width: 100%;
	}
	.franchiseModule .franchiseModule_content .cell::before{
		display: none;
	}

	.franchiseModule .franchiseModule_content .cell .franchiseModule_content__box .franchiseModule_more{
		position: absolute;
		margin-left: 50%;
		left: -110px;
		bottom: 50px;
	}
	.franchiseModule .franchiseModule_content .cell{
		width: 50%;
		margin-left: 0;
	}
	.franchiseModule .franchiseModule_content .cell .franchiseModule_content__box h2{
		font-size: 28px;
	}
	.franchiseModule .franchiseModule_content {
	    background-size: 115%;
	    background-position: left 0vw bottom -30vw;
	    height: 540px;
	}


}
@media (max-width: 767px){
	.franchiseModule .franchiseModule_content .cell{
		width: 100%;
		margin-left: 0;
	}
	.franchiseModule{
		padding: 30px 0;
	}
	.franchiseModule .franchiseModule_content{
		height: auto;
	}
	.franchiseModule .franchiseModule_content{
		padding: 60px 20px;
		background-position: left bottom 0vw;
		padding-bottom: 240px;
	}
	.franchiseModule .franchiseModule_content .cell .franchiseModule_content__box h2{
		font-size: 26px;
	}

	.franchiseModule .franchiseModule_content {
	    background-position: left bottom -38vw;
	}
	
}

@media (max-width: 650px){
	.franchiseModule .franchiseModule_content {
	    background-position: left bottom -29vw;
	}
	
}

@media (max-width: 550px){
	.franchiseModule .franchiseModule_content {
	    background-position: left bottom -22vw;
	}
	
}

@media (max-width: 500px){
	.franchiseModule .franchiseModule_content {
	    background-position: left bottom -15vw;
	}
	
}

@media (max-width: 450px){
	.franchiseModule .franchiseModule_content {
	    background-position: left bottom -10vw;
	}
}


@media (max-width: 400px){
	.franchiseModule .franchiseModule_content {
	    background-position: left bottom 0vw;
	}
}



