.footerWorks{
	float: left;
	width: 100%;
	background-color: #002E5D;
	padding: 255px 0;
	background-size: cover;
	background-position: center top -100px;
	background-size: auto 120%;
	.title{
		font-weight: bold;
		line-height: normal;
		font-size: 46px;
		text-align: center;
		margin-bottom: 45px;
		color: #fff;
	}
	.footerWorks_tabs{
		position: relative;
		float: left;
		width: 100%;
		margin-top: 0px;
		z-index: 2;
		ul{
			// display: flex;
			background: #FAFAFA;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
			border-radius: 3px;
			overflow: hidden;

			@extend %noPadding;
			li{
				border-right: 1px solid #ccc;
				&.style-for-2{
					width: 50%;
				}
				&.style-for-1{
					width: 100%;
				}
				a{
					display: block;
					font-weight: $mediumFont;
					line-height: 26px;
					font-size: 24px;
					align-items: center;justify-content: center;
					color: #002E5D;
					display: flex;
					.tabs_icon{
						width: 120px;
						height: 120px;
						display: flex;
						align-items: center;
						justify-content: center;
						.tabs_icon__svg{
							max-width: 60px;
							width: 100%;
							max-height: 50px;
							path{
								fill: $darkBlue;
							}
						}

					}

					.tabs_content{
						float: left;
						flex-grow: 100;
						.tabs_title{
							font-weight: 500;
							line-height: 26px;
							font-size: 24px;
						}
						.tabs_info{
							text-transform: uppercase;
							font-weight: $mediumFont;
							font-size: 14px;
							letter-spacing: 1.4px;
						}
					}
					.tabs_arrow{
						width: 60px;
						float: left;
						display: flex;
						align-items: center;
						justify-content: center;
						.tabs_arrow__svg{
							width: 9px;
						}
					}
					&:hover{
						background-color: $red;
						transition-duration: .3s;
						.tabs_icon, .tabs_arrow{
							.tabs_icon__svg, .tabs_arrow__svg{
								path{
									fill: #fff;
									transition-duration: .3s;
								}
							}
						}
						.tabs_content{
							color: #fff;
									transition-duration: .3s;

						}
					}
				}

				&:last-child{
					border-right: none;
				}
			}
		}
	}
}

@media (max-width: 1024px){
	.footerWorks{
		padding: 135px 15px;
	}
}
@media (max-width: 767px){
	.footerWorks{
		padding: 60px 10px;
	}
}


@media (max-width: 1024px){

	.footerWorks {
	    background-position: center top -110px;
	    background-size: auto 130%;
	}
	.footerWorks .title{
		font-size: 37px;
		margin-bottom: 35px;
	}
	.footerWorks .footerWorks_tabs ul li a .tabs_icon{
		min-width: 74px;
	}
	.footerWorks .footerWorks_content:before{
		width: 50%;
		background-size: auto 90%;
		background-position: right bottom 50px;
	}
	.footerWorks .footerWorks_tabs ul li a .tabs_content .tabs_title{
		font-size: 20px;
		line-height: 22px;
		margin-bottom: 5px;
	}
	.footerWorks .footerWorks_tabs{
		margin-top: -30px;
		position: relative;
	}
	.footerWorks .footerWorks_image img{
		float: right;
	}
	.footerWorks{
		margin-top: 0;
	}
	.footerWorks .footerWorks_content h2{
		width: 34vw;
		font-size: 3.0vw;
		margin-top: -160px;

	}
	.footerWorks .footerWorks_image img{
		width: 70vw;
	}
}

@media (max-width: 767px){
	.footerWorks{
		background-position: center top 0;
	}
	.footerWorks .title{
		font-size: 28px;
		margin-bottom: 35px;
	}
	.footerWorks .footerWorks_image img{
		display: none;
	}
	.footerWorks .footerWorks_tabs{
		margin-top: 0px;
		bottom: 0;
		.grid-container{
			padding: 0; margin: 0;
		}
	}
	.footerWorks .footerWorks_content h2{
		margin-top: 0;
		float: left;
		font-size: 8vw;
		width: 100%;
		margin-left: 0px;
		padding: 20px 0;
	}
	.footerWorks .footerWorks_content{
		width: 100%;
		position: relative;
		padding: 0 20px;
		padding-bottom: 40vw;
	}
	.footerWorks .footerWorks_content:before{
		width: 100%;
		background-size: 100%;
		bottom: 0;
		background-position: center bottom;

			background-image: url(../img/circle/circleMobile.svg);

	}
	.footerWorks .footerWorks_tabs ul li{
		border-bottom: 1px solid #C4C4C4;

	}
	.footerWorks .footerWorks_tabs ul li a .tabs_content{
		flex-grow: 10;
	}
	.footerWorks .footerWorks_tabs ul{
		box-shadow: none;
	}
	.footerWorks .footerWorks_tabs ul li.style-for-2{
		width: 100%;
	}
	.footerWorks .footerWorks_tabs ul li{
		border-right: none;
	}
}
