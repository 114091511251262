.mobileNav{
	.mobileMenu, .mobileMenu_lang{
		background-color: $darkBlue;
		width: 100%;
		height: 100vh;
		max-height: 100vh;
		overflow: auto;
		bottom: 0;
		top: 0;
		display: none;
		position: fixed;
		// position: absolute;
		z-index: 1000;
		&::before{
			content: '';
			position: absolute;
			display: block;
			width: 100%;


			background-color: $darkBlue;
		}

		.mobileMenu_top{
			display: flex;
			align-items: center;
			padding: 15px 20px;
			border-bottom: 1px solid rgba(255,255,255,.3);
			.mobileMenu_top__logo{
				svg{
					width: 156px;
					path{
						fill: #fff;
					}
				}
			}
			.mobileMenu_topClose{
				margin-left: auto;
			}
		}
		ul{
			@extend %noPadding;
			li{
				border-bottom: 1px solid rgba(255,255,255,.3);
				a{
					display: block;
					width: 100%;
					font-weight: $boldFont;
					font-size: 15px;
					color: #fff;
					padding: 30px 20px;
				}
				ul{
					li{
						border: none;

						a{
							font-weight: $mediumFont;
							padding: 30px 20px 30px 50px;
						}
					}
				}
			}
		}

		.mobileMenu_lang__bottom{
			float: left;
			width: 100%;
			display: block;
			ul{
				@extend %noPadding;
				display: flex;
				float: left;
				width: 100%;
				li{
					display: inline-block;
					flex-grow: 1;
					text-align: center;
					a{
						color: #fff;
						font-weight: $normalFont;
						border-right: 1px solid rgba(255,255,255,.3);
					}
					&.activeLang{
						a{
							font-weight: $boldFont;
						}
					}
					&:last-child{
						a{
							border-right: none;
						}
					}
				}
			}
		}
	}
}


