.contact_full_width{
	float: left;
	width: 100%;
	padding: 120px 0;
	background-color: #FAFAFA;
	.item{
		width: 50%;
		float: left;
		text-align: center;
		a{
			
			display: inline-block;
			font-weight: 300;
			line-height: normal;
			font-size: 39.35px;
			text-align: center;
			color: $blue;
			&::after{
				display: block;
				content: '';
				width: 30%;
				height: 1px;
				position: relative;
				transition-duration: .3s;
				background-color: $blue;
				margin-top: 10px;
				margin-bottom: 20px;
			}
			&:hover{
				&::after{
					width: 100%;
				}
			}
		}
		span{
			width: 100%;
			display: block;

			color: $darkBlue;
		}
	}
}

@media (max-width: 1024px){
	.contact_full_width {
		padding: 90px 0;
		.item{
			width: 100%;
			a{
			font-weight: 300;
			line-height: normal;
			font-size: 28px;
			}
			span{
				font-size: 14px;
			}
			&:first-child{
				margin-bottom: 40px;
			}
		}
	}
}

@media (max-width: 767px){
	.contact_full_width {
		padding: 50px 0;
		.item{
			width: 100%;
			a{
			font-weight: 300;
			line-height: normal;
			font-size: 26px;
			}
			span{
				font-size: 14px;
			}
			&:first-child{
				margin-bottom: 40px;
			}
		}
	}
}