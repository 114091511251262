.row_3_only_text{
	float: left;
	width: 100%;
	padding: 120px 0;
	padding-bottom: 80px;
	.item{
		display: flex; justify-content: center;
		margin-bottom: 40px;
		.content{
			width: 100%;
			max-width: 280px;
			p{
				margin-bottom: 60px;
				font-weight: normal;
				line-height: 24px;
				font-size: 16px;
				strong{
					line-height: 26px;
					font-weight: normal;
					font-size: 20px;
					margin-bottom: 20px;
					float: left;
					width: 100%;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
}

@media (max-width: 1024px){
	.row_3_only_text{
		padding: 90px 0;
	}

}

@media (max-width: 767px){
	.row_3_only_text{
		padding: 50px 0;
	}
	.row_3_only_text .item .content {

	    width: 100%;
	    margin-bottom: 60px;
	}
	.row_3_only_text .item:last-child{
		.content:last-child{
			margin-bottom: 0;
		}
	}
}