.row_5_circle_box{
	float: left;
	width: 100%;
	padding: 20px 0;
	margin-bottom: 100px;
	.items{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		.item{
			width: 20%;
			float: left;
			position: relative;
			padding: 0 13px;
			transition-duration: .3s;

			.content{
				padding: 40px;
				padding-top: 50px;
				position: absolute;
				left: 0; right: 0; top: 0; bottom: 0;
				font-weight: normal;
				line-height: 18px;
				font-size: 16px;
				text-align: center;
					transition-duration: .3s;


				.count{
					float: left;
					margin-bottom: 25px;
					width: 100%;
					text-align: center;

					font-weight: bold;
					line-height: 18px;
					font-size: 32px;
					text-align: center;
					color: $yellow;
				}
			}
			.bg{
				img{
					transition-duration: .1s;
					fill: #fff;
					width: 100%;
				}
			}
			&:hover{
				// transform: scale(1.05);
				.content{
					// color: #fff;
					.count{

					}
				}
				.bg{
					svg{
						// fill: $darkBlue;
					}
				}
			}
		}
	}
}


@media (max-width: 1024px){
	.row_5_circle_box .items .wrap{
		width: 220px;

		position: relative;

	}
	.row_5_circle_box .items .item{
		width: 33.333%;
		display: flex;
		margin-bottom: 30px;
		justify-content: center;
	}
}
@media (max-width: 767px){
	.row_5_circle_box .items{
		width: 230px;
		margin: 0 auto;
	}
	.row_5_circle_box .items .item{
		margin: 0 auto;
		margin-bottom: 30px;
		width: 230px;
		font-size: 16px;
		display: inline-block;
		float: none;

		.bg{
			img{
			}
		}
	}
	.row_5_circle_box .items .item .content .count{
		margin-bottom: 20px;
	}
}
