.forms_modal{
      position: fixed;
      left: 0px;
      right: 0px;
      top: 0;
      bottom: 0;
      z-index: 10000;
      // display: none;
      padding: 60px 0;
      display: none;

      &.openModal2{
            display: flex;
      }


      .forms_modal__bg{
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            opacity: .2;
            background-color: #000000;
      }

      .forms_modal__box{
            background-color: #fff;
            float: left;
            width: 100%;
            position: relative;
            overflow: hidden;
            height: 100%;
            border-radius: 4px;
            z-index: 1;
            .top{
                  width: 100%;
                  line-height: 1em;
                  padding: 13px;
                  float: left;
                  border-bottom: 1px solid #EEEEEE;
                  a{
                        float: right;
                        line-height: 1em;
                  }



            }
            .content{
                  padding: 30px 85px;
                  height: calc(100% - 44.5px);
                  overflow: auto;
                  .title{
                        font-weight: bold;
                        line-height: 38px;
                        font-size: 28px;
                        text-align: center;
                        margin-bottom: 45px;
                  }
                  p{
                        strong{
                              font-weight: 500;
                              line-height: 26px;
                              font-size: 24px;
                              text-align: center;
                              letter-spacing: 0.2px;
                              display: block;
                              width: 100%;
                              margin-bottom: 15px;
                        }
                        font-size: 16px;
                        line-height: 1.5em;
                  }
            }
      }
}


@media (max-width: 767px){
      .forms_modal .forms_modal__box .content{
            padding: 30px;
      }
}
