.box_image_background{
	float: left;
	width: 100%;
	padding: 75px 0;
	padding-top: 0;
	.box_image_background_content{
		float: left;width: 100%;
		// background-color: #f5d362;
		background-size: 75%;
		background-position: left 0px top -50px; 
		background-repeat: no-repeat;
		color: $darkBlue;
		padding: 161px 60px;
		position: relative;
		overflow: hidden;


		&::after{
		display: block;
		position: absolute;
		left: 0;right: 0;top: 0;bottom: 0;
		background-color: #fff;
		content: '';
		opacity: .04;
		}

		.cell{
			margin-left: auto;
			max-width: 320px;
			position: relative;
			


			.box_image_background_content__box{
				z-index: 12;
				position: relative;

				
				h3{
					p{
						font-size: 39.35px;
						line-height: normal;
						strong{
							font-weight: bold;
						}
					}
				}
				p{
					line-height: 25px;
					font-size: 16px;
					margin-bottom: 30px;
				}
				.box_image_background_more{
					width: 220px;
					display: block;
					background-color: $blue;
					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
					border-radius: 3px;
					text-align: center;
					position: relative;
					padding: 15px 10px;
					color: #fff;
					@include buttonAnimation($darkBlue); 
					span{
						z-index: 1;
						position: relative;
					}
				}
			}
		}
	}	
}
@media (max-width: 1440px){
	.box_image_background{
		padding: 60px 0;
	}
	.box_image_background .box_image_background_content{
		padding: 76px 60px;
	}
}
@media (max-width: 1024px){
	.box_image_background{
		padding: 45px 10px;
	}
	.box_image_background .box_image_background_content .cell .box_image_background_content__box,
	.box_image_background .box_image_background_content .cell{
		position: initial;
		width: 100%;
	}
	.box_image_background .box_image_background_content .cell{
		max-width: 100%;
	}
	.box_image_background .box_image_background_content .cell::before{
		display: none;
	}

	.box_image_background .box_image_background_content .cell .box_image_background_content__box .box_image_background_more{
		position: absolute;
		margin-left: 50%;
		left: -110px;
		bottom: 50px;
	}
	.box_image_background .box_image_background_content .cell{
		width: 100%;
		margin-left: 0;
	}
	.box_image_background .box_image_background_content .cell .box_image_background_content__box h2{
		font-size: 28px;
	}
	.box_image_background .box_image_background_content {
	    background-size: 115%;
	    background-position: left 0vw bottom -30vw;
	    height: 540px;
	}
	
	.box_image_background .box_image_background_content .cell .box_image_background_content__box{
		h3, p{
			width: 50%;
			display: block;
			float: left;
		}
		h3{
			font-size: 28px;
			p{
				font-size: 28px;
			}
		}
	}

	.box_image_background .box_image_background_content {

	    background-size: 100%;
	    background-position: center bottom -25vw;
	}
}
@media (max-width: 767px){
	.box_image_background .box_image_background_content .cell{
		width: 100%;
		margin-left: 0;
	}
	.box_image_background{
		padding: 30px 0;
	}
	.box_image_background .box_image_background_content{
		height: auto;
	}
	.box_image_background .box_image_background_content{
		padding: 60px 20px;
		background-position: left bottom 0vw;
		padding-bottom: 240px;
	}
	.box_image_background .box_image_background_content .cell .box_image_background_content__box h2{
		font-size: 26px;
	}

	.box_image_background .box_image_background_content {
	    background-position: left bottom -29vw;
	}

	.box_image_background .box_image_background_content .cell .box_image_background_content__box{
		h3, p{
			width: 100%;
			display: block;
			float: left;
		}
		h3{
			font-size: 28px;
			p{
				font-size: 28px;
			}
		}
		p{
			br{
				display: none;
			}
		}
	}
	
}

@media (max-width: 650px){
	.box_image_background .box_image_background_content {
	    background-position: left bottom -21vw;
	}
	
}

@media (max-width: 550px){
	.box_image_background .box_image_background_content {
	    background-position: left bottom -14vw;
	}
	
}

@media (max-width: 500px){
	.box_image_background .box_image_background_content {
	}
	
}

@media (max-width: 450px){
	.box_image_background .box_image_background_content {
	    background-position: left bottom -10vw;
	}
}


@media (max-width: 400px){
	.box_image_background .box_image_background_content {
	    background-position: left bottom 0vw;
	}
}



