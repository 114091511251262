.textModule{
	padding: 55px 0;
	padding-bottom: 120px;
	float: left;
	width: 100%;
	p{
		line-height: 38px;
		font-size: 28px;
		text-align: center;
		max-width: 686px;
		margin: 0 auto;
		font-weight: $lightFont;
		color: $darkBlue;
		strong{
			font-weight: $boldFont;
		}
	}
}

@media (max-width: 1440px){
	.textModule{
		padding: 60px 0;
	}
}
@media (max-width: 1024px){


	.textModule{
		padding: 45px 0;
		float: left;
		width: 100%;
		p{
			line-height: 31px;
			font-size: 23px;

			strong{
				font-weight: $boldFont;
			}
		}
	}
}

@media (max-width: 767px){


	.textModule{
		float: left;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 35px;
		width: 100%;
		p{
			line-height: 26px;
			font-size: 19px;

			strong{
				font-weight: $boldFont;
			}
		}
	}
}
